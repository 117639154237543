import React, { useMemo } from "react";
import { Card, Col, Row, Space, Typography } from "antd";
import { GRID_GUTTER } from "constants/ui";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { isEmpty, orderBy, sum, uniq } from "lodash";
import { DIMENSION_ITEM_ID } from "constants/customDimensions";
import TableChart from "components/charts/TableChart";
import { formatNumber } from "services/numberService";
import LineChart from "components/charts/LineChart";
import {
  DIMENSION_DATE,
  DIMENSION_EVENT_NAME,
  DIMENSION_HOSTNAME,
} from "constants/dimensions";
import { EVENT_VIEW_ITEM_IMPRESSION } from "constants/customEvents";
import { reviseTimeSeriesData } from "services/gaService";
import Favicon from "components/Favicon";
import { useTriggerTextsContext } from "../../TriggerTextsContext";

const TriggerTextImpressionsByPartnersSection = () => {
  const { dateStrings, triggerTextViewsReportData } = useTriggerTextsContext();

  const timeSeriesData = useMemo(() => {
    if (isEmpty(triggerTextViewsReportData)) return [];

    const dataKeysMap = {};
    const rowsGroupedByDate = triggerTextViewsReportData.rows
      .filter((row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION)
      .reduce((result, current) => {
        const date = current[DIMENSION_DATE];
        const hostName = current[DIMENSION_HOSTNAME];
        if (!result[date]) result[date] = {};
        result[date][DIMENSION_DATE] = date;
        result[date][hostName] = sum([
          result[date][hostName],
          current[METRIC_EVENT_COUNT],
        ]);

        dataKeysMap[hostName] = hostName;
        return result;
      }, {});

    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: Object.keys(dataKeysMap),
    });
  }, [triggerTextViewsReportData, dateStrings]);

  const contentActiveViewsByPartnersTimeSeriesLinesData = useMemo(() => {
    if (isEmpty(triggerTextViewsReportData)) return [];

    return uniq(
      triggerTextViewsReportData.rows.map((row) => row[DIMENSION_HOSTNAME])
    ).map((hostName) => ({ name: hostName, dataKey: hostName }));
  }, [triggerTextViewsReportData]);

  const impressionsByPartnersTableData = useMemo(() => {
    if (isEmpty(triggerTextViewsReportData)) return [];

    const rowsGroupedByDate = triggerTextViewsReportData.rows
      .filter((row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION)
      .reduce((result, current) => {
        const hostName = current[DIMENSION_HOSTNAME];
        if (!result[hostName]) result[hostName] = {};
        result[hostName][DIMENSION_HOSTNAME] = hostName;
        result[hostName][METRIC_EVENT_COUNT] = sum([
          result[hostName][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      }, {});

    return orderBy(
      Object.values(rowsGroupedByDate),
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [triggerTextViewsReportData]);

  return (
    <>
      <Typography.Title level={5}>Trigger Text Impressions</Typography.Title>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <Card size="small">
            <LineChart
              xAxisDataKey={DIMENSION_DATE}
              data={timeSeriesData}
              lines={contentActiveViewsByPartnersTimeSeriesLinesData}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <Card size="small" className="">
            <TableChart
              rowKey={DIMENSION_ITEM_ID}
              dataSource={impressionsByPartnersTableData}
              columns={[
                {
                  title: "Partner",
                  dataIndex: DIMENSION_HOSTNAME,
                  key: DIMENSION_HOSTNAME,
                  render(value) {
                    return (
                      <Space>
                        <Favicon url={value} />
                        {value}
                      </Space>
                    );
                  },
                },
                {
                  title: "Impressions",
                  dataIndex: METRIC_EVENT_COUNT,
                  key: METRIC_EVENT_COUNT,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

TriggerTextImpressionsByPartnersSection.propTypes = {};

export default TriggerTextImpressionsByPartnersSection;
