import React from "react";
import Overview from "../Overview";
import OverviewContextProvider from "../OverviewContextProvider";

const OverviewWrapper = () => {
  return (
    <OverviewContextProvider>
      <Overview />
    </OverviewContextProvider>
  );
};

export default OverviewWrapper;
