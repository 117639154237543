import React, { useEffect, useMemo, useState } from "react";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import {
  DIMENSION_DATE,
  DIMENSION_EVENT_NAME,
  DIMENSION_SEARCH_TERM,
} from "constants/dimensions";
import { get, isEmpty, orderBy, sum } from "lodash";
import { Card, Col, Row, Spin } from "antd";
import { GRID_GUTTER } from "constants/ui";
import {
  getBatchReports,
  reviseReport,
  reviseTimeSeriesData,
} from "services/gaService";
import { buildInListFilter } from "services/gaDimensionsService";
import {
  EVENT_SEARCH,
  EVENT_VIEW_SEARCH_RESULTS,
} from "constants/customEvents";
import LineChart from "components/charts/LineChart";
import Scorecard from "components/charts/Scorecard";
import TableChart from "components/charts/TableChart";
import ChartTitle from "components/ChartTitle";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import { alertUnknownError } from "services/notificationService";

const buildFilters = (filters, notFilters) => {
  return {
    andGroup: {
      expressions: [
        buildInListFilter(DIMENSION_EVENT_NAME, [
          EVENT_SEARCH,
          EVENT_VIEW_SEARCH_RESULTS,
        ]),
        ...(filters || []),
      ],
    },
  };
};

const Search = () => {
  const [batchReports, setBatchReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dateStrings } = useReportContext();

  const batchReportRequests = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;

    return [
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters(),
      },
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_SEARCH_TERM,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        limit: 100,
        offset: 0,
      },
    ];
  }, [dateStrings]);

  useEffect(() => {
    if (!batchReportRequests) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const batchData01 = await getBatchReports(batchReportRequests);
        setBatchReports(get(batchData01, "reports", []));
      } catch (error) {
        alertUnknownError();
        setBatchReports([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [batchReportRequests]);

  const searchesByDateReportData = useMemo(() => {
    return reviseReport(batchReports[0]);
  }, [batchReports]);

  const searchTermsReportData = useMemo(() => {
    return reviseReport(batchReports[1]);
  }, [batchReports]);

  const searchesTimeSeriesData = useMemo(() => {
    if (isEmpty(searchesByDateReportData)) return [];

    const rowsGroupedByDate = searchesByDateReportData.rows.reduce(
      (result, current) => {
        const date = current[DIMENSION_DATE];
        if (!result[date]) result[date] = {};
        result[date].date = date;
        result[date][METRIC_EVENT_COUNT] = sum([
          result[date][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [METRIC_EVENT_COUNT],
    });
  }, [searchesByDateReportData, dateStrings]);

  const searchCount = useMemo(() => {
    if (isEmpty(searchesTimeSeriesData)) return 0;

    return sum(searchesTimeSeriesData.map((item) => item[METRIC_EVENT_COUNT]));
  }, [searchesTimeSeriesData]);

  const seardchTermsTableData = useMemo(() => {
    if (isEmpty(searchTermsReportData)) return [];

    return orderBy(
      searchTermsReportData.rows.filter((row) => row[DIMENSION_SEARCH_TERM]),
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [searchTermsReportData]);

  return (
    <Spin spinning={isLoading}>
      <ChartTitle>Search trend</ChartTitle>

      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <Card size="small">
            <LineChart
              xAxisDataKey="date"
              data={searchesTimeSeriesData}
              lines={[
                {
                  name: "Event",
                  dataKey: METRIC_EVENT_COUNT,
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <Row gutter={GRID_GUTTER}>
            <Col span={24} className="mb-8">
              <Card size="small">
                <Scorecard title="Searches" value={searchCount} />
              </Card>
            </Col>
            <Col xs={24} lg={24} className="mb-8">
              <Card size="small" className="">
                <TableChart
                  rowKey={DIMENSION_SEARCH_TERM}
                  dataSource={seardchTermsTableData}
                  columns={[
                    {
                      title: "Term",
                      dataIndex: DIMENSION_SEARCH_TERM,
                      key: DIMENSION_SEARCH_TERM,
                    },
                    {
                      title: "Count",
                      dataIndex: METRIC_EVENT_COUNT,
                      key: METRIC_EVENT_COUNT,
                      align: "right",
                    },
                  ]}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

Search.propTypes = {};

export default Search;
