import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { Affix, Alert, Button, Layout, Typography } from "antd";
import classNames from "classnames";
import Icon from "components/Icon";
import DateFilter from "components/DateFilter";
import ReportContextProvider from "components/ReportContext/ReportContextProvider";
import moment from "moment";
import { DATE_FORMAT, DATE_FORMAT_PRINT } from "constants/default";
import useSetQueryString from "hooks/useSetQueryString";
import { PARAM_DATE_END, PARAM_DATE_START } from "constants/queryStringParams";

const DefaultLayout = ({
  title,
  children,
  dateRangeEnabled,
  warningEnabled: initialWarningEnabled,
  warningText,
  initialFilterType,
  disableDateAfter,
}) => {
  const [affixed, setAffixed] = useState();
  const [dateStrings, setDateStrings] = useState([]);
  const [printDateStrings, setPrintDateStrings] = useState([]);
  const [warningEnabled, setWarningEnabled] = useState(initialWarningEnabled);
  const [_, setParamBatch] = useSetQueryString();

  useEffect(() => {
    setPrintDateStrings([
      moment(dateStrings[0], DATE_FORMAT).format(DATE_FORMAT_PRINT),
      moment(dateStrings[1], DATE_FORMAT).format(DATE_FORMAT_PRINT),
    ]);
    setParamBatch({
      [PARAM_DATE_START]: dateStrings[0],
      [PARAM_DATE_END]: dateStrings[1],
    });
  }, [dateStrings, setParamBatch]);

  const contextValue = useMemo(() => {
    return {
      dateStrings,
      setDateStrings,
      setWarningEnabled,
    };
  }, [dateStrings]);

  return (
    <Layout>
      <Layout.Content className="bg-white">
        <Affix offsetTop={0} onChange={setAffixed} className="print:hidden">
          <div
            className={classNames(
              "bg-white duration-300 ease-in-out transition-shadow flex items-center bg-opacity-95",
              { shadow: affixed }
            )}
          >
            <div className="container px-4 py-2 flex flex-wrap sm:flex-nowrap items-center">
              <div className="flex sm:flex-col lg:w-auto w-full mb-1 sm:mb-0">
                <Typography.Title level={5} style={{ marginBottom: "2px" }}>
                  {title}
                </Typography.Title>
                {dateRangeEnabled && (
                  <div className="text-gray-400 text-xxs ml-auto sm:ml-0">
                    {printDateStrings[0]} - {printDateStrings[1]}
                  </div>
                )}
              </div>

              <div className="flex sm:flex-nowrap items-start ml-auto sm:flex-shrink-0">
                {dateRangeEnabled && (
                  <DateFilter
                    initialType={initialFilterType}
                    dateStrings={dateStrings}
                    onChange={setDateStrings}
                    disableDateAfter={disableDateAfter}
                  />
                )}
                <span className="ml-2">
                  <Button onClick={() => window.print()}>
                    <Icon name="print" />
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </Affix>
        <div className="hidden print:flex items-center mb-2">
          <Typography.Title level={5} style={{ marginBottom: "2px" }}>
            {title}
          </Typography.Title>
          {dateRangeEnabled && (
            <div className="text-gray-400 text-xxs ml-auto">
              {printDateStrings[0]} - {printDateStrings[1]}
            </div>
          )}
        </div>
        <hr className="m-0 border-gray-100" />
        <div className="container p-4">
          {warningEnabled && (
            <div className="flex justify-end mb-2 print:hidden">
              <Alert
                type="warning"
                message={<span className="text-xxs">{warningText}</span>}
                showIcon
                banner
              ></Alert>
            </div>
          )}

          <ReportContextProvider value={contextValue}>
            {children}
          </ReportContextProvider>
        </div>
      </Layout.Content>
    </Layout>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
  dateRangeEnabled: PropTypes.bool,
  warningEnabled: PropTypes.bool,
  warningText: PropTypes.any,
  disableDateAfter: PropTypes.object,
};

DefaultLayout.defaultProps = {
  dateRangeEnabled: true,
  warningEnabled: true,
  warningText: (
    <>
      <span>Attention: This Google Analytics report has a delay of </span>
      <a
        href="https://support.google.com/analytics/answer/1070983?hl=en#zippy=%2Cin-this-article"
        target="_blank"
        rel="noreferrer"
        className="text-sky-500"
      >
        24-48 hours
      </a>
      !
    </>
  ),
};

export default DefaultLayout;
