import { Empty } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useMemo } from "react";
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  BarChart as AntBarChart,
} from "recharts";
import { formatNumber } from "services/numberService";
import { getDimensionColorByIndex } from "utils/colorUtils";

const VerticalBarChart = ({ bars, data, yAxisDataKey }) => {
  const computedBars = useMemo(() => {
    return (bars || []).map((header, index) => ({
      ...header,
      color: getDimensionColorByIndex(index),
    }));
  }, [bars]);

  if (isEmpty(data)) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <ResponsiveContainer height={320}>
      <AntBarChart layout="vertical" data={data} barSize={16}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" fontSize={11} />
        <YAxis
          type="category"
          dataKey={yAxisDataKey}
          fontSize={11}
          interval={0}
        />
        <Tooltip formatter={(value) => formatNumber(value)} />
        <Legend iconType="circle" iconSize={10} />
        {computedBars.map((bar) => (
          <Bar
            name={bar.name}
            key={bar.dataKey}
            dataKey={bar.dataKey}
            fill={bar.color}
          />
        ))}
      </AntBarChart>
    </ResponsiveContainer>
  );
};

VerticalBarChart.propTypes = {};

export default VerticalBarChart;
