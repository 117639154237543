export const EVENT_VIEW_ITEM_IMPRESSION = "view_item_impression";
export const EVENT_VIEW_ITEM_DETAILS = "view_item_details";
export const EVENT_VIEW_ITEM_EMAIL_DETAILS = "view_item_email_details";
export const EVENT_VIEW_ITEM_EXTERNAL = "view_item_external";
export const EVENT_VIEW_ITEM_CLICKED = "view_item_clicked";
export const EVENT_VIEW_ITEM_LINK_CLICKED = "view_item_link_clicked";
export const EVENT_SAVE_ITEM = "save_item";
export const EVENT_PLUG_ITEM = "plug_item";
export const EVENT_VIEW_SEARCH_RESULTS = "view_search_results";
export const EVENT_SEARCH = "search";
export const EVENT_SEND_ITEM = "send_item";
export const EVENT_MINIMIZE_ITEM = "minimize_item";
export const EVENT_CLOSE_ITEM = "close_item";
