import React, { useMemo } from "react";
import { ResponsiveContainer, LineChart, Line } from "recharts";
import { getDimensionColorByIndex } from "utils/colorUtils";
import { isEmpty } from "lodash";
import { Col, Row, Statistic } from "antd";

const Scorecard = ({ title, value, chartProps, valueStyle }) => {
  const { lines, data } = chartProps || {};

  const computedLines = useMemo(() => {
    if (isEmpty(lines)) return [];

    return lines.map((item, index) => ({
      ...item,
      fill: getDimensionColorByIndex(index),
    }));
  }, [lines]);

  if (isEmpty(chartProps) || isEmpty(chartProps.data))
    return <Statistic title={title} value={value} valueStyle={valueStyle} />;

  return (
    <Row>
      <Col xs={12}>
        <Statistic title={title} value={value} valueStyle={valueStyle} />
      </Col>
      <Col xs={12} className="flex items-end">
        <ResponsiveContainer width="100%" height="80%">
          <LineChart
            data={data}
            margin={{
              top: 0,
              right: 16,
              left: 0,
              bottom: 0,
            }}
          >
            {computedLines.map((line) => (
              <Line
                dot={false}
                name={line.name}
                key={line.dataKey}
                type="monotone"
                dataKey={line.dataKey}
                stroke={line.fill}
                strokeWidth={2}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </Col>
    </Row>
  );
};

Scorecard.propTypes = {};

export default Scorecard;
