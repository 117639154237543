import React, { useState, useCallback, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Radio, Select, DatePicker, Space } from "antd";
import moment from "moment";
import {
  FILTER_PAST_7_DAYS,
  FILTER_PAST_14_DAYS,
  FILTER_PAST_28_DAYS,
  FILTER_CUSTOM,
} from "./dateFilterTypes";
import { isEmpty } from "lodash";
import {
  getDefaultDateAvailableReport,
  DATE_FORMAT,
  getAdminDefaultDateAvailableReport,
  MAX_DATE_RANGE_IN_DAYS,
} from "constants/default";
import useQueryString from "hooks/useQueryString";
import { useAppContext } from "contexts/AppContextProvider";

const { Option } = Select;

const DateFilter = ({
  disableDateAfter,
  dateStrings,
  initialType,
  onChange,
}) => {
  const [tempDateStrings, setTempDateStrings] = useState([]);
  const [isValidRange, setIsValidateRange] = useState(true);
  const { isAdmin } = useAppContext();
  const dateAvailableReport = () => {
    if (isAdmin) return getAdminDefaultDateAvailableReport();

    return disableDateAfter || getDefaultDateAvailableReport();
  };
  const dateAvailableReportString = dateAvailableReport().format(DATE_FORMAT);
  const { start, end } = useQueryString();

  const dateFilterTypes = useMemo(() => {
    return [
      {
        id: FILTER_PAST_7_DAYS,
        name: "7D",
        startDate: dateAvailableReport()
          .subtract(7, "days")
          .format(DATE_FORMAT),
        endDate: dateAvailableReportString,
      },
      {
        id: FILTER_PAST_14_DAYS,
        name: "14D",
        startDate: dateAvailableReport()
          .subtract(14, "days")
          .format(DATE_FORMAT),
        endDate: dateAvailableReportString,
      },
      {
        id: FILTER_PAST_28_DAYS,
        name: "28D",
        startDate: dateAvailableReport()
          .subtract(28, "days")
          .format(DATE_FORMAT),
        endDate: dateAvailableReportString,
      },
      {
        id: FILTER_CUSTOM,
        name: "Custom",
        startDate: null,
        endDate: null,
      },
    ];
  }, []);

  const [dateFilterType, setDateFilterType] = useState(() => {
    if (initialType) return initialType;

    if (start && end) {
      const foundFilterType = dateFilterTypes.find(
        (item) => item.startDate === start && item.endDate === end
      );
      return foundFilterType?.id || FILTER_CUSTOM;
    }

    return FILTER_PAST_14_DAYS;
  });

  const selectDateFilterType = useCallback(
    (value) => {
      setDateFilterType(value);
      const selectedDateFilterType = dateFilterTypes.find(
        (item) => item.id === value
      );

      if (
        selectedDateFilterType &&
        selectedDateFilterType.id !== FILTER_CUSTOM
      ) {
        onChange([
          selectedDateFilterType.startDate,
          selectedDateFilterType.endDate,
        ]);
      }
    },
    [dateFilterTypes, onChange]
  );

  const onDateFilterTypeChange = useCallback(
    (e) => {
      selectDateFilterType(e.target.value);
    },
    [selectDateFilterType]
  );

  const onDateFilterSelectChange = useCallback(
    (value) => {
      selectDateFilterType(value);
    },
    [selectDateFilterType]
  );

  const onCustomRangeSelected = useCallback(
    (dates) => {
      const startDate = moment(dates[0]);
      const startDateString = startDate.format(DATE_FORMAT);
      const endDate = moment(dates[1]);
      const endDateString = endDate.format(DATE_FORMAT);
      const diff = endDate.diff(startDate, "days");
      if (diff > MAX_DATE_RANGE_IN_DAYS) {
        setTempDateStrings([startDateString, endDateString]);
        setIsValidateRange(false);
        return;
      }

      onChange([startDateString, endDateString]);
      setIsValidateRange(true);
      setTempDateStrings(null);
    },
    [onChange]
  );

  function disabledDate(current) {
    return current && current > dateAvailableReport();
  }

  useEffect(() => {
    if (initialType === FILTER_CUSTOM) return;

    const defaultDateFilterType = dateFilterTypes.find(
      (filterType) => filterType.id === FILTER_PAST_14_DAYS
    );
    let startDate = start ?? defaultDateFilterType.startDate;
    let endDate = end ?? defaultDateFilterType.endDate;
    onChange([startDate, endDate]);
  }, []);

  const dateRangeValue = useMemo(() => {
    if (isEmpty(dateStrings) || !dateStrings[0] || !dateStrings[1]) return null;

    if (!isEmpty(tempDateStrings))
      return [moment(tempDateStrings[0]), moment(tempDateStrings[1])];

    return [moment(dateStrings[0]), moment(dateStrings[1])];
  }, [dateStrings, tempDateStrings]);

  return (
    <div className="print:hidden flex flex-col justify-end justify-end">
      <Space className="print:hidden">
        <div className="lg:hidden">
          <Select
            defaultValue={FILTER_PAST_14_DAYS}
            value={dateFilterType}
            className="w-full"
            onChange={onDateFilterSelectChange}
          >
            {dateFilterTypes.map((filterType) => (
              <Option value={filterType.id} key={filterType.id}>
                {filterType.name}
              </Option>
            ))}
          </Select>
        </div>

        <div className="hidden lg:block">
          <div>
            <Radio.Group
              defaultValue={FILTER_PAST_14_DAYS}
              value={dateFilterType}
              onChange={onDateFilterTypeChange}
              buttonStyle="solid"
              className="ml-auto"
            >
              {dateFilterTypes.map((filterType) => (
                <Radio.Button
                  value={filterType.id}
                  key={filterType.id}
                  checked={dateFilterType === filterType.id}
                >
                  {filterType.name}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
        </div>

        {dateFilterType === FILTER_CUSTOM && (
          <DatePicker.RangePicker
            value={dateRangeValue}
            className="w-full"
            format={DATE_FORMAT}
            placeholder={["Start Time", "End Time"]}
            disabledDate={disabledDate}
            onChange={onCustomRangeSelected}
            onOk={onCustomRangeSelected}
          />
        )}
      </Space>
      {!isValidRange && (
        <span className="text-red-500 text-xxs text-right mt-1">
          Date range must be within {MAX_DATE_RANGE_IN_DAYS} days
        </span>
      )}
    </div>
  );
};

DateFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  disableDateAfter: PropTypes.object,
};

DateFilter.defaultProps = {
  disableDateAfter: null,
};

export default DateFilter;
