import axios from "axios";
import { isEmpty } from "lodash";

const isDev = process.env.NODE_ENV === "development";
const baseUrl = "http://localhost:3005";
const flag = !isEmpty(baseUrl);
let defaultConfigs = {
  // baseUrl: process.env.REACT_APP_PLUGILO_API_HOST,
  method: "POST",
  mode: flag ? "cors" : "same-origin",
  credentials: flag ? "include" : "same-origin",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  responseType: "json",
};

export default axios.create(defaultConfigs);

export function fetchAuth() {
  let headers = {
    ...defaultConfigs.headers
  };
  if(window.token) {
    headers = {...headers,
      Authorization: window.usingApiKey
        ? window.token
        : `Bearer ${
            isDev ? process.env.REACT_APP_PLUGILO_API_TOKEN : window.token
          }`, }
  }
  return axios.create({
    ...defaultConfigs,
    headers,
  });
}
