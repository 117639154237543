import React, { useEffect, useMemo, useState } from "react";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { DIMENSION_DATE, DIMENSION_EVENT_NAME } from "constants/dimensions";
import {
  DIMENSION_ITEM_BRAND,
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_SOURCE,
} from "constants/customDimensions";
import { get, isEmpty, orderBy, sum } from "lodash";
import { Card, Col, Row, Spin } from "antd";
import { GRID_GUTTER } from "constants/ui";
import LineChart from "components/charts/LineChart";
import {
  getBatchReports,
  getContentTypeName,
  reviseReport,
  reviseTimeSeriesData,
} from "services/gaService";
import { buildStringFilter } from "services/gaDimensionsService";
import { EVENT_SAVE_ITEM } from "constants/customEvents";
import { useParams } from "react-router";
import PieChart from "components/charts/PieChart";
import Scorecard from "components/charts/Scorecard";
import {
  TAG_BOOKMARK,
  TAG_DOMAIN,
  TAG_HIGHLIGHT,
  TAG_LIST,
  TAG_LISTSET,
} from "constants/contentTags";
import ChartTitle from "components/ChartTitle";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import { alertUnknownError } from "services/notificationService";

const buildFilters = (domain, filters, notFilters) => {
  return {
    andGroup: {
      expressions: [
        buildStringFilter(DIMENSION_ITEM_BRAND, domain),
        ...(filters || []),
      ],
    },
  };
};

const SaveContents = () => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dateStrings } = useReportContext();
  const { id: domain } = useParams();

  const reportRequests = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;

    return [
      // Content Views, Save, Plug events
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_ITEM_SOURCE,
          },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters(domain, [
          buildStringFilter(DIMENSION_EVENT_NAME, EVENT_SAVE_ITEM),
        ]),
      },
    ];
  }, [domain, dateStrings]);

  useEffect(() => {
    if (!reportRequests) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await getBatchReports(reportRequests);
        setReports(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        setReports([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [reportRequests]);

  const commonReportData = useMemo(() => {
    return reviseReport(reports[0]);
  }, [reports]);

  const eventTrendTimeSeriesData = useMemo(() => {
    if (isEmpty(commonReportData)) return [];

    const dataKeysMap = {};
    const rowsGroupedByDate = commonReportData.rows.reduce(
      (result, current) => {
        const date = current[DIMENSION_DATE];
        const category = current[DIMENSION_ITEM_CATEGORY];
        if (!result[date]) result[date] = {};

        result[date][DIMENSION_DATE] = date;
        result[date][category] = sum([
          result[date][category],
          current[METRIC_EVENT_COUNT],
        ]);
        result[date][METRIC_EVENT_COUNT] = sum([
          result[date][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        dataKeysMap[category] = category;
        return result;
      },
      {}
    );

    // return orderBy(Object.values(rowsGroupedByDate), [DIMENSION_DATE]);
    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [...Object.keys(dataKeysMap), METRIC_EVENT_COUNT],
    });
  }, [commonReportData, dateStrings]);

  const saveCardCount = useMemo(() => {
    if (isEmpty(eventTrendTimeSeriesData)) return 0;

    return sum(eventTrendTimeSeriesData.map((item) => item[TAG_BOOKMARK]));
  }, [eventTrendTimeSeriesData]);

  const saveCompanyCount = useMemo(() => {
    if (isEmpty(eventTrendTimeSeriesData)) return 0;

    return sum(eventTrendTimeSeriesData.map((item) => item[TAG_DOMAIN]));
  }, [eventTrendTimeSeriesData]);

  const saveStackCount = useMemo(() => {
    if (isEmpty(eventTrendTimeSeriesData)) return 0;

    return sum(eventTrendTimeSeriesData.map((item) => item[TAG_LISTSET]));
  }, [eventTrendTimeSeriesData]);

  const saveListCount = useMemo(() => {
    if (isEmpty(eventTrendTimeSeriesData)) return 0;

    return sum(eventTrendTimeSeriesData.map((item) => item[TAG_LIST]));
  }, [eventTrendTimeSeriesData]);

  const saveHighlightCount = useMemo(() => {
    if (isEmpty(eventTrendTimeSeriesData)) return 0;

    return sum(eventTrendTimeSeriesData.map((item) => item[TAG_HIGHLIGHT]));
  }, [eventTrendTimeSeriesData]);

  const saveByTypesPieChartData = useMemo(() => {
    if (isEmpty(commonReportData)) return [];

    const rowsGroupedByCategories = commonReportData.rows.reduce(
      (result, current) => {
        const category = current[DIMENSION_ITEM_CATEGORY];
        if (!result[category]) result[category] = {};

        result[category][DIMENSION_ITEM_CATEGORY] =
          getContentTypeName(category);
        result[category][METRIC_EVENT_COUNT] = sum([
          result[category][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    return orderBy(Object.values(rowsGroupedByCategories), [DIMENSION_DATE]);
  }, [commonReportData]);

  const saveByPlugiloCount = useMemo(() => {
    if (isEmpty(commonReportData)) return 0;

    return sum(
      commonReportData.rows
        .filter((row) => row[DIMENSION_ITEM_SOURCE] === "plugilo")
        .map((item) => item[METRIC_EVENT_COUNT])
    );
  }, [commonReportData]);

  const saveByExtensionCount = useMemo(() => {
    if (isEmpty(commonReportData)) return 0;

    return sum(
      commonReportData.rows
        .filter((row) => row[DIMENSION_ITEM_SOURCE] === "extension")
        .map((item) => item[METRIC_EVENT_COUNT])
    );
  }, [commonReportData]);

  return (
    <Spin spinning={isLoading}>
      <ChartTitle>Save Trend</ChartTitle>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <Card size="small">
            <LineChart
              xAxisDataKey={DIMENSION_DATE}
              data={eventTrendTimeSeriesData}
              lines={[
                {
                  name: "Event",
                  dataKey: METRIC_EVENT_COUNT,
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <Row gutter={GRID_GUTTER}>
            <Col span={12} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Cards"
                  value={saveCardCount}
                  chartProps={{
                    data: eventTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Cards",
                        dataKey: TAG_BOOKMARK,
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
            <Col span={12} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Companies"
                  value={saveCompanyCount}
                  chartProps={{
                    data: eventTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Companies",
                        dataKey: TAG_DOMAIN,
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
            <Col span={12} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Stacks"
                  value={saveStackCount}
                  chartProps={{
                    data: eventTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Stacks",
                        dataKey: TAG_LISTSET,
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
            <Col span={12} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Lists"
                  value={saveListCount}
                  chartProps={{
                    data: eventTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Lists",
                        dataKey: TAG_LIST,
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
            <Col span={12} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Newsletters"
                  value={saveHighlightCount}
                  chartProps={{
                    data: eventTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Newsletters",
                        dataKey: TAG_HIGHLIGHT,
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <ChartTitle>Save Content By Types</ChartTitle>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <Card size="small" className="lg:h-full">
            <div className="h-80">
              <PieChart
                data={saveByTypesPieChartData}
                dataKey={METRIC_EVENT_COUNT}
                nameKey={DIMENSION_ITEM_CATEGORY}
              />
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Row gutter={GRID_GUTTER}>
            <Col xs={24} lg={12} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Save by plugilo portal"
                  value={saveByPlugiloCount}
                />
              </Card>
            </Col>
            <Col xs={24} lg={12} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Save by plugilo extension"
                  value={saveByExtensionCount}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

SaveContents.propTypes = {};

export default SaveContents;
