import React, { useEffect, useMemo, useState } from "react";
import { METRIC_TOTAL_USERS } from "constants/metrics";
import { DIMENSION_AUDIENCE_NAME } from "constants/dimensions";
import { get, isEmpty, orderBy } from "lodash";
import { Card, Col, Row, Spin } from "antd";
import { GRID_GUTTER } from "constants/ui";
import { getBatchReports, reviseReport } from "services/gaService";
import TableChart from "components/charts/TableChart";
import ChartTitle from "components/ChartTitle";
import { formatNumber } from "services/numberService";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import { alertUnknownError } from "services/notificationService";

const Audiences = () => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dateStrings } = useReportContext();

  const reportRequests = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;

    return [
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_AUDIENCE_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
      },
    ];
  }, [dateStrings]);

  useEffect(() => {
    if (!reportRequests) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await getBatchReports(reportRequests);
        setReports(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        setReports([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [reportRequests]);

  const commonReportData = useMemo(() => {
    return reviseReport(reports[0]);
  }, [reports]);

  const audiencesTableData = useMemo(() => {
    if (isEmpty(commonReportData)) return [];

    return orderBy(commonReportData.rows, [METRIC_TOTAL_USERS], ["desc"]);
  }, [commonReportData]);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={24}>
          <ChartTitle>Types</ChartTitle>
          <Card size="small" className="lg:h-full">
            <TableChart
              dataSource={audiencesTableData}
              columns={[
                {
                  title: "Name",
                  dataIndex: DIMENSION_AUDIENCE_NAME,
                  key: DIMENSION_AUDIENCE_NAME,
                  ellipsis: true,
                },
                {
                  title: "Total Users",
                  dataIndex: METRIC_TOTAL_USERS,
                  key: METRIC_TOTAL_USERS,
                  width: 100,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

Audiences.propTypes = {};

export default Audiences;
