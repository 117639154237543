export const sky300 = "#38BDF8";
export const indigo300 = "#818CF8";
export const purple300 = "#C084FC";
export const pink300 = "#F472B6";
export const red300 = "#F87171";
export const amber300 = "#FBBF24";
export const lime300 = "#A3E635";
export const emerald300 = "#34D399";
export const cyan300 = "#22D3EE";
export const gray300 = "#d1d5db";

export const dimensionColors = [
  sky300,
  indigo300,
  purple300,
  pink300,
  red300,
  amber300,
  lime300,
  emerald300,
  cyan300,
  gray300,
];
