import React, { memo } from "react";
import PropTypes from "prop-types";

const TooltipText = memo(({ children }) => {
  return <span className="text-xxs">{children}</span>;
});

TooltipText.propTypes = {
  children: PropTypes.string.isRequired,
};

TooltipText.defaultProps = {
  children: null,
};

export default TooltipText;
