import moment from "moment";

export const MAX_DATE_RANGE_IN_DAYS = 90;
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_PRINT = "DD MMM YYYY";
export const DATE_FORMAT_CHART = "MMM-DD";
export const DATE_FORMAT_METRIC = "YYYYMMDD";
// Note: Always have to get new momentjs object to avoid self-calculated on imported date
// TODO: move to proper place
export const getAdminDefaultDateAvailableReport = () => moment();
export const getDefaultDateAvailableReport = () => moment().subtract(2, "days");
export const getDefaultContentDateAvailableReport = () =>
  moment().subtract(12, "days");
export const DATE_RANGE_TODAY = [moment(), moment()];
export const DATE_RANGE_YESTERDAY = [moment().subtract(1, "day"), moment()];
export const DATE_RANGE_LAST_7_DAYS = [moment().subtract(7, "days"), moment()];
export const DATE_RANGE_LAST_14_DAYS = [
  moment().subtract(14, "days"),
  moment(),
];
export const DATE_RANGE_LAST_28_DAYS = [
  moment().subtract(28, "days"),
  moment(),
];
export const DATE_RANGE_LAST_30_DAYS = [
  moment().subtract(30, "days"),
  moment(),
];
