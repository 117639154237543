import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import { Empty, Table } from "antd";
import { v4 as uuid } from "uuid";
import { getContentNames, getTriggerTexts } from "services/gaService";
import isEmpty from "lodash/isEmpty";
import produce from "immer";
import { toLower, get } from "lodash";
import { useAppContext } from "contexts/AppContextProvider";

const PAGE_SIZE = 10;

const Chart = ({
  columns,
  dataSource,
  rowKey,
  syncNames,
  summary,
  pageSize: initialPageSize,
}) => {
  const [page, setPage] = useState(1);
  const [renderKey, setRenderKey] = useState(uuid());
  const [syncedDataSource, setSyncedDataSource] = useState(dataSource);
  const { isPrintMode, tableRowsLimit } = useAppContext();
  const [userPageSize, setUserPageSize] = useState(initialPageSize);
  const pageSize = useMemo(
    () => (isPrintMode && tableRowsLimit ? tableRowsLimit : userPageSize),
    [userPageSize, isPrintMode, tableRowsLimit]
  );

  useEffect(() => {
    setPage(1);
    setRenderKey(uuid());
    setSyncedDataSource(dataSource);
  }, [dataSource]);

  useEffect(() => {
    if (!syncNames?.idKey || !syncNames?.nameKey || isEmpty(syncedDataSource))
      return;

    async function sync() {
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize - 1;

      const syncedItems = syncedDataSource.slice(startIndex, endIndex + 1);
      const syncedIds = syncedItems
        .filter((item) => item[syncNames.idKey] && !item.$isSynced)
        .map((item) => item[syncNames.idKey]);
      if (isEmpty(syncedIds)) return;

      try {
        let returnSyncedData = null;
        if (syncNames?.type === "trigger-text") {
          returnSyncedData = await getTriggerTexts(syncedIds);
        } else {
          returnSyncedData = await getContentNames(syncedIds);
        }
        // if (isEmpty(returnSyncedData)) return;

        const updatedItems = produce(syncedDataSource, (draft) => {
          for (let i = startIndex; i <= endIndex; i++) {
            const activeItem = draft[i];
            if (isEmpty(activeItem)) continue;

            activeItem.$isSynced = true;
            const matchedItem = (returnSyncedData || []).find(
              (item) =>
                toLower(item.id) === toLower(activeItem[syncNames.idKey]) ||
                toLower(item.sfId) === toLower(activeItem[syncNames.idKey])
            );

            if (matchedItem) {
              activeItem[syncNames.nameKey] =
                matchedItem.title || matchedItem.name || matchedItem.text;
              activeItem[syncNames.idKey] = matchedItem.id || matchedItem.sfId;
              activeItem[syncNames.typeKey] = get(matchedItem, "tags[0]");
            }
          }
        });

        setSyncedDataSource(updatedItems);
      } catch (error) {
        console.error(error);
      }
    }

    sync();
  }, [page, syncedDataSource, syncNames, pageSize]);

  if (isEmpty(syncedDataSource))
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <Table
      key={renderKey}
      pagination={{
        pageSize,
        position: isPrintMode ? ["none", "none"] : "bottomCenter",
        onChange: (page, size) => {
          setPage(page);
          setUserPageSize(size);
        },
        hideOnSinglePage: false,
      }}
      rowKey={rowKey}
      size="small"
      dataSource={syncedDataSource}
      summary={summary}
      columns={[
        {
          render(value, record, index) {
            return (
              <span className="text-gray-400">
                {(page - 1) * pageSize + index + 1}
              </span>
            );
          },
          width: 40,
        },
        ...columns,
      ]}
    />
  );
};

Chart.propTypes = {
  columns: PropTypes.any,
  dataSource: PropTypes.any,
  rowKey: PropTypes.any,
  syncNames: PropTypes.any,
  summary: PropTypes.any,
  pageSize: PropTypes.number,
};

Chart.defaultProps = {
  syncNames: false,
  pageSize: PAGE_SIZE,
};

export default Chart;
