import React, { useEffect, useMemo, useState } from "react";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { DIMENSION_DATE, DIMENSION_EVENT_NAME } from "constants/dimensions";
import {
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_CATEGORY2,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_NAME,
} from "constants/customDimensions";
import { get, isEmpty, orderBy, sum } from "lodash";
import { Card, Col, Row, Spin } from "antd";
import { GRID_GUTTER } from "constants/ui";
import LineChart from "components/charts/LineChart";
import {
  getBatchReports,
  reviseReport,
  reviseTimeSeriesData,
} from "services/gaService";
import {
  buildInListFilter,
  FILTER_EVENT_VIEW_ITEM_EMAIL_DETAILS,
  FILTER_ITEM_SOURCE_EMAIL,
} from "services/gaDimensionsService";
import {
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_EMAIL_DETAILS,
} from "constants/customEvents";
import Scorecard from "components/charts/Scorecard";
import TableChart from "components/charts/TableChart";
import ChartTitle from "components/ChartTitle";
import { formatNumber } from "services/numberService";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import { alertUnknownError } from "services/notificationService";

const buildFilters = (filters, notFilters) => {
  return {
    andGroup: {
      expressions: [...(filters || [])],
    },
  };
};
const syncNamesOptions = {
  idKey: DIMENSION_ITEM_ID,
  nameKey: DIMENSION_ITEM_NAME,
  typeKey: DIMENSION_ITEM_CATEGORY,
};
const SaveContents = () => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dateStrings } = useReportContext();

  const reportRequests = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;

    return [
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_ITEM_ID,
          },
          // {
          //   name: DIMENSION_ITEM_NAME,
          // },
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          buildInListFilter(DIMENSION_EVENT_NAME, [
            EVENT_VIEW_ITEM_EMAIL_DETAILS,
            EVENT_VIEW_ITEM_CLICKED,
          ]),
          FILTER_ITEM_SOURCE_EMAIL,
        ]),
      },
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
          // {
          //   name: DIMENSION_ITEM_NAME,
          // },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
          {
            name: DIMENSION_ITEM_CATEGORY2,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          FILTER_EVENT_VIEW_ITEM_EMAIL_DETAILS,
          FILTER_ITEM_SOURCE_EMAIL,
        ]),
      },
    ];
  }, [dateStrings]);

  useEffect(() => {
    if (!reportRequests) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await getBatchReports(reportRequests);
        setReports(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        setReports([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [reportRequests]);

  const commonReportData = useMemo(() => {
    return reviseReport(reports[0]);
  }, [reports]);

  const emailCampaignsReportData = useMemo(() => {
    return reviseReport(reports[1]);
  }, [reports]);

  const eventTrendTimeSeriesData = useMemo(() => {
    if (isEmpty(commonReportData)) return [];

    const dataKeysMap = {};
    const rowsGroupedByDate = commonReportData.rows
      .filter(
        (item) => item[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_EMAIL_DETAILS
      )
      .reduce((result, current) => {
        const date = current[DIMENSION_DATE];
        const emailId = current[DIMENSION_ITEM_ID];
        if (!result[date]) result[date] = {};

        result[date][DIMENSION_DATE] = date;
        result[date][emailId] = sum([
          result[date][emailId],
          current[METRIC_EVENT_COUNT],
        ]);
        result[date][METRIC_EVENT_COUNT] = sum([
          result[date][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        dataKeysMap[emailId] = emailId;
        return result;
      }, {});

    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [...Object.keys(dataKeysMap), METRIC_EVENT_COUNT],
    });
  }, [commonReportData, dateStrings]);

  const eventTrendTimeSeriesKeysData = useMemo(() => {
    if (isEmpty(commonReportData)) return [];

    const rowsGroupedByDate = commonReportData.rows
      .filter(
        (item) => item[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_EMAIL_DETAILS
      )
      .reduce((result, current) => {
        result[current[DIMENSION_ITEM_ID]] = current[DIMENSION_ITEM_NAME];
        return result;
      }, {});

    return Object.keys(rowsGroupedByDate)
      .map((dataKey) => ({
        name: rowsGroupedByDate[dataKey],
        dataKey,
      }))
      .slice(0, 10);
  }, [commonReportData]);

  const openEmailCount = useMemo(() => {
    if (isEmpty(commonReportData)) return 0;

    return sum(
      commonReportData.rows
        .filter(
          (item) => item[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_EMAIL_DETAILS
        )
        .map((item) => item[METRIC_EVENT_COUNT])
    );
  }, [commonReportData]);

  const clickedEmailCount = useMemo(() => {
    if (isEmpty(commonReportData)) return 0;

    return sum(
      commonReportData.rows
        .filter(
          (item) => item[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED
        )
        .map((item) => item[METRIC_EVENT_COUNT])
    );
  }, [commonReportData]);

  const emailCampaignsReportDataTableData = useMemo(() => {
    if (isEmpty(emailCampaignsReportData)) return [];

    return orderBy(
      emailCampaignsReportData.rows,
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [emailCampaignsReportData]);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={24} className="mb-8">
          <ChartTitle>Trend</ChartTitle>
          <Card size="small">
            <LineChart
              height={600}
              xAxisDataKey={DIMENSION_DATE}
              data={eventTrendTimeSeriesData}
              lines={eventTrendTimeSeriesKeysData}
            />
          </Card>
        </Col>
        <Col xs={24} lg={24} className="mb-8">
          <Row gutter={GRID_GUTTER}>
            <Col span={12}>
              <Card size="small">
                <Scorecard title="Open emails" value={openEmailCount} />
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small">
                <Scorecard title="Email clicks" value={clickedEmailCount} />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={24} className="mb-8">
          <ChartTitle>Email campaigns</ChartTitle>
          <Card size="small" className="lg:h-full">
            <TableChart
              rowKey={DIMENSION_ITEM_ID}
              dataSource={emailCampaignsReportDataTableData}
              syncNames={syncNamesOptions}
              columns={[
                {
                  title: "Name",
                  dataIndex: DIMENSION_ITEM_NAME,
                  key: DIMENSION_ITEM_ID,
                  ellipsis: true,
                },
                {
                  title: "Category",
                  dataIndex: DIMENSION_ITEM_CATEGORY2,
                  key: DIMENSION_ITEM_CATEGORY2,
                  width: 160,
                },
                {
                  title: "Open",
                  dataIndex: METRIC_EVENT_COUNT,
                  key: METRIC_EVENT_COUNT,
                  width: 100,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

SaveContents.propTypes = {};

export default SaveContents;
