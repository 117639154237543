import pako from "pako";
import { isEmpty } from "lodash";
import textMap from "components/TranslatedText/textMap";

export const decompress = (input) => {
  if (isEmpty(input)) return input;

  try {
    const strData = atob(input);
    var charData = strData.split("").map(function (char) {
      return char.charCodeAt(0);
    });
    var binData = new Uint8Array(charData);
    var data = pako.inflate(binData);
    return String.fromCharCode.apply(null, new Uint16Array(data));
  } catch (ex) {
    console.info(ex);
    return input;
  }
};

const getTextByKey = (messageKey) => {
  return textMap[messageKey];
};

export { getTextByKey };
