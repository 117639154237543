import { dimensionColors } from "constants/colors";
import { isNumber } from "lodash";

export const getDimensionColorByIndex = (index, colors) => {
  if (!isNumber(index)) return dimensionColors[0];

  const finalColors = colors || dimensionColors;
  const colorsLength = finalColors.length;
  return finalColors[index % colorsLength];
};
