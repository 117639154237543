import React, { useMemo } from "react";
import { Card, Col, Row, Typography } from "antd";
import { GRID_GUTTER, HIDE_VISITORS } from "constants/ui";
import { METRIC_EVENT_COUNT, METRIC_TOTAL_USERS } from "constants/metrics";
import { isEmpty, sum } from "lodash";
import { DIMENSION_ITEM_ID } from "constants/customDimensions";
import TableChart from "components/charts/TableChart";
import { formatNumber } from "services/numberService";
import { DIMENSION_DATE, DIMENSION_EVENT_NAME } from "constants/dimensions";
import {
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import { reviseTimeSeriesData } from "services/gaService";
import moment from "moment";
import { DATE_FORMAT } from "constants/default";
import { useWidgetsContext } from "../../WidgetsProvider";

const DailyTrafficSection = ({
  dateStrings,
  contentViewsReportData,
  widgetImpressionsReportData,
  usersReportData,
}) => {
  const { clicksEnabled, adImpressionsEnabled } = useWidgetsContext();

  const usersTimeSeriesData = useMemo(() => {
    if (isEmpty(usersReportData)) return [];

    const rowsGroupedByDate = usersReportData.rows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_EVENT_COUNT] = sum([
        result[date][METRIC_EVENT_COUNT],
        current[METRIC_TOTAL_USERS],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(
      Object.values(rowsGroupedByDate),
      {
        dateKey: DIMENSION_DATE,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        dataKeys: [METRIC_EVENT_COUNT],
      },
      "desc"
    );
  }, [dateStrings, usersReportData]);

  const widgetImpressionsTimeSeriesData = useMemo(() => {
    if (isEmpty(widgetImpressionsReportData)) return [];

    const rowsGroupedByDate = widgetImpressionsReportData.rows.reduce(
      (result, current) => {
        const date = current[DIMENSION_DATE];
        if (!result[date]) result[date] = {};
        result[date].date = date;
        result[date][METRIC_EVENT_COUNT] = sum([
          result[date][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    return reviseTimeSeriesData(
      Object.values(rowsGroupedByDate),
      {
        dateKey: DIMENSION_DATE,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        dataKeys: [METRIC_EVENT_COUNT],
      },
      "desc"
    );
  }, [dateStrings, widgetImpressionsReportData]);

  const activeViewsTimeSeriesData = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return [];

    const filteredRows = contentViewsReportData.rows.filter(
      (row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS
    );
    const rowsGroupedByDate = filteredRows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_EVENT_COUNT] = sum([
        result[date][METRIC_EVENT_COUNT],
        current[METRIC_EVENT_COUNT],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(
      Object.values(rowsGroupedByDate),
      {
        dateKey: DIMENSION_DATE,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        dataKeys: [METRIC_EVENT_COUNT],
      },
      "desc"
    );
  }, [dateStrings, contentViewsReportData]);

  const impressionsTimeSeriesData = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return [];

    const filteredRows = contentViewsReportData.rows.filter(
      (row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION
    );
    const rowsGroupedByDate = filteredRows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_EVENT_COUNT] = sum([
        result[date][METRIC_EVENT_COUNT],
        current[METRIC_EVENT_COUNT],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(
      Object.values(rowsGroupedByDate),
      {
        dateKey: DIMENSION_DATE,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        dataKeys: [METRIC_EVENT_COUNT],
      },
      "desc"
    );
  }, [dateStrings, contentViewsReportData]);

  const clicksTimeSeriesData = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return [];

    const filteredRows = contentViewsReportData.rows.filter(
      (row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED
    );
    const rowsGroupedByDate = filteredRows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_EVENT_COUNT] = sum([
        result[date][METRIC_EVENT_COUNT],
        current[METRIC_EVENT_COUNT],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(
      Object.values(rowsGroupedByDate),
      {
        dateKey: DIMENSION_DATE,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        dataKeys: [METRIC_EVENT_COUNT],
      },
      "desc"
    );
  }, [dateStrings, contentViewsReportData]);

  const tableData = useMemo(() => {
    if (
      isEmpty(usersTimeSeriesData) &&
      isEmpty(clicksTimeSeriesData) &&
      isEmpty(activeViewsTimeSeriesData) &&
      isEmpty(widgetImpressionsTimeSeriesData) &&
      isEmpty(impressionsTimeSeriesData)
    )
      return [];

    const dataLength =
      usersTimeSeriesData.length ||
      clicksTimeSeriesData.length ||
      activeViewsTimeSeriesData.length ||
      widgetImpressionsTimeSeriesData.length ||
      impressionsTimeSeriesData.length;

    return Array(dataLength)
      .fill()
      .map((_, index) => {
        const widgetItem = widgetImpressionsTimeSeriesData[index] || {};
        const userItem = usersTimeSeriesData[index] || {};
        const activeViewItem = activeViewsTimeSeriesData[index] || {};
        const clickItem = clicksTimeSeriesData[index] || {};
        const impressionItem = impressionsTimeSeriesData[index] || {};
        return {
          ...widgetItem,
          adImpressions: widgetItem[METRIC_EVENT_COUNT] || 0,
          impressions: impressionItem[METRIC_EVENT_COUNT] || 0,
          users: userItem[METRIC_EVENT_COUNT] || 0,
          activeViews: activeViewItem[METRIC_EVENT_COUNT] || 0,
          clicks: clickItem[METRIC_EVENT_COUNT] || 0,
        };
      });
  }, [
    usersTimeSeriesData,
    clicksTimeSeriesData,
    activeViewsTimeSeriesData,
    widgetImpressionsTimeSeriesData,
    impressionsTimeSeriesData,
  ]);

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: "Date",
        dataIndex: DIMENSION_DATE,
        key: DIMENSION_DATE,
        render(value) {
          return moment(value).format(DATE_FORMAT);
        },
      },
      {
        title: "Ad Impressions",
        dataIndex: "adImpressions",
        key: "adImpressions",
        align: "right",
        width: 200,
        render: (value) => formatNumber(value),
        disabled: !adImpressionsEnabled,
      },
      {
        title: "Visitors",
        dataIndex: "users",
        key: "users",
        align: "right",
        width: 200,
        render: (value) => formatNumber(value),
        disabled: HIDE_VISITORS,
      },
      {
        title: "Content Visibility",
        dataIndex: "impressions",
        key: "impressions",
        align: "right",
        width: 200,
        render: (value) => formatNumber(value),
        disabled: adImpressionsEnabled,
      },
      {
        title: "Active Views",
        dataIndex: "activeViews",
        key: "activeViews",
        align: "right",
        width: 200,
        render: (value) => formatNumber(value),
      },
      {
        title: "Clicks",
        dataIndex: "clicks",
        key: "clicks",
        align: "right",
        width: 200,
        render: (value) => formatNumber(value),
        disabled: !clicksEnabled,
      },
    ];

    return columns.filter((column) => !column.disabled);
  }, [adImpressionsEnabled, clicksEnabled]);

  return (
    <>
      <Typography.Title level={5}>Daily Traffic</Typography.Title>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={24} className="mb-8">
          <Card size="small" className="">
            <TableChart
              rowKey={DIMENSION_ITEM_ID}
              dataSource={tableData}
              columns={tableColumns}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

DailyTrafficSection.propTypes = {};

export default DailyTrafficSection;
