import React, { useEffect, useMemo, useState } from "react";
import {
  METRIC_ACTIVE_1_DAY_USERS,
  METRIC_ACTIVE_28_DAY_USERS,
  METRIC_ACTIVE_7_DAY_USERS,
  METRIC_EVENT_COUNT,
  METRIC_NEW_USERS,
  METRIC_TOTAL_USERS,
  METRIC_VIEWS,
} from "constants/metrics";
import {
  DIMENSION_COUNTRY,
  DIMENSION_COUNTRY_ID,
  DIMENSION_DATE,
  DIMENSION_FIRST_USER_DEFAULT_CHANNEL_GROUPING,
  DIMENSION_FULL_PAGE_URL,
  DIMENSION_PAGE_TITLE,
  EMPTY_DIMENSION_VALUES,
} from "constants/dimensions";
import {
  DIMENSION_ITEM_BRAND,
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_NAME,
} from "constants/customDimensions";
import { get, isEmpty, orderBy, sum } from "lodash";
import { Card, Col, Row, Spin } from "antd";
import { GRID_GUTTER } from "constants/ui";
import LineChart from "components/charts/LineChart";
import {
  getBatchReports,
  getContentTypeName,
  reviseReport,
  reviseTimeSeriesData,
} from "services/gaService";
import {
  FILTER_EVENT_VIEW_ITEM_DETAILS,
  FILTER_EVENT_VIEW_ITEM_EMAIL_DETAILS,
  FILTER_ITEM_SOURCE_EMAIL,
} from "services/gaDimensionsService";
import Favicon from "components/Favicon";
import ContentType from "components/ContentType";
import Scorecard from "components/charts/Scorecard";
import PieChart from "components/charts/PieChart";
import VerticalBarChart from "components/charts/BarChart/VerticalBarChart";
import TableChart from "components/charts/TableChart";
import ContentLink from "components/ContentLink";
import { formatNumber } from "services/numberService";
import ChartTitle from "components/ChartTitle";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import { alertUnknownError } from "services/notificationService";

const buildFilters = (filters, notFilters) => {
  return {
    andGroup: {
      expressions: [...(filters || [])],
    },
  };
};

const syncNamesOptions = {
  idKey: DIMENSION_ITEM_ID,
  nameKey: DIMENSION_ITEM_NAME,
  typeKey: DIMENSION_ITEM_CATEGORY,
};

const AdminOverview = () => {
  const [batchReports01, setReports01] = useState([]);
  const [batchReports02, setReports02] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dateStrings } = useReportContext();

  const batchReportRequests01 = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;

    return [
      // 0: User trend
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
          {
            name: METRIC_NEW_USERS,
          },
          {
            name: METRIC_ACTIVE_1_DAY_USERS,
          },
          {
            name: METRIC_ACTIVE_7_DAY_USERS,
          },
          {
            name: METRIC_ACTIVE_28_DAY_USERS,
          },
        ],
      },
      // 1: Email
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
          // {
          //   name: DIMENSION_ITEM_NAME,
          // },
          {
            name: DIMENSION_ITEM_BRAND,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          FILTER_EVENT_VIEW_ITEM_EMAIL_DETAILS,
          FILTER_ITEM_SOURCE_EMAIL,
        ]),
        limit: 100,
        offset: 0,
      },
      // 2: Active Views contents
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
          // {
          //   name: DIMENSION_ITEM_NAME,
          // },
          {
            name: DIMENSION_ITEM_BRAND,
          },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([FILTER_EVENT_VIEW_ITEM_DETAILS]),
      },
      // 3: Active Views by content types
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([FILTER_EVENT_VIEW_ITEM_DETAILS]),
      },
      // 4: Users by countries
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_COUNTRY_ID,
          },
          {
            name: DIMENSION_COUNTRY,
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
      },
    ];
  }, [dateStrings]);

  const batchReportRequests02 = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;

    return [
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_FIRST_USER_DEFAULT_CHANNEL_GROUPING,
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
          {
            name: METRIC_NEW_USERS,
          },
        ],
      },
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_PAGE_TITLE,
          },
          {
            name: DIMENSION_FULL_PAGE_URL,
          },
        ],
        metrics: [
          {
            name: METRIC_VIEWS,
          },
        ],
        orderBys: [
          {
            desc: true,
            metric: {
              metricName: METRIC_VIEWS,
            },
          },
        ],
        limit: 100,
        offset: 0,
      },
    ];
  }, [dateStrings]);

  const resetData = () => {
    setReports01([]);
    setReports02([]);
  };

  useEffect(() => {
    if (!batchReportRequests01) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const batchData01 = await getBatchReports(batchReportRequests01);
        setReports01(get(batchData01, "reports", []));
      } catch (error) {
        alertUnknownError();
        resetData();
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [batchReportRequests01]);

  useEffect(() => {
    if (!batchReportRequests02) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const batchData02 = await getBatchReports(batchReportRequests02);
        setReports02(get(batchData02, "reports", []));
      } catch (error) {
        alertUnknownError();
        resetData();
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [batchReportRequests02]);

  const usersReportData = useMemo(() => {
    return reviseReport(batchReports01[0]);
  }, [batchReports01]);

  const emailContentsReportData = useMemo(() => {
    return reviseReport(batchReports01[1]);
  }, [batchReports01]);

  const activeViewContentsReportData = useMemo(() => {
    return reviseReport(batchReports01[2]);
  }, [batchReports01]);

  const activeViewByContentTypesReportData = useMemo(() => {
    return reviseReport(batchReports01[3]);
  }, [batchReports01]);

  const usersByCountryReportData = useMemo(() => {
    return reviseReport(batchReports01[4]);
  }, [batchReports01]);

  const mediumReportData = useMemo(() => {
    return reviseReport(batchReports02[0]);
  }, [batchReports02]);

  const pageViewsReportData = useMemo(() => {
    return reviseReport(batchReports02[1]);
  }, [batchReports02]);

  const usersTrendTimeSeriesData = useMemo(() => {
    if (isEmpty(usersReportData)) return [];

    const rowsGroupedByDate = usersReportData.rows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_TOTAL_USERS] = sum([
        result[date][METRIC_TOTAL_USERS],
        current[METRIC_TOTAL_USERS],
      ]);
      result[date][METRIC_NEW_USERS] = sum([
        result[date][METRIC_NEW_USERS],
        current[METRIC_NEW_USERS],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [METRIC_TOTAL_USERS, METRIC_NEW_USERS],
    });
  }, [dateStrings, usersReportData]);

  const totalUsersCount = useMemo(() => {
    if (isEmpty(usersReportData)) return 0;

    return sum(usersReportData.rows.map((item) => item[METRIC_TOTAL_USERS]));
  }, [usersReportData]);

  const newUsersCount = useMemo(() => {
    if (isEmpty(usersTrendTimeSeriesData)) return 0;

    return sum(usersTrendTimeSeriesData.map((item) => item[METRIC_NEW_USERS]));
  }, [usersTrendTimeSeriesData]);

  const mediumPieChartData = useMemo(() => {
    if (isEmpty(mediumReportData)) return [];

    const filteredRows = mediumReportData.rows.filter(
      (row) =>
        !EMPTY_DIMENSION_VALUES.includes(
          row[DIMENSION_FIRST_USER_DEFAULT_CHANNEL_GROUPING]
        )
    );
    return orderBy(filteredRows, [METRIC_NEW_USERS], "desc").slice(0, 10);
  }, [mediumReportData]);

  const pageViewsTableData = useMemo(() => {
    if (isEmpty(pageViewsReportData)) return [];

    const filteredRows = pageViewsReportData.rows.filter(
      (row) => !EMPTY_DIMENSION_VALUES.includes(row[DIMENSION_PAGE_TITLE])
    );
    return orderBy(filteredRows, [METRIC_VIEWS], ["desc"]).slice(0, 10);
  }, [pageViewsReportData]);

  const activeUsersTrendTimeSeriesData = useMemo(() => {
    if (isEmpty(usersReportData)) return [];

    const rowsGroupedByDate = usersReportData.rows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_ACTIVE_1_DAY_USERS] = sum([
        result[date][METRIC_ACTIVE_1_DAY_USERS],
        current[METRIC_ACTIVE_1_DAY_USERS],
      ]);
      result[date][METRIC_ACTIVE_7_DAY_USERS] = sum([
        result[date][METRIC_ACTIVE_7_DAY_USERS],
        current[METRIC_ACTIVE_7_DAY_USERS],
      ]);
      result[date][METRIC_ACTIVE_28_DAY_USERS] = sum([
        result[date][METRIC_ACTIVE_28_DAY_USERS],
        current[METRIC_ACTIVE_28_DAY_USERS],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [
        METRIC_ACTIVE_1_DAY_USERS,
        METRIC_ACTIVE_7_DAY_USERS,
        METRIC_ACTIVE_28_DAY_USERS,
      ],
    });
  }, [dateStrings, usersReportData]);

  const emailContentsTableData = useMemo(() => {
    if (isEmpty(emailContentsReportData)) return [];

    const rowsGroupedByDate = emailContentsReportData.rows.reduce(
      (result, current) => {
        const itemId = current[DIMENSION_ITEM_ID];
        if (!result[itemId]) result[itemId] = {};
        result[itemId][DIMENSION_ITEM_ID] = itemId;
        // result[itemId][DIMENSION_ITEM_NAME] = current[DIMENSION_ITEM_NAME];
        result[itemId][DIMENSION_ITEM_BRAND] = current[DIMENSION_ITEM_BRAND];
        result[itemId][DIMENSION_ITEM_CATEGORY] =
          current[DIMENSION_ITEM_CATEGORY];
        result[itemId][METRIC_EVENT_COUNT] = sum([
          result[itemId][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    return orderBy(
      Object.values(rowsGroupedByDate),
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [emailContentsReportData]);

  const activeViewContentsTableData = useMemo(() => {
    if (isEmpty(activeViewContentsReportData)) return [];

    const rowsGroupedByDate = activeViewContentsReportData.rows.reduce(
      (result, current) => {
        const itemId = current[DIMENSION_ITEM_ID];
        if (!result[itemId]) result[itemId] = {};
        result[itemId][DIMENSION_ITEM_ID] = itemId;
        // result[itemId][DIMENSION_ITEM_NAME] = current[DIMENSION_ITEM_NAME];
        result[itemId][DIMENSION_ITEM_BRAND] = current[DIMENSION_ITEM_BRAND];
        result[itemId][DIMENSION_ITEM_CATEGORY] =
          current[DIMENSION_ITEM_CATEGORY];
        result[itemId][METRIC_EVENT_COUNT] = sum([
          result[itemId][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    return orderBy(
      Object.values(rowsGroupedByDate),
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [activeViewContentsReportData]);

  const activeViewsByTypesPieChartData = useMemo(() => {
    if (isEmpty(activeViewByContentTypesReportData)) return [];

    const rowsGroupedBySource = activeViewByContentTypesReportData.rows.reduce(
      (result, current) => {
        const category = current[DIMENSION_ITEM_CATEGORY];
        if (!result[category]) result[category] = {};

        result[category][DIMENSION_ITEM_CATEGORY] =
          getContentTypeName(category);
        result[category][METRIC_EVENT_COUNT] = sum([
          result[category][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    return orderBy(Object.values(rowsGroupedBySource), [DIMENSION_DATE]);
  }, [activeViewByContentTypesReportData]);

  const usersByCountriesTableData = useMemo(() => {
    if (
      isEmpty(usersByCountryReportData) ||
      isEmpty(usersByCountryReportData.rows)
    )
      return [];

    return orderBy(
      usersByCountryReportData.rows,
      [METRIC_TOTAL_USERS],
      ["desc"]
    );
  }, [usersByCountryReportData]);

  return (
    <Spin spinning={isLoading}>
      <ChartTitle>Users trend</ChartTitle>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <Card size="small">
            <LineChart
              xAxisDataKey="date"
              data={usersTrendTimeSeriesData}
              lines={[
                {
                  name: "New users",
                  dataKey: METRIC_NEW_USERS,
                },
                {
                  name: "Total Users",
                  dataKey: METRIC_TOTAL_USERS,
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <Row gutter={GRID_GUTTER}>
            <Col span={24} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Total Users"
                  value={totalUsersCount}
                  chartProps={{
                    data: usersTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Event",
                        dataKey: METRIC_TOTAL_USERS,
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
            <Col span={24} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="New Users"
                  value={newUsersCount}
                  chartProps={{
                    data: usersTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Event",
                        dataKey: METRIC_NEW_USERS,
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Where Do Your New Users Come From?</ChartTitle>
          <Card
            style={{
              height: 480,
            }}
          >
            <VerticalBarChart
              yAxisDataKey={DIMENSION_FIRST_USER_DEFAULT_CHANNEL_GROUPING}
              data={mediumPieChartData}
              bars={[
                {
                  name: "Total Users",
                  dataKey: METRIC_NEW_USERS,
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>What Are Your Top Email Campaigns?​</ChartTitle>
          <Card>
            <TableChart
              rowKey={DIMENSION_ITEM_ID}
              dataSource={emailContentsTableData}
              syncNames={syncNamesOptions}
              columns={[
                {
                  title: "Content",
                  dataIndex: DIMENSION_ITEM_NAME,
                  key: DIMENSION_ITEM_NAME,
                  ellipsis: true,
                },
                {
                  title: "Active Views",
                  dataIndex: METRIC_EVENT_COUNT,
                  key: METRIC_EVENT_COUNT,
                  width: 120,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>How Are Active Users Trending?</ChartTitle>
          <Card size="small">
            <LineChart
              xAxisDataKey="date"
              data={activeUsersTrendTimeSeriesData}
              lines={[
                {
                  name: "1-day active users",
                  dataKey: METRIC_ACTIVE_1_DAY_USERS,
                },
                {
                  name: "7-day active users",
                  dataKey: METRIC_ACTIVE_7_DAY_USERS,
                },
                {
                  name: "28-day active users",
                  dataKey: METRIC_ACTIVE_28_DAY_USERS,
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Where Are Your Users Visiting From?</ChartTitle>
          <Card size="small">
            <TableChart
              rowKey={DIMENSION_COUNTRY_ID}
              dataSource={usersByCountriesTableData}
              columns={[
                {
                  title: "Country",
                  dataIndex: DIMENSION_COUNTRY,
                  key: DIMENSION_COUNTRY,
                },
                {
                  title: "Total Users",
                  dataIndex: METRIC_TOTAL_USERS,
                  key: METRIC_TOTAL_USERS,
                  width: 120,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Top Active views contents</ChartTitle>
          <Card size="small">
            <TableChart
              rowKey={DIMENSION_ITEM_ID}
              dataSource={activeViewContentsTableData}
              syncNames={syncNamesOptions}
              columns={[
                {
                  title: "Content",
                  dataIndex: DIMENSION_ITEM_ID,
                  key: DIMENSION_ITEM_ID,
                  ellipsis: true,
                  render: (_, record) => (
                    <ContentLink
                      id={record[DIMENSION_ITEM_ID]}
                      name={record[DIMENSION_ITEM_NAME]}
                      tag={record[DIMENSION_ITEM_CATEGORY]}
                      ownerDomain={record[DIMENSION_ITEM_BRAND]}
                    />
                  ),
                },
                {
                  title: "Type",
                  dataIndex: DIMENSION_ITEM_CATEGORY,
                  key: DIMENSION_ITEM_CATEGORY,
                  render: (value) => <ContentType tag={value} />,
                  width: 80,
                },
                {
                  title: "Active Views",
                  dataIndex: METRIC_EVENT_COUNT,
                  key: METRIC_EVENT_COUNT,
                  width: 120,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Active views by content types</ChartTitle>
          <Card size="small" className="lg:h-full">
            <div className="h-80">
              <PieChart
                data={activeViewsByTypesPieChartData}
                dataKey={METRIC_EVENT_COUNT}
                nameKey={DIMENSION_ITEM_CATEGORY}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Which Pages And Screens Get The Most Views?</ChartTitle>
          <Card size="small">
            <TableChart
              rowKey={DIMENSION_PAGE_TITLE}
              dataSource={pageViewsTableData}
              columns={[
                {
                  title: "Content",
                  dataIndex: DIMENSION_PAGE_TITLE,
                  key: DIMENSION_PAGE_TITLE,
                  render: (value, record) => {
                    return (
                      <a
                        href={`https://${record[DIMENSION_FULL_PAGE_URL]}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Favicon
                          url={record[DIMENSION_FULL_PAGE_URL]}
                          className="mr-2"
                        />
                        {record[DIMENSION_PAGE_TITLE]}
                      </a>
                    );
                  },
                  ellipsis: true,
                },
                {
                  title: "Views",
                  dataIndex: METRIC_VIEWS,
                  key: METRIC_VIEWS,
                  width: 120,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8"></Col>
      </Row>
    </Spin>
  );
};

AdminOverview.propTypes = {};

export default AdminOverview;
