export const DIMENSION_DATE = "date";
export const DIMENSION_COHORT = "cohort";
export const DIMENSION_COHORT_NTH_DAY = "cohortNthDay";
export const DIMENSION_FIRST_SESSION_DATE = "firstSessionDate";
export const DIMENSION_EVENT_NAME = "eventName";
export const DIMENSION_HOSTNAME = "hostName";
export const DIMENSION_HOSTNAME_FULL = "hostNameFull";
export const DIMENSION_AUDIENCE_NAME = "audienceName";
export const DIMENSION_MEDIUM = "medium";
export const DIMENSION_FIRST_USER_DEFAULT_CHANNEL_GROUPING =
  "firstUserDefaultChannelGrouping";
export const DIMENSION_COUNTRY = "country";
export const DIMENSION_COUNTRY_ID = "countryId";
export const DIMENSION_PAGE_TITLE = "pageTitle";
export const DIMENSION_FULL_PAGE_URL = "fullPageUrl";
export const DIMENSION_OPERATING_SYSTEM = "operatingSystem";
export const DIMENSION_DEVICE_CATEGORY = "deviceCategory";
export const DIMENSION_BROWSER = "browser";
export const DIMENSION_SCREEN_RESOLUTION = "screenResolution";
export const DIMENSION_SEARCH_TERM = "searchTerm";
export const NOT_SET_DIMENSION_VALUE = "(not set)";
export const EMPTY_DIMENSION_VALUES = [NOT_SET_DIMENSION_VALUE, "Others", ""];
