import React from "react";
import { LABEL_INACTIVE } from "constants/ui";

function Inactive() {
  return <span className="text-gray-400">{LABEL_INACTIVE}</span>;
}

Inactive.propTypes = {};

export default Inactive;
