import {
  SOURCE_EMAIL,
  SOURCE_PLUGILO,
  SOURCE_WIDGET,
} from "constants/contentSources";
import {
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_NAME,
  DIMENSION_ITEM_SOURCE,
} from "constants/customDimensions";
import {
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_EMAIL_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import { DIMENSION_EVENT_NAME } from "constants/dimensions";
import { METRIC_EVENT_COUNT } from "constants/metrics";

export const getCount = (row) => {
  if (!row) return 0;

  const source = row[DIMENSION_ITEM_SOURCE];
  const eventName = row[DIMENSION_EVENT_NAME];
  const matchPlugilo =
    source === SOURCE_PLUGILO &&
    [EVENT_VIEW_ITEM_DETAILS, EVENT_VIEW_ITEM_IMPRESSION].includes(eventName);
  const matchWidget =
    source === SOURCE_WIDGET && [EVENT_VIEW_ITEM_DETAILS].includes(eventName);
  const matchEmail =
    source === SOURCE_EMAIL &&
    [
      EVENT_VIEW_ITEM_EMAIL_DETAILS,
      EVENT_VIEW_ITEM_CLICKED,
      EVENT_VIEW_ITEM_IMPRESSION,
    ].includes(eventName);

  return matchPlugilo || matchWidget || matchEmail
    ? row[METRIC_EVENT_COUNT]
    : 0;
};

export const sourceNamesMap = {
  [SOURCE_PLUGILO]: "plugilo",
  [SOURCE_WIDGET]: "Widgets",
  [SOURCE_EMAIL]: "Email",
};

export const sourceOptions = [
  { label: "All", value: "" },
  { label: "plugilo", value: SOURCE_PLUGILO },
  { label: "Widget", value: SOURCE_WIDGET },
  { label: "Email", value: SOURCE_EMAIL },
];

export const defaultOrderBys = [
  {
    desc: true,
    metric: {
      metricName: METRIC_EVENT_COUNT,
    },
  },
];

export const IGNORE_ITEM_CATEGORIES = ["infoboard", ""];

export const SYNC_NAMES_OPTIONS = {
  idKey: DIMENSION_ITEM_ID,
  nameKey: DIMENSION_ITEM_NAME,
  typeKey: DIMENSION_ITEM_CATEGORY,
};

export const SOURCE_ALL = [
  {
    name: "plugilo",
    dataKey: SOURCE_PLUGILO,
  },
  {
    name: "Widgets",
    dataKey: SOURCE_WIDGET,
  },
  {
    name: "Email",
    dataKey: SOURCE_EMAIL,
  },
];

export const FILTER_ALL = [SOURCE_PLUGILO, SOURCE_EMAIL, SOURCE_WIDGET];
