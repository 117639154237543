import PropTypes from "prop-types";
import React, { useContext } from "react";
import { noop } from "lodash";

const BulkContentViewsContext = React.createContext({
  contentData: null,
});
BulkContentViewsContext.displayName = "BulkContentViewsContext";

export const useContentViewsContext = () => useContext(BulkContentViewsContext);

export default function BulkContentViewsProvider({ children, value }) {
  return (
    <BulkContentViewsContext.Provider value={value}>
      {children}
    </BulkContentViewsContext.Provider>
  );
}

BulkContentViewsProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.object,
};
