import React, { useCallback, useEffect, useMemo, useState } from "react";
import { METRIC_EVENT_COUNT, METRIC_TOTAL_USERS } from "constants/metrics";
import {
  DIMENSION_DATE,
  DIMENSION_EVENT_NAME,
  DIMENSION_HOSTNAME,
  DIMENSION_HOSTNAME_FULL,
} from "constants/dimensions";
import {
  DIMENSION_ITEM_BRAND,
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_SOURCE_ID,
  DIMENSION_ITEM_SOURCE_NAME,
  DIMENSION_ITEM_SOURCE_STREAM_ID,
  DIMENSION_ROLE,
} from "constants/customDimensions";
import { get, isEmpty, sum, sumBy, uniqBy } from "lodash";
import { Col, Row, Spin, Select, Space, Tag } from "antd";
import { GRID_GUTTER, HIDE_VISITORS } from "constants/ui";
import {
  getBatchReports,
  reviseReport,
  reviseTimeSeriesData,
  getTriggerTexts,
} from "services/gaService";
import {
  FILTER_EVENT_VIEW_ITEM_DETAILS,
  buildStringFilter,
  FILTER_EVENT_VIEW_ITEM_CLICKED,
  buildInListFilter,
  FILTER_ITEM_SOURCE_TRIGGER_TEXT,
} from "services/gaDimensionsService";
import {
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import Favicon from "components/Favicon";
import { useParams } from "react-router";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import OverviewSection from "./components/OverviewSection";
import ContentActiveViewsSection from "./components/ContentActiveViewsSection";
import ContentClicksSection from "./components/ContentClicksSection";
import ContentActiveViewsByPartnersSection from "./components/ContentActiveViewsByPartnersSection";
import ContentClicksByPartnersSection from "./components/ContentClicksByPartnersSection";
import UsersByPartnersSection from "./components/UsersByPartnersSection";
import DailyTrafficSection from "./components/DailyTrafficSection";
import useSetQueryString from "hooks/useSetQueryString";
import {
  PARAM_TRIGGER_TEXT_HOST,
  PARAM_TRIGGER_TEXT_ID,
  PARAM_TRIGGER_TEXT_OWNER,
} from "constants/queryStringParams";
import useQueryString from "hooks/useQueryString";
import { alertUnknownError } from "services/notificationService";
import TriggerTextsContextProvider from "./TriggerTextsContext";
import TriggerTextActiveViewsSection from "./components/TriggerTextActiveViewsSection";
import TriggerTextImpressionsByPartnersSection from "./components/TriggerTextImpressionsByPartnersSection";
import { refineDomainName } from "services/companyService";

const defaultOrderBys = [
  {
    desc: true,
    metric: {
      metricName: METRIC_EVENT_COUNT,
    },
  },
];
const DEFAULT_TRIGGER_TEXT_OWNER = "DEFAULT";

const TriggerTexts = () => {
  const [setParam, _, removeParam] = useSetQueryString();
  const {
    [PARAM_TRIGGER_TEXT_HOST]: paramHost,
    [PARAM_TRIGGER_TEXT_ID]: paramTriggerTextId,
  } = useQueryString();
  const [reports01, setReports01] = useState([]);
  const [reports02, setReports02] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dateStrings } = useReportContext();
  const [selectedHostName, setSelectedHostName] = useState(paramHost);
  const [selectedHostNameFull, setSelectedHostNameFull] = useState(null);
  const [selectedTriggerOwnerDomain, setSelectedTriggerOwnerDomain] =
    useState(null);
  const [selectedTriggerTextId, setSelectedTriggerTextId] =
    useState(paramTriggerTextId);
  const [availableTriggerTexts, setAvailableTriggerTexts] = useState([]);
  const { id: domain, text: filterTriggerText } = useParams();

  const buildFilters = useCallback(
    (filters) => {
      if (filterTriggerText) {
        return {
          andGroup: {
            expressions: [
              FILTER_ITEM_SOURCE_TRIGGER_TEXT,
              buildStringFilter(DIMENSION_ITEM_SOURCE_NAME, filterTriggerText),
              ...(filters || []),
            ],
          },
        };
      }

      return {
        andGroup: {
          expressions: [
            FILTER_ITEM_SOURCE_TRIGGER_TEXT,
            buildStringFilter(DIMENSION_ITEM_SOURCE_STREAM_ID, domain),
            ...(filters || []),
          ],
        },
      };
    },
    [domain, filterTriggerText]
  );

  const reportRequests01 = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;

    const commonFilters = [];
    if (filterTriggerText)
      if (selectedTriggerOwnerDomain) {
        commonFilters.push(
          buildStringFilter(
            DIMENSION_ITEM_SOURCE_STREAM_ID,
            selectedTriggerOwnerDomain
          )
        );
      } else {
        commonFilters.push(
          buildStringFilter(
            DIMENSION_ITEM_SOURCE_STREAM_ID,
            DEFAULT_TRIGGER_TEXT_OWNER
          )
        );
      }

    return [
      // 0: General Filters
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_HOSTNAME,
          },
          {
            name: DIMENSION_ITEM_SOURCE_ID,
          },
          {
            name: DIMENSION_ITEM_SOURCE_STREAM_ID,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters(),
      },
      // 1: Trigger Text Impression And Details
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_HOSTNAME,
          },
          {
            name: DIMENSION_ITEM_SOURCE_ID,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters(commonFilters),
      },
    ];
  }, [
    buildFilters,
    dateStrings,
    filterTriggerText,
    selectedTriggerOwnerDomain,
  ]);

  const reportRequests02 = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;
    const commonFilters = [];

    if (selectedHostNameFull)
      commonFilters.push(
        buildStringFilter(DIMENSION_HOSTNAME, selectedHostNameFull)
      );

    if (selectedTriggerTextId)
      commonFilters.push(
        buildStringFilter(DIMENSION_ITEM_SOURCE_ID, selectedTriggerTextId)
      );

    if (filterTriggerText)
      if (selectedTriggerOwnerDomain) {
        commonFilters.push(
          buildStringFilter(
            DIMENSION_ITEM_SOURCE_STREAM_ID,
            selectedTriggerOwnerDomain
          )
        );
      } else {
        commonFilters.push(
          buildStringFilter(
            DIMENSION_ITEM_SOURCE_STREAM_ID,
            DEFAULT_TRIGGER_TEXT_OWNER
          )
        );
      }

    return [
      // 0: Users
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
        dimensionFilter: buildFilters([
          buildInListFilter(DIMENSION_EVENT_NAME, [
            EVENT_VIEW_ITEM_CLICKED,
            EVENT_VIEW_ITEM_DETAILS,
          ]),
          ...commonFilters,
        ]),
      },
      // 1: Total users
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_HOSTNAME,
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
        dimensionFilter: buildFilters([...commonFilters]),
      },
      // 2: Content Active Views
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
          {
            name: DIMENSION_ITEM_BRAND,
          },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          FILTER_EVENT_VIEW_ITEM_DETAILS,
          ...commonFilters,
        ]),
        limit: 100,
        offset: 0,
        orderBys: defaultOrderBys,
      },
      // 3: Top clicked contents
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
          {
            name: DIMENSION_ITEM_BRAND,
          },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
          {
            name: DIMENSION_ROLE,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          FILTER_EVENT_VIEW_ITEM_CLICKED,
          ...commonFilters,
        ]),
        limit: 100,
        offset: 0,
        orderBys: defaultOrderBys,
      },
      // 4: Top clicked roles
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ROLE,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          FILTER_EVENT_VIEW_ITEM_CLICKED,
          ...commonFilters,
        ]),
        limit: 100,
        offset: 0,
        orderBys: defaultOrderBys,
      },
    ];
  }, [
    dateStrings,
    selectedHostNameFull,
    selectedTriggerTextId,
    filterTriggerText,
    selectedTriggerOwnerDomain,
    buildFilters,
  ]);

  const resetData = () => {
    setReports01([]);
    setReports02([]);
  };

  useEffect(() => {
    if (!reportRequests01) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await getBatchReports(reportRequests01);
        setReports01(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        resetData();
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [reportRequests01]);

  useEffect(() => {
    if (!reportRequests02) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await getBatchReports(reportRequests02);
        setReports02(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        resetData();
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [reportRequests02]);

  const commonFilter = useCallback(
    (report, otherFilter) => {
      if (isEmpty(report)) return {};

      const rows = report.rows.filter((row) => {
        const matchHostName = selectedHostName
          ? row[DIMENSION_HOSTNAME] === selectedHostName
          : true;
        const matchTriggerTextId = selectedTriggerTextId
          ? row[DIMENSION_ITEM_SOURCE_ID] === selectedTriggerTextId
          : true;

        const matchOtherFilter = otherFilter ? otherFilter(row) : true;

        return matchHostName && matchOtherFilter && matchTriggerTextId;
      });

      return {
        ...report,
        rows,
      };
    },
    [selectedHostName, selectedTriggerTextId]
  );

  const filtersReportData = useMemo(() => {
    const report = reviseReport(reports01[0]);
    return report;
  }, [reports01]);

  useEffect(() => {
    if (!filtersReportData.rowCount) return setAvailableTriggerTexts([]);

    const loadData = async () => {
      const triggerTextIds = uniqBy(
        filtersReportData.rows
          .map((x) => x[DIMENSION_ITEM_SOURCE_ID])
          .filter((id) => id !== DEFAULT_TRIGGER_TEXT_OWNER)
      );
      const results = await getTriggerTexts(triggerTextIds);
      setAvailableTriggerTexts(results);
    };
    loadData();
  }, [filtersReportData]);

  const triggerTextViewsReportData = useMemo(() => {
    return commonFilter(reviseReport(reports01[1]));
  }, [commonFilter, reports01]);

  const audiencesReportData = useMemo(() => {
    return reviseReport(reports02[0]);
  }, [reports02]);

  const usersReportData = useMemo(() => {
    return reviseReport(reports02[1]);
  }, [reports02]);

  const contentActiveViewsReportData = useMemo(() => {
    return reviseReport(reports02[2]);
  }, [reports02]);

  const contentClicksReportData = useMemo(() => {
    return reviseReport(reports02[3]);
  }, [reports02]);

  const roleClicksReportData = useMemo(() => {
    const report = reviseReport(reports02[4]);
    const contentClicks = reviseReport(reports02[3]);
    const otherElementClickedCount = sumBy(contentClicks.rows || [], x => x[METRIC_EVENT_COUNT]) - sumBy(report.rows || [], x => x[METRIC_EVENT_COUNT]) || 0;
    if (otherElementClickedCount && !report.rows.some(x => x[DIMENSION_ROLE] === 'Others')) {
      const otherRow = {};
      otherRow[DIMENSION_ROLE] = 'Others';
      otherRow[METRIC_EVENT_COUNT] = otherElementClickedCount;
      report.rows.push(otherRow);
      report.rowCount += 1;
    }
    return report;
  }, [reports02]);

  const availablePartners = useMemo(() => {
    if (!filtersReportData.rowCount) return [];

    return uniqBy(
      filtersReportData.rows.map((row) => ({
        [DIMENSION_HOSTNAME]: row[DIMENSION_HOSTNAME],
        [DIMENSION_HOSTNAME_FULL]: row[DIMENSION_HOSTNAME_FULL],
      })),
      DIMENSION_HOSTNAME
    );
  }, [filtersReportData]);

  const availableTriggerOwnerDomains = useMemo(() => {
    if (!filtersReportData.rowCount) return [];

    return uniqBy(
      filtersReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_ITEM_SOURCE_STREAM_ID] !== DEFAULT_TRIGGER_TEXT_OWNER
        )
        .map((row) => ({
          [DIMENSION_ITEM_SOURCE_STREAM_ID]:
            row[DIMENSION_ITEM_SOURCE_STREAM_ID],
        })),
      DIMENSION_ITEM_SOURCE_STREAM_ID
    );
  }, [filtersReportData]);

  const availableItems = useMemo(() => {
    if (isEmpty(filtersReportData)) return [];

    const filteredItems = filtersReportData.rows.filter(
      (item) =>
        !selectedHostName || selectedHostName === item[DIMENSION_HOSTNAME]
    );
    const triggerTextIds = uniqBy(
      filteredItems.map((row) => row[DIMENSION_ITEM_SOURCE_ID])
    );

    return triggerTextIds.map((triggerTextId) => {
      const matchedTriggerText = availableTriggerTexts.find(
        (item) => item.id === triggerTextId
      );
      return (
        matchedTriggerText || {
          id: triggerTextId,
          text: triggerTextId,
          isDeleted: true,
        }
      );
    });
  }, [filtersReportData, availableTriggerTexts, selectedHostName]);

  const triggerTextViewsTimeSeriesData = useMemo(() => {
    if (isEmpty(triggerTextViewsReportData)) return [];

    const rowsGroupedByDate = triggerTextViewsReportData.rows.reduce(
      (result, current) => {
        const date = current[DIMENSION_DATE];
        if (!result[date])
          result[date] = {
            [EVENT_VIEW_ITEM_DETAILS]: 0,
            [EVENT_VIEW_ITEM_IMPRESSION]: 0,
          };
        result[date].date = date;

        if (current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION)
          result[date][EVENT_VIEW_ITEM_IMPRESSION] = sum([
            result[date][EVENT_VIEW_ITEM_IMPRESSION],
            current[METRIC_EVENT_COUNT],
          ]);
        if (current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS)
          result[date][EVENT_VIEW_ITEM_DETAILS] = sum([
            result[date][EVENT_VIEW_ITEM_DETAILS],
            current[METRIC_EVENT_COUNT],
          ]);

        return result;
      },
      {}
    );

    // return orderBy(Object.values(rowsGroupedByDate), [DIMENSION_DATE]);
    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [EVENT_VIEW_ITEM_IMPRESSION, EVENT_VIEW_ITEM_DETAILS],
    });
  }, [triggerTextViewsReportData, dateStrings]);

  // Content Impressions

  const onHostChange = useCallback(
    (value, option) => {
      setSelectedHostName(value);
      setSelectedHostNameFull(option[DIMENSION_HOSTNAME_FULL]);
      setSelectedTriggerTextId(null);
      setParam(PARAM_TRIGGER_TEXT_HOST, value);
    },
    [setParam]
  );

  const handleTriggerTextChange = useCallback(
    (value, option) => {
      setSelectedTriggerTextId(value);
      setParam(PARAM_TRIGGER_TEXT_ID, value);
    },
    [setParam]
  );

  const handleTriggerOwnerDomainChange = useCallback(
    (value, option) => {
      setSelectedTriggerOwnerDomain(value);
      setParam(PARAM_TRIGGER_TEXT_OWNER, value);
    },
    [setParam]
  );

  const contextValue = useMemo(() => {
    return {
      triggerTextViewsReportData,
      audiencesReportData,
      usersReportData,
      triggerTextViewsTimeSeriesData,
      dateStrings,
      clicksEnabled: true,
      contentActiveViewsReportData,
      contentClicksReportData,
      filterTriggerText,
      roleClicksReportData,
    };
  }, [
    audiencesReportData,
    contentActiveViewsReportData,
    triggerTextViewsReportData,
    triggerTextViewsTimeSeriesData,
    dateStrings,
    usersReportData,
    contentClicksReportData,
    filterTriggerText,
    roleClicksReportData,
  ]);

  return (
    <TriggerTextsContextProvider value={contextValue}>
      <Spin spinning={isLoading}>
        <Row gutter={GRID_GUTTER} className="xl:justify-start">
          <Col xs={12} lg={8} className="mb-8">
            <Select
              value={selectedHostName}
              onChange={onHostChange}
              className="w-full"
              defaultValue={null}
            >
              <Select.Option value={null}>All partners</Select.Option>
              {availablePartners.map((item) => (
                <Select.Option
                  value={item[DIMENSION_HOSTNAME]}
                  key={item[DIMENSION_HOSTNAME]}
                  hostNameFull={item[DIMENSION_HOSTNAME_FULL]}
                >
                  <Space>
                    <Favicon url={item[DIMENSION_HOSTNAME_FULL]} />
                    {item[DIMENSION_HOSTNAME]}
                  </Space>
                </Select.Option>
              ))}
            </Select>
          </Col>

          {!filterTriggerText && (
            <Col xs={12} lg={8} className="mb-8">
              <Select
                value={selectedTriggerTextId}
                onChange={handleTriggerTextChange}
                className="w-full"
                defaultValue={null}
              >
                <Select.Option value={null}>All trigger texts</Select.Option>
                {availableItems.map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    <Space>
                      {item.text}
                      {item.isDeleted && <Tag color="red">Unavailable</Tag>}
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            </Col>
          )}

          {filterTriggerText && (
            <Col xs={12} lg={8} className="mb-8">
              <Select
                value={selectedTriggerOwnerDomain}
                onChange={handleTriggerOwnerDomainChange}
                className="w-full"
                defaultValue={null}
              >
                <Select.Option value={null}>Trigger text owner</Select.Option>
                {availableTriggerOwnerDomains.map((item) => (
                  <Select.Option
                    value={item[DIMENSION_ITEM_SOURCE_STREAM_ID]}
                    key={item[DIMENSION_ITEM_SOURCE_STREAM_ID]}
                  >
                    <Space>
                      <Favicon url={item[DIMENSION_ITEM_SOURCE_STREAM_ID]} />
                      {refineDomainName(item[DIMENSION_ITEM_SOURCE_STREAM_ID])}
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            </Col>
          )}
        </Row>

        <OverviewSection />
        <TriggerTextActiveViewsSection />
        <TriggerTextImpressionsByPartnersSection />
        <ContentActiveViewsByPartnersSection />
        {!HIDE_VISITORS && <UsersByPartnersSection />}
        <ContentClicksSection />
        <ContentClicksByPartnersSection />
        <DailyTrafficSection />
        {/* <ContentActiveViewsSection /> */}
      </Spin>
    </TriggerTextsContextProvider>
  );
};

TriggerTexts.propTypes = {};

export default TriggerTexts;
