// const FILTER_THIS_YEAR = 1;
const FILTER_THIS_MONTH = 2;
const FILTER_YESTERDAY = 3;
const FILTER_TODAY = 4;
const FILTER_PAST_7_DAYS = 5;
const FILTER_PAST_14_DAYS = 6;
const FILTER_PAST_28_DAYS = 7;
const FILTER_CUSTOM = 8;

export {
  // FILTER_THIS_YEAR,
  FILTER_THIS_MONTH,
  FILTER_YESTERDAY,
  FILTER_TODAY,
  FILTER_PAST_7_DAYS,
  FILTER_PAST_14_DAYS,
  FILTER_PAST_28_DAYS,
  FILTER_CUSTOM,
};
