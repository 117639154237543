import React, { useMemo } from "react";
import { Card, Col, Row, Typography } from "antd";
import { GRID_GUTTER, HIDE_VISITORS } from "constants/ui";
import { METRIC_EVENT_COUNT, METRIC_TOTAL_USERS } from "constants/metrics";
import { isEmpty, sum } from "lodash";
import { DIMENSION_ITEM_ID } from "constants/customDimensions";
import TableChart from "components/charts/TableChart";
import { formatNumber } from "services/numberService";
import { DIMENSION_DATE, DIMENSION_EVENT_NAME } from "constants/dimensions";
import {
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
} from "constants/customEvents";
import { reviseTimeSeriesData } from "services/gaService";
import moment from "moment";
import { DATE_FORMAT } from "constants/default";
import { useTriggerTextsContext } from "../../TriggerTextsContext";

const DailyTrafficSection = () => {
  const {
    dateStrings,
    triggerTextViewsReportData,
    usersReportData,
    clicksEnabled,
  } = useTriggerTextsContext();

  const usersTimeSeriesData = useMemo(() => {
    if (isEmpty(usersReportData)) return [];

    const rowsGroupedByDate = usersReportData.rows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_EVENT_COUNT] = sum([
        result[date][METRIC_EVENT_COUNT],
        current[METRIC_TOTAL_USERS],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(
      Object.values(rowsGroupedByDate),
      {
        dateKey: DIMENSION_DATE,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        dataKeys: [METRIC_EVENT_COUNT],
      },
      "desc"
    );
  }, [dateStrings, usersReportData]);

  const activeViewsTimeSeriesData = useMemo(() => {
    if (isEmpty(triggerTextViewsReportData)) return [];

    const filteredRows = triggerTextViewsReportData.rows.filter(
      (row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS
    );
    const rowsGroupedByDate = filteredRows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_EVENT_COUNT] = sum([
        result[date][METRIC_EVENT_COUNT],
        current[METRIC_EVENT_COUNT],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(
      Object.values(rowsGroupedByDate),
      {
        dateKey: DIMENSION_DATE,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        dataKeys: [METRIC_EVENT_COUNT],
      },
      "desc"
    );
  }, [dateStrings, triggerTextViewsReportData]);

  const clicksTimeSeriesData = useMemo(() => {
    if (isEmpty(triggerTextViewsReportData)) return [];

    const filteredRows = triggerTextViewsReportData.rows.filter(
      (row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED
    );
    const rowsGroupedByDate = filteredRows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_EVENT_COUNT] = sum([
        result[date][METRIC_EVENT_COUNT],
        current[METRIC_EVENT_COUNT],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(
      Object.values(rowsGroupedByDate),
      {
        dateKey: DIMENSION_DATE,
        startDate: dateStrings[0],
        endDate: dateStrings[1],
        dataKeys: [METRIC_EVENT_COUNT],
      },
      "desc"
    );
  }, [dateStrings, triggerTextViewsReportData]);

  const tableData = useMemo(() => {
    if (
      isEmpty(usersTimeSeriesData) &&
      isEmpty(clicksTimeSeriesData) &&
      isEmpty(activeViewsTimeSeriesData)
    )
      return [];

    const dataLength =
      usersTimeSeriesData.length ||
      clicksTimeSeriesData.length ||
      activeViewsTimeSeriesData.length;

    return Array(dataLength)
      .fill()
      .map((_, index) => {
        const userItem = usersTimeSeriesData[index] || {};
        const activeViewItem = activeViewsTimeSeriesData[index] || {};
        const clickItem = clicksTimeSeriesData[index] || {};
        return {
          ...userItem,
          users: userItem[METRIC_EVENT_COUNT] || 0,
          activeViews: activeViewItem[METRIC_EVENT_COUNT] || 0,
          clicks: clickItem[METRIC_EVENT_COUNT] || 0,
        };
      });
  }, [usersTimeSeriesData, clicksTimeSeriesData, activeViewsTimeSeriesData]);

  return (
    <>
      <Typography.Title level={5}>Daily Traffic</Typography.Title>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={24} className="mb-8">
          <Card size="small" className="">
            <TableChart
              rowKey={DIMENSION_ITEM_ID}
              dataSource={tableData}
              columns={[
                {
                  title: "Date",
                  dataIndex: DIMENSION_DATE,
                  key: DIMENSION_DATE,
                  render(value) {
                    return moment(value).format(DATE_FORMAT);
                  },
                },
                ...(!HIDE_VISITORS ?
                [{
                  title: "Visitors",
                  dataIndex: "users",
                  key: "users",
                  align: "right",
                  width: 200,
                  render: (value) => formatNumber(value),
                }]: []),
                {
                  title: "Active Views",
                  dataIndex: "activeViews",
                  key: "activeViews",
                  align: "right",
                  width: 200,
                  render: (value) => formatNumber(value),
                },
                // ...(clicksEnabled
                //   ? [
                //       {
                //         title: "Clicks",
                //         dataIndex: "clicks",
                //         key: "clicks",
                //         align: "right",
                //         width: 200,
                //         render: (value) => formatNumber(value),
                //       },
                //     ]
                //   : []),
              ]}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

DailyTrafficSection.propTypes = {};

export default DailyTrafficSection;
