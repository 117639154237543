import React, { useMemo } from "react";
import { Card, Col, Row, Typography } from "antd";
import { GRID_GUTTER } from "constants/ui";
import {
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { isEmpty, orderBy, sum } from "lodash";
import { DIMENSION_HOSTNAME } from "constants/dimensions";
import {
  DIMENSION_ITEM_BRAND,
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_NAME,
} from "constants/customDimensions";
import LineChart from "components/charts/LineChart";
import TableChart from "components/charts/TableChart";
import ContentLink from "components/ContentLink";
import ContentType from "components/ContentType";
import { formatNumber } from "services/numberService";

const syncNamesOptions = {
  idKey: DIMENSION_ITEM_ID,
  nameKey: DIMENSION_ITEM_NAME,
  typeKey: DIMENSION_ITEM_CATEGORY,
};

const ContentActiveViewsSection = ({
  contentActiveViewsReportData,
  contentViewsTimeSeriesData,
}) => {
  const contentActiveViewsTableData = useMemo(() => {
    if (isEmpty(contentActiveViewsReportData)) return [];

    const rowsGroupedById = contentActiveViewsReportData.rows.reduce(
      (result, current) => {
        const itemId = current[DIMENSION_ITEM_ID];
        if (!result[itemId]) result[itemId] = {};
        result[itemId][DIMENSION_ITEM_ID] = itemId;
        result[itemId][DIMENSION_HOSTNAME] = current[DIMENSION_HOSTNAME];
        result[itemId][DIMENSION_ITEM_NAME] = current[DIMENSION_ITEM_NAME];
        result[itemId][DIMENSION_ITEM_BRAND] = current[DIMENSION_ITEM_BRAND];
        result[itemId][DIMENSION_ITEM_CATEGORY] =
          current[DIMENSION_ITEM_CATEGORY];
        result[itemId][METRIC_EVENT_COUNT] = sum([
          result[itemId][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    return orderBy(
      Object.values(rowsGroupedById),
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [contentActiveViewsReportData]);

  return (
    <Row gutter={GRID_GUTTER}>
      <Col xs={24} lg={12} className="mb-8">
        <Typography.Title level={5}>Active View · Trends</Typography.Title>
        <Card size="small" className="">
          <LineChart
            xAxisDataKey="date"
            data={contentViewsTimeSeriesData}
            lines={[
              {
                name: "Active Views",
                dataKey: EVENT_VIEW_ITEM_DETAILS,
              },
            ]}
          />
        </Card>
      </Col>
      <Col xs={24} lg={12} className="mb-8">
        <Typography.Title level={5}>
          Active View · Top contents
        </Typography.Title>
        <Card size="small" className="">
          <TableChart
            rowKey={DIMENSION_ITEM_ID}
            dataSource={contentActiveViewsTableData}
            syncNames={syncNamesOptions}
            columns={[
              {
                title: "Content",
                dataIndex: DIMENSION_ITEM_ID,
                key: DIMENSION_ITEM_ID,
                ellipsis: true,
                render: (_, record) => (
                  <ContentLink
                    id={record[DIMENSION_ITEM_ID]}
                    name={record[DIMENSION_ITEM_NAME]}
                    tag={record[DIMENSION_ITEM_CATEGORY]}
                    ownerDomain={record[DIMENSION_ITEM_BRAND]}
                  />
                ),
              },
              {
                title: "Type",
                dataIndex: DIMENSION_ITEM_CATEGORY,
                key: DIMENSION_ITEM_CATEGORY,
                render: (value) => <ContentType tag={value} />,
                width: 80,
              },
              {
                title: "Active Views",
                dataIndex: METRIC_EVENT_COUNT,
                key: METRIC_EVENT_COUNT,
                width: 120,
                align: "right",
                render: (value) => formatNumber(value),
              },
            ]}
          />
        </Card>
      </Col>
    </Row>
  );
};

ContentActiveViewsSection.propTypes = {};

export default ContentActiveViewsSection;
