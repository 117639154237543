import { Card, Col } from "antd";
import PieChart from "components/charts/PieChart";
import ChartTitle from "components/ChartTitle";
import { DIMENSION_ITEM_CATEGORY } from "constants/customDimensions";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import React from "react";

const ViewsContentTypes = ({ data }) => {
  return (
    <Col xs={24} lg={12} className="mb-8">
      <ChartTitle>Views · Content types</ChartTitle>
      <Card size="small" className="lg:h-full">
        <div className="h-80">
          <PieChart
            data={data}
            dataKey={METRIC_EVENT_COUNT}
            nameKey={DIMENSION_ITEM_CATEGORY}
          />
        </div>
      </Card>
    </Col>
  );
};

export default ViewsContentTypes;
