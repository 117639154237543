import { isEmpty, isNil, omitBy } from "lodash";
import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import useQueryString from "./useQueryString";

const omitChecker = (value) => isNil(value) || isEmpty(value);

function useSetQueryString() {
  const query = useQueryString();
  const [_, setSearchParams] = useSearchParams();

  const set = useCallback(
    (key, value) => {
      const newParams = omitBy({ ...query, [key]: value }, omitChecker);
      setSearchParams(newParams);
    },
    [query, setSearchParams]
  );

  const setBatch = useCallback(
    (params) => {
      const newParams = omitBy({ ...query, ...params }, omitChecker);
      setSearchParams(newParams);
    },
    [query, setSearchParams]
  );

  const remove = useCallback(
    (key) => {
      const newParams = omitBy({ ...query, [key]: undefined }, omitChecker);
      setSearchParams(newParams);
    },
    [query, setSearchParams]
  );

  const reset = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  return [set, setBatch, remove, reset];
}

export default useSetQueryString;
