import { Card, Col } from "antd";
import TableChart from "components/charts/TableChart";
import ChartTitle from "components/ChartTitle";
import ContentLink from "components/ContentLink";
import ContentType from "components/ContentType";
import {
  DIMENSION_ITEM_BRAND,
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_ID_TEMP,
  DIMENSION_ITEM_NAME,
} from "constants/customDimensions";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import React from "react";
import { SYNC_NAMES_OPTIONS } from "services/overviewService";

const ViewDetails = ({ dataSource }) => {
  return (
    <Col xs={24} lg={12} className="mb-8">
      <ChartTitle level={5}>View details</ChartTitle>
      <Card size="small" className="lg:h-full">
        <TableChart
          rowKey={DIMENSION_ITEM_ID_TEMP}
          dataSource={dataSource}
          syncNames={SYNC_NAMES_OPTIONS}
          columns={[
            {
              title: "Content",
              dataIndex: DIMENSION_ITEM_ID,
              key: DIMENSION_ITEM_ID,
              ellipsis: true,
              render: (_, record) => (
                <ContentLink
                  id={record[DIMENSION_ITEM_ID]}
                  name={record[DIMENSION_ITEM_NAME]}
                  tag={record[DIMENSION_ITEM_CATEGORY]}
                  ownerDomain={record[DIMENSION_ITEM_BRAND]}
                />
              ),
            },
            {
              title: "Type",
              dataIndex: DIMENSION_ITEM_CATEGORY,
              key: DIMENSION_ITEM_CATEGORY,
              render: (value) => <ContentType tag={value} />,
              width: 80,
            },
            {
              title: "Views",
              dataIndex: METRIC_EVENT_COUNT,
              key: METRIC_EVENT_COUNT,
              width: 100,
              align: "right",
            },
          ]}
        />
      </Card>
    </Col>
  );
};

export default ViewDetails;
