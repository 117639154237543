import { message } from "antd";

message.config({
  duration: 5,
  maxCount: 1,
});

export const alertUnknownError = () => {
  message.error("Something went wrong");
};
