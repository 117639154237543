export const partnerSourceNames = [
  "ProConnectWeekly",
  "ResellerWeeklyund",
  "PremiumReseller",
  "PremiumProConnect",
];
export const clientEmailSourcesMap = {
  PremiumEmail: {
    order: 1,
    name: "DCI Premium Email",
    key: "premiumemail",
  },
  DCIDailyWeeklyHighlight: {
    order: 2,
    name: "DCI Highlights - Daily",
    key: "dcidailyweeklyhighlight",
  },
  DCIDailyHighlight: {
    order: 2,
    name: "DCI Highlights - Daily",
    key: "dcidailyhighlight",
  },
  DCIWeeklyHighlight: {
    order: 3,
    name: "DCI Highlights - Weekly",
    key: "dciweeklyhighlight",
  },
  ProConnectWeekly: {
    order: 4,
    name: "ProConnect - Weekly",
    key: "proconnectweekly",
  },
  ResellerWeeklyund: {
    order: 5,
    name: "Reseller Direct - Weekly",
    key: "resellerweeklyund",
  },
  PremiumReseller: {
    order: 6,
    name: "Premium email Reseller Direct",
    key: "premiumreseller",
  },
  PremiumProConnect: {
    order: 7,
    name: "Premium email ProConnect",
    key: "premiumproconnect",
  },
  Invitation: {
    order: 8,
    name: "Invitation",
    key: "invitation",
  },
  default: {
    order: 1000,
  },
};
