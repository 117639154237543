import React, { useEffect, useMemo, useState } from "react";
import { isEmpty, sum, orderBy, get } from "lodash";
import { useContentViewsContext } from "../../ContentViewsContext";
import { Card, Table, Typography, Space } from "antd";
import TableChart from "components/charts/TableChart";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { formatNumber } from "services/numberService";
import Text from "antd/lib/typography/Text";
import {
  DIMENSION_ITEM_URL,
  DIMENSION_ITEM_URL1,
  DIMENSION_ITEM_URL2,
  DIMENSION_ITEM_URL3,
  DIMENSION_ITEM_URL4,
} from "constants/customDimensions";
import { decompressLinks } from "services/gaService";
import { decompress } from "services/textService";
import { EMPTY_DIMENSION_VALUES } from "constants/dimensions";
import { v4 as uuid } from "uuid";

function LinkClicks() {
  const {
    deepLinkClicksReport,
    contentData,
    useSendInBlueReport,
    sendInBlueReport,
  } = useContentViewsContext();
  const [tableData, setTableData] = useState(() => {
    if (!sendInBlueReport) return [];

    const linksStats = get(sendInBlueReport, "statistics.linksStats", {});
    const items = Object.keys(linksStats).map((key) => ({
      decompressedUrl: key,
      [METRIC_EVENT_COUNT]: linksStats[key] || 0,
    }));

    return orderBy(items, [METRIC_EVENT_COUNT], ["desc"]);
  });

  useEffect(() => {
    async function loadData() {
      const items = orderBy(
        deepLinkClicksReport.rows,
        [METRIC_EVENT_COUNT],
        ["desc"]
      );
      const failedCompressUrls = [];
      items.forEach((item) => {
        const compressedUrl = [
          item[DIMENSION_ITEM_URL],
          item[DIMENSION_ITEM_URL1],
          item[DIMENSION_ITEM_URL2],
          item[DIMENSION_ITEM_URL3],
          item[DIMENSION_ITEM_URL4],
        ]
          .filter((url) => url && !EMPTY_DIMENSION_VALUES.includes(url))
          .join("");
        item.compressedUrl = compressedUrl;

        const decompressedUrl = decompress(compressedUrl);
        if (compressedUrl && !decompressedUrl) {
          failedCompressUrls.push(compressedUrl);
        } else {
          item.decompressedUrl = decompressedUrl;
        }
      });

      if (!isEmpty(failedCompressUrls)) {
        try {
          const requestData = {
            id: contentData?.id,
            compressedLinks: failedCompressUrls,
          };
          const results = await decompressLinks(requestData);
          if (results) {
            results.forEach((result) => {
              items.forEach((item) => {
                if (item.compressedUrl === result.compressedUrl) {
                  item.decompressedUrl = result.rawUrl;
                }
              });
            });
          }

          const othersCount = sum(
            items
              .filter((item) => !item.decompressedUrl)
              .map((item) => item[METRIC_EVENT_COUNT])
          );
          const itemsWithoutOthers = items.filter(
            (item) => item.decompressedUrl
          );
          if (othersCount)
            itemsWithoutOthers.push({
              [METRIC_EVENT_COUNT]: othersCount,
            });
          setTableData(
            itemsWithoutOthers.map((item) => ({ ...item, id: uuid() }))
          );
        } catch (error) {
          setTableData(items.map((item) => ({ ...item, id: uuid() })));
          console.error(error);
        }
      } else {
        setTableData(items);
      }
    }

    if (!isEmpty(deepLinkClicksReport) && !useSendInBlueReport) loadData();
  }, [contentData?.id, deepLinkClicksReport, useSendInBlueReport]);

  const totalClicks = useMemo(() => {
    if (useSendInBlueReport) {
      const linksStats = get(sendInBlueReport, "statistics.linksStats", {});
      return sum(Object.keys(linksStats).map((key) => linksStats[key] || 0));
    }

    if (isEmpty(tableData)) return 0;

    return sum(tableData.map((item) => item[METRIC_EVENT_COUNT]));
  }, [sendInBlueReport, tableData, useSendInBlueReport]);

  return (
    <section className="mt-8">
      <Space className="mb-2">
        <span className="text-xl font-semibold mb-0">Link Clicks</span>
      </Space>
      <Card size="small">
        <TableChart
          pageSize={30}
          rowKey="id"
          dataSource={tableData}
          columns={[
            {
              title: "Link",
              dataIndex: DIMENSION_ITEM_URL,
              key: DIMENSION_ITEM_URL,
              ellipsis: true,
              render: (value, record) => {
                const url = record.decompressedUrl;
                return !url ? (
                  <div>Others</div>
                ) : (
                  <a
                    href={record.decompressedUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="break-all text-sky-500"
                  >
                    {record.decompressedUrl}
                  </a>
                );
              },
            },
            {
              title: "Clicks",
              dataIndex: METRIC_EVENT_COUNT,
              key: METRIC_EVENT_COUNT,
              width: 100,
              align: "right",
              render: (value) => formatNumber(value),
            },
          ]}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>
                <Text type="secondary" className="font-semibold">
                  Total
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell className="text-right">
                <Text className="font-semibold">{totalClicks}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </Card>
    </section>
  );
}

LinkClicks.propTypes = {};

export default LinkClicks;
