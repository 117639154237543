import React, { useMemo } from "react";
import { Badge, Card, Col, Row, Space, Typography } from "antd";
import { GRID_GUTTER } from "constants/ui";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { isEmpty, orderBy, sum, sumBy } from "lodash";
import {
  DIMENSION_ITEM_BRAND,
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_NAME,
  DIMENSION_ROLE,
} from "constants/customDimensions";
import TableChart from "components/charts/TableChart";
import ContentLink from "components/ContentLink";
import ContentType from "components/ContentType";
import { formatNumber } from "services/numberService";
import { useTriggerTextsContext } from "../../TriggerTextsContext";
import { getRoleByKey } from "services/gaService";

const syncNamesOptions = {
  idKey: DIMENSION_ITEM_ID,
  nameKey: DIMENSION_ITEM_NAME,
  typeKey: DIMENSION_ITEM_CATEGORY,
};

const ContentClicksSection = () => {
  const { contentClicksReportData, roleClicksReportData } =
    useTriggerTextsContext();

  const contentClicksTableData = useMemo(() => {
    if (isEmpty(contentClicksReportData)) return [];

    const rowsGroupedById = contentClicksReportData.rows.reduce(
      (result, current) => {
        const itemId = current[DIMENSION_ITEM_ID];
        if (!result[itemId]) result[itemId] = {};
        result[itemId][DIMENSION_ITEM_ID] = itemId;
        result[itemId][DIMENSION_ITEM_NAME] = current[DIMENSION_ITEM_NAME];
        result[itemId][DIMENSION_ITEM_BRAND] = current[DIMENSION_ITEM_BRAND];
        result[itemId][DIMENSION_ITEM_CATEGORY] =
          current[DIMENSION_ITEM_CATEGORY];
        result[itemId][METRIC_EVENT_COUNT] = sum([
          result[itemId][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    return orderBy(
      Object.values(rowsGroupedById),
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [contentClicksReportData]);

  const rolesTableData = useMemo(() => {
    if (isEmpty(roleClicksReportData)) return [];

    const rowsGroupedByRole = roleClicksReportData.rows.reduce(
      (result, current) => {
        const rawRole = current[DIMENSION_ROLE];
        const roleData = getRoleByKey(rawRole);
        const roleKey = roleData.key;
        if (!result[roleKey]) result[roleKey] = {};
        result[roleKey][DIMENSION_ROLE] = roleKey;
        result[roleKey][DIMENSION_ITEM_NAME] = roleData.name;
        result[roleKey][METRIC_EVENT_COUNT] = sum([
          result[roleKey][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    return orderBy(
      Object.values(rowsGroupedByRole),
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [roleClicksReportData]);

  return (
    <Row gutter={GRID_GUTTER}>
      <Col xs={24} lg={12} className="mb-8">
        <Typography.Title level={5}>
          <Space>
            Clicks · Clicked elements
            {/* <Badge
              count={sumBy(rolesTableData, (item) => item[METRIC_EVENT_COUNT])}
            /> */}
          </Space>
        </Typography.Title>
        <Card size="small" className="">
          <TableChart
            rowKey={DIMENSION_ROLE}
            dataSource={rolesTableData}
            columns={[
              {
                title: "Element",
                dataIndex: DIMENSION_ITEM_NAME,
                key: DIMENSION_ITEM_NAME,
                render(value) {
                  return value;
                },
              },
              {
                title: "Clicks",
                dataIndex: METRIC_EVENT_COUNT,
                key: METRIC_EVENT_COUNT,
                align: "right",
                render: (value) => formatNumber(value),
              },
            ]}
          />
        </Card>
      </Col>
      <Col xs={24} lg={12} className="mb-8">
        <Space>
          <Typography.Title level={5}>
            <Space>
              Clicks · Top contents
              {/* <Badge
                count={sumBy(
                  contentClicksTableData,
                  (item) => item[METRIC_EVENT_COUNT]
                )}
              /> */}
            </Space>
          </Typography.Title>
        </Space>
        <Card size="small" className="">
          <TableChart
            rowKey={DIMENSION_ITEM_ID}
            dataSource={contentClicksTableData}
            syncNames={syncNamesOptions}
            columns={[
              {
                title: "Content",
                dataIndex: DIMENSION_ITEM_ID,
                key: DIMENSION_ITEM_ID,
                ellipsis: true,
                render: (_, record) => (
                  <ContentLink
                    id={record[DIMENSION_ITEM_ID]}
                    name={record[DIMENSION_ITEM_NAME]}
                    tag={record[DIMENSION_ITEM_CATEGORY]}
                    ownerDomain={record[DIMENSION_ITEM_BRAND]}
                  />
                ),
              },
              {
                title: "Type",
                dataIndex: DIMENSION_ITEM_CATEGORY,
                key: DIMENSION_ITEM_CATEGORY,
                render: (value) => <ContentType tag={value} />,
                width: 80,
              },
              {
                title: "Clicks",
                dataIndex: METRIC_EVENT_COUNT,
                key: METRIC_EVENT_COUNT,
                width: 120,
                align: "right",
                render: (value) => formatNumber(value),
              },
            ]}
          />
        </Card>
      </Col>
    </Row>
  );
};

ContentClicksSection.propTypes = {};

export default ContentClicksSection;
