import PropTypes from "prop-types";
import React, { memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const typeMap = {
  light: "fal",
  brand: "fab",
  solid: "fa",
  duotone: "fad",
};

const Icon = memo(({ type, fixedWidth, name, className, ...props }) => {
  return (
    <FontAwesomeIcon
      icon={[typeMap[type], name]}
      className={className}
      fixedWidth={fixedWidth}
      {...props}
    />
  );
});

Icon.propTypes = {
  className: PropTypes.string,
  fixedWidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
  fixedWidth: PropTypes.bool,
  type: PropTypes.oneOf(["light", "brand", "solid", "duotone"]),
};

Icon.defaultProps = {
  className: "",
  type: "light",
  fixedWidth: false,
};

export default Icon;
