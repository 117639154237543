export const DIMENSION_INFOBOARD_BRAND = "customEvent:infoboard_brand";
export const DIMENSION_ITEM_BRAND = "customEvent:item_brand";
export const DIMENSION_ITEM_CATEGORY = "customEvent:item_category";
export const DIMENSION_ITEM_CATEGORY2 = "customEvent:item_category2";
export const DIMENSION_ITEM_ID = "customEvent:item_id";
export const DIMENSION_ITEM_ID_TEMP = "$id";
export const DIMENSION_ITEM_URL = "customEvent:item_url";
export const DIMENSION_ITEM_URL1 = "customEvent:item_url1";
export const DIMENSION_ITEM_URL2 = "customEvent:item_url2";
export const DIMENSION_ITEM_URL3 = "customEvent:item_url3";
export const DIMENSION_ITEM_URL4 = "customEvent:item_url4";
export const DIMENSION_ITEM_NAME = "customEvent:item_name";
export const DIMENSION_ITEM_SOURCE = "customEvent:item_source";
export const DIMENSION_ITEM_SOURCE_ID = "customEvent:item_source_id";
export const DIMENSION_ITEM_SOURCE_NAME = "customEvent:item_source_name";
export const DIMENSION_CIRCULATION_ID = "customEvent:circulation_id";
export const DIMENSION_ITEM_SOURCE_STREAM_CATEGORY =
  "customEvent:item_source_stream_category";
export const DIMENSION_ITEM_SOURCE_STREAM_ID =
  "customEvent:item_source_stream_id";
export const DIMENSION_ITEM_SOURCE_STREAM_NAME =
  "customEvent:item_source_stream_name";
export const DIMENSION_SEARCH_TERM = "customEvent:search_term";
export const DIMENSION_ROLE = "customEvent:role";
