import { memo } from "react";
import PropTypes from "prop-types";
import { Tag } from "antd";
import { getContentTypeName } from "services/gaService";
import {
  TAG_BOOKMARK,
  TAG_DOMAIN,
  TAG_FACEBOOK,
  TAG_FEED,
  TAG_HIGHLIGHT,
  TAG_LIST,
  TAG_LISTSET,
  TAG_TWITTER,
} from "constants/contentTags";

const contentTypeColorsMap = {
  [TAG_BOOKMARK]: "blue",
  [TAG_DOMAIN]: "purple",
  [TAG_HIGHLIGHT]: "gold",
  [TAG_LIST]: "cyan",
  [TAG_LISTSET]: "orange",
  [TAG_FACEBOOK]: "blue",
  [TAG_TWITTER]: "blue",
  [TAG_FEED]: "gray",
};

const ContentType = memo(({ tag }) => {
  const contentTypeName = getContentTypeName(tag);
  const contentTypeColor = contentTypeColorsMap[tag];

  if (contentTypeName)
    return <Tag color={contentTypeColor}>{contentTypeName}</Tag>;

  return tag;
});

ContentType.propTypes = {
  className: PropTypes.any,
  isTag: PropTypes.any,
  size: PropTypes.number,
  tag: PropTypes.any,
};

ContentType.defaultProps = {
  className: null,
  size: 16,
  isTag: false,
};

export default ContentType;
