import { Row } from "antd";
import { GRID_GUTTER } from "constants/ui";
import React from "react";
import ViewDetails from "../ViewDetails";
import ViewsContentTypes from "../ViewsContentTypes";
import TopClickContents from "../TopClickContents";
import SaveContent from "../SaveContents";

const ViewsContent = ({
  combinedContentViewsTableData,
  contentViewsByTypesPieChartData,
  contentClicksTableData,
  plugSaveTrendTimeSeriesData,
  hasPlugiloSource,
}) => {
  return (
    <>
      <Row gutter={GRID_GUTTER}>
        <ViewDetails dataSource={combinedContentViewsTableData} />
        <ViewsContentTypes data={contentViewsByTypesPieChartData} />
      </Row>

      <Row gutter={GRID_GUTTER} className="my-8">
        <TopClickContents
          dataSource={contentClicksTableData}
          hasPlugiloSource={hasPlugiloSource}
        />
        <SaveContent
          data={plugSaveTrendTimeSeriesData}
          hasPlugiloSource={hasPlugiloSource}
        />
      </Row>
    </>
  );
};

export default ViewsContent;
