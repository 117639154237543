export const GRID_GUTTER = 32;
export const GRID_GUTTER_SM = 16;
export const LABEL_INACTIVE = "-";

export const TOOLTIP_CARD = `The "plugilo Card" is the new, compressed form of advertising. All kinds of content can be stored, e.g. email newsletters, landing pages, videos and much more. The plugilo card is distributed via the plugilo portal, via plugilo widgets and via the plugilo newsletter (if booked).`;
export const TOOLTIP_PUBLICATION = `The "Publications" shows the entire circulation of the distribution. The sum of all publications in the plugilo portal, via plugilo widgets and via the plugilo newsletter is shown here (if booked).
  IMPORTANT NOTE about our unique service:
  In contrast to classic email campaigns, we distribute the promotions simultaneously via multiple channels, as standalone emails, in bundled emails as well as in the plugilo portal and, if commissioned, also via widgets on target group websites (all combined under "Publications"). This allows you to achieve the highest possible distribution with almost 100% market coverage and maximum results. The ratios, such as the click rates, are therefore somewhat lower compared to "email-only mailings" - but the results far surpass pure email campaigns.`;
export const TOOLTIP_ACTIVE_VIEWS =
  'The "Active Views" show all active sightings of the advertisement, in the plugilo portal, via plugilo widgets via mouse-over as well as the opening rate of standalone emails and the actually measured delivery of the plugilo card in the visible area of ​​the plugilo newsletter (if booked) .';
export const TOOLTIP_CLICKS =
  'The "Clicks" show all clicks made on the plugilo card to open the entire content of the advertisement.';
export const TOOLTIP_DEEP_CLICKS =
  'The "Click outs" show all clicks that came from the content of the advertisement to the customer (click outs). These Click outs can only be measured for emails or websites that are stored by plugilo.';
export const TOOLTIP_OPEN_RATE = `An open in email marketing is the display of mailing contents on the recipient’s device. An important key figure in this connection is the open rate.
The percentage ratio between the number of opens and the number of delivered emails for a mailing dispatch is referred to, in email marketing, as the open rate.
The following formula is used to calculate the open rate:
Open rate [%] = Total opens / (Total number of sent emails – bounces) * 100`;
export const TOOLTIP_CLICK_RATE = `The percentage ratio between unique clicks and the number of delivered emails is referred to, in email marketing, as the click rate (or click-through rate).
A high click rate is an indicator of highly relevant contents for the recipients, as well as appealing and explicit call-to-action elements.
Click rate [%] = clicks / (Sent emails – bounces) * 100`;
export const TOOLTIP_CLICK_OPEN_RATE = `The click-to-open rate (CTOR) is the ratio of clicks to opens as a percentage.
A high click-to-open rate is an indicator of the relevance of newsletter contents, the graphic design and the placement of call-to-action elements (CTAs).
Click-to-open rate [%] = Total clicks / Total opens * 100`;

export const UI_CARD_WIDTH_DEFAULT = 302;
export const UI_CARD_HEIGHT_DEFAULT = 480;

export const HIDE_VISITORS = true;
