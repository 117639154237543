import PropTypes from "prop-types";
import React, { useMemo } from "react";
import {
  ResponsiveContainer,
  PieChart as RechartsPieChart,
  Pie,
  Tooltip,
  Legend,
} from "recharts";
import { getDimensionColorByIndex } from "utils/colorUtils";
import { isEmpty } from "lodash";
import { formatNumber } from "services/numberService";
import { Empty } from "antd";

const PieChart = ({ data, nameKey, dataKey, colors, showLegends }) => {
  const computedData = useMemo(() => {
    if (isEmpty(data)) return [];

    return data.map((item, index) => ({
      ...item,
      fill: getDimensionColorByIndex(index, colors),
    }));
  }, [data]);

  if (isEmpty(data)) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <ResponsiveContainer>
      <RechartsPieChart>
        <Pie
          data={computedData}
          nameKey={nameKey}
          dataKey={dataKey}
          label
          innerRadius={50}
        />
        <Tooltip formatter={(value) => formatNumber(value)} />
        {showLegends && (
          <Legend
            iconType="circle"
            iconSize={10}
            layout="vertical"
            align="right"
            verticalAlign="middle"
          />
        )}
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};

PieChart.propTypes = {
  data: PropTypes.array,
  dataKey: PropTypes.string,
  nameKey: PropTypes.string,
  showLegend: PropTypes.bool,
};

PieChart.defaultProps = {
  showLegend: true,
};

export default PieChart;
