import React, { useEffect, useMemo } from "react";
import { isEmpty, sum, toLower, get } from "lodash";
import { useContentViewsContext } from "../../ContentViewsContext";
import { GRID_GUTTER, LABEL_INACTIVE, TOOLTIP_DEEP_CLICKS } from "constants/ui";
import { Card, Col, Row, Space, Typography, Tooltip } from "antd";
import Icon from "components/Icon";
import TableChart from "components/charts/TableChart";
import {
  DIMENSION_ITEM_CATEGORY2,
  DIMENSION_ITEM_SOURCE,
  DIMENSION_ITEM_SOURCE_NAME,
  DIMENSION_ITEM_SOURCE_STREAM_ID,
} from "constants/customDimensions";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { DIMENSION_DATE, DIMENSION_EVENT_NAME } from "constants/dimensions";
import {
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_LINK_CLICKED,
} from "constants/customEvents";
import {
  SOURCE_EMAIL,
  SOURCE_PLUGILO,
  SOURCE_WIDGET,
} from "constants/contentSources";
import { indigo300, purple300, sky300 } from "constants/colors";
import PieChart from "components/charts/PieChart";
import { formatNumber } from "services/numberService";
import Text from "antd/lib/typography/Text";
import {
  clientEmailSourcesMap,
  partnerSourceNames,
} from "../../emailSourcesMap";
import { reviseTimeSeriesData } from "services/gaService";
import cloneDeep from "lodash/cloneDeep";
import LineChart from "components/charts/LineChart";
import { clientDeepClickSourcesMap } from "../../deepClickSourcesMap";
import TranslatedText from "components/TranslatedText";

const FALLBACK_CATEGORY_2 = "dciinformer";
function DeepClicks({ isVisible }) {
  const {
    summaryReport,
    dateStrings,
    deepClicksReport,
    emailSourcesMap,
    useSendInBlueReport,
    sendInBlueReport,
    deepClickSourcesMap,
    setEmailEventCount,
    setTimeSeriesData,
  } = useContentViewsContext();
  const availablePublicationIds = Object.keys(emailSourcesMap);

  const emailSources = useMemo(() => {
    if (isEmpty(deepClicksReport)) return [];
    const resultMap = cloneDeep(emailSourcesMap);

    deepClicksReport.rows.filter(
      (row) =>
        row[DIMENSION_ITEM_SOURCE] === SOURCE_EMAIL
    ).forEach((current) => {
      const cId = current[DIMENSION_ITEM_SOURCE_STREAM_ID];
      const category2 = current[DIMENSION_ITEM_CATEGORY2];
      const partnerSourceName = partnerSourceNames.includes(
        current[DIMENSION_ITEM_SOURCE_NAME]
      )
        ? current[DIMENSION_ITEM_SOURCE_NAME]
        : null;
      let itemCategory =
        toLower(partnerSourceName || category2) || FALLBACK_CATEGORY_2; // Fallback as others
      itemCategory = availablePublicationIds.includes(itemCategory)
        ? itemCategory
        : FALLBACK_CATEGORY_2;

      let matchEmailSource = Object.values(resultMap).find((item) => {
        return item.key === cId || item.names.includes(cId);
      });

      if (!matchEmailSource) {
        matchEmailSource = Object.values(resultMap).find((item) => {
          return item.names.includes(itemCategory);
        });
      }

      if (matchEmailSource) {
        matchEmailSource[METRIC_EVENT_COUNT] = sum([
          matchEmailSource[METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        resultMap[matchEmailSource.key] = matchEmailSource;
      }

    }, {});

    const refinedSources = Object.values(resultMap).map((item) => {
      const eventCount = item[METRIC_EVENT_COUNT] || 0;
      const threshold = deepClickSourcesMap[item.key]?.triggerThreshold || 0;
      const refinedEventCount = eventCount >= threshold ? eventCount : 0;
      return {
        ...item,
        [METRIC_EVENT_COUNT]: refinedEventCount,
      };
    });

    if (useSendInBlueReport) {
      const resultMap = cloneDeep(emailSourcesMap);
      const premiumSource = resultMap[clientEmailSourcesMap.PremiumEmail.key];
      resultMap[clientEmailSourcesMap.PremiumEmail.key] = {
        ...premiumSource,
        [METRIC_EVENT_COUNT]: sum(
          refinedSources.map((item) => item[METRIC_EVENT_COUNT])
        ),
      };
      return Object.values(resultMap);
    }

    return refinedSources;
  }, [
    useSendInBlueReport,
    deepClicksReport,
    emailSourcesMap,
    deepClickSourcesMap,
  ]);

  const plugiloCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_LINK_CLICKED &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGILO
    );
    const count = sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
    return count >=
      (deepClickSourcesMap[clientDeepClickSourcesMap.plugiloPortal.key]
        ?.triggerThreshold || 0)
      ? count
      : 0;
  }, [deepClickSourcesMap, summaryReport]);

  const widgetCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_LINK_CLICKED &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_WIDGET
    );
    const count = sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
    return count >=
      (deepClickSourcesMap[clientDeepClickSourcesMap.plugiloWidget.key]
        ?.triggerThreshold || 0)
      ? count
      : 0;
  }, [deepClickSourcesMap, summaryReport]);

  const emailCount = useMemo(() => {
    if (useSendInBlueReport) {
      const linksStats = get(sendInBlueReport, "statistics.linksStats", {});
      return sum(Object.keys(linksStats).map((key) => linksStats[key] || 0));
    }

    if (isEmpty(deepClicksReport)) return 0;
    return sum(emailSources.map((item) => item[METRIC_EVENT_COUNT]));
  }, [deepClicksReport, sendInBlueReport, useSendInBlueReport, emailSources]);

  const clickCount = useMemo(() => {
    const sum = plugiloCount + widgetCount + emailCount;
    return sum;
  }, [emailCount, plugiloCount, widgetCount]);

  const displayEmailSources = useMemo(() => {
    const sourceMap = cloneDeep(emailSources).reduce((prev, current) => {
      if (prev[current.displayName]) {
        prev[current.displayName][METRIC_EVENT_COUNT] =
          (prev[current.displayName][METRIC_EVENT_COUNT] || 0) +
          (current[METRIC_EVENT_COUNT] || 0);
      } else prev[current.displayName] = current;
      return prev;
    }, {});

    return Object.values(sourceMap);
  }, [emailSources]);

  const pieChartData = useMemo(() => {
    const emailPercent = clickCount ? (emailCount / clickCount) * 100 : null;

    return [
      // {
      //   name: "plugilo",
      //   value: plugiloCount,
      //   color: sky300,
      //   percent: plugiloPercent,
      //   soon: true,
      // },
      // {
      //   name: "Widgets",
      //   value: widgetCount,
      //   color: indigo300,
      //   percent: widgetPercent,
      //   soon: true,
      // },
      {
        name: "Emails",
        value: emailCount,
        color: sky300,
        percent: emailPercent,
      },
    ];
  }, [clickCount, emailCount]);

  // TODO:
  const trendTimeSeriesData = useMemo(() => {
    if (isEmpty(deepClicksReport) || !clickCount) return [];

    const dataKeysMap = {};
    const rowsGroupedByDate = deepClicksReport.rows
      .filter((row) =>
        [EVENT_VIEW_ITEM_LINK_CLICKED, EVENT_VIEW_ITEM_CLICKED].includes(
          row[DIMENSION_EVENT_NAME]
        )
      )
      .reduce((result, current) => {
        const date = current[DIMENSION_DATE];
        const source = current[DIMENSION_ITEM_SOURCE];
        if (!result[date]) result[date] = {};

        result[date][DIMENSION_DATE] = date;
        let canCount = false;
        switch (source) {
          case SOURCE_PLUGILO:
          case SOURCE_WIDGET:
            canCount = true;
            break;
          case SOURCE_EMAIL:
            // Threshold number
            const cId = current[DIMENSION_ITEM_SOURCE_STREAM_ID];
            let category2 = toLower(
              current[DIMENSION_ITEM_CATEGORY2] || FALLBACK_CATEGORY_2
            );
            category2 = availablePublicationIds.includes(category2)
              ? category2
              : FALLBACK_CATEGORY_2;
            const partnerSourceName = partnerSourceNames.includes(
              current[DIMENSION_ITEM_SOURCE_NAME]
            )
              ? current[DIMENSION_ITEM_SOURCE_NAME]
              : null;
            const itemCategory = toLower(partnerSourceName || category2);

            let emailSource = emailSources.find((item) => item.key === cId);

            if (!emailSource) {
              emailSource = emailSources.find((item) => item.key === itemCategory);
            }

            const emailSourceHasEvent = emailSource
              ? emailSource[METRIC_EVENT_COUNT]
              : 0;
            if (emailSourceHasEvent) {
              canCount = true;
            }
            break;
          default:
            console.log("hello");
        }

        if (canCount) {
          result[date][source] = sum([
            result[date][source],
            current[METRIC_EVENT_COUNT],
          ]);
          result[date][METRIC_EVENT_COUNT] = sum([
            result[date][METRIC_EVENT_COUNT],
            current[METRIC_EVENT_COUNT],
          ]);
        }
        dataKeysMap[source] = source;
        return result;
      }, {});

    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [...Object.keys(dataKeysMap), METRIC_EVENT_COUNT],
    });
  }, [clickCount, dateStrings, deepClicksReport, emailSources]);

  useEffect(() => {
    emailSources.forEach((source) => {
      setEmailEventCount(source.key, "deepClicks", source[METRIC_EVENT_COUNT]);
    });
  }, [emailSources]);

  useEffect(() => {
    setTimeSeriesData("deepClicks", trendTimeSeriesData);
  }, [trendTimeSeriesData]);

  if (!clickCount || !isVisible) return null;
  return (
    <section className="mt-8">
      <Tooltip title={<span className="text-xxs">{TOOLTIP_DEEP_CLICKS}</span>}>
        <Space className="mb-2">
          <span className="text-xl font-semibold mb-0">
            <TranslatedText id="Plugilo.Analytics.ClickOuts" />
          </span>
          <Icon name="info-circle" />
        </Space>
      </Tooltip>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12}>
          <Space
            direction="vertical"
            className="w-full lg:h-full flex flex-col"
            size={GRID_GUTTER}
          >
            <Card size="small">
              <TableChart
                rowKey={DIMENSION_ITEM_SOURCE}
                dataSource={[
                  {
                    [DIMENSION_ITEM_SOURCE]: "plugilo Portal",
                    [METRIC_EVENT_COUNT]: "Coming Soon",
                  },
                  {
                    [DIMENSION_ITEM_SOURCE]: "plugilo Widgets",
                    [METRIC_EVENT_COUNT]: "Coming Soon",
                  },
                ]}
                columns={[
                  {
                    title: "plugilo Source",
                    dataIndex: DIMENSION_ITEM_SOURCE,
                    key: DIMENSION_ITEM_SOURCE,
                    ellipsis: true,
                  },
                  {
                    title: "Clicks",
                    dataIndex: METRIC_EVENT_COUNT,
                    key: METRIC_EVENT_COUNT,
                    width: 300,
                    align: "right",
                    render: (value) => {
                      return <span className="text-gray-400">{value}</span>;
                    },
                  },
                ]}
              />
            </Card>

            {!isEmpty(displayEmailSources) && (
              <Card size="small">
                <TableChart
                  rowKey="key"
                  dataSource={displayEmailSources}
                  columns={[
                    {
                      title: "Newsletter sources",
                      dataIndex: "displayName",
                      key: "displayName",
                      ellipsis: true,
                    },
                    {
                      title: "Clicks",
                      dataIndex: METRIC_EVENT_COUNT,
                      key: METRIC_EVENT_COUNT,
                      width: 100,
                      align: "right",
                      render: (value) => {
                        return value ? (
                          formatNumber(value)
                        ) : (
                          <span className="text-gray-400">Coming soon</span>
                        );
                      },
                    },
                  ]}
                />
              </Card>
            )}
          </Space>
        </Col>

        <Col xs={24} lg={12}>
          <Card size="small" className="text-center lg:h-full mt-8 lg:mt-0">
            <div className="h-80">
              <PieChart
                data={pieChartData}
                dataKey="value"
                nameKey="name"
                colors={[sky300, indigo300, purple300]}
                showLegends={false}
              />
            </div>
            <Space size={40}>
              {pieChartData.map((item) => (
                <Space
                  direction="vertical"
                  size={4}
                  key={item.name}
                  className="flex-wrap justify-center"
                >
                  <Space align="baseline">
                    <Typography.Title
                      level={4}
                      style={{ color: item.color, margin: 0 }}
                    >
                      {item.value ? formatNumber(item.value) : LABEL_INACTIVE}
                    </Typography.Title>
                    {Boolean(item.percent) && (
                      <Text type="secondary">
                        ({formatNumber(item.percent)}%)
                      </Text>
                    )}
                  </Space>
                  <Text level={5}>{item.name}</Text>
                </Space>
              ))}
            </Space>
          </Card>
        </Col>
      </Row>

      {!useSendInBlueReport && (
        <Card size="small" className="mt-8">
          <LineChart
            xAxisDataKey="date"
            data={trendTimeSeriesData}
            lines={[
              {
                name: "Email",
                dataKey: SOURCE_EMAIL,
              },
            ]}
          />
        </Card>
      )}
    </section>
  );
}

DeepClicks.propTypes = {};

export default DeepClicks;
