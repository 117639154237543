import { UI_CARD_HEIGHT_DEFAULT, UI_CARD_WIDTH_DEFAULT } from "constants/ui";
import PropTypes from "prop-types";
import React from "react";

function CardPreview({ contentId, contentType }) {
  return (
    <div>
      <iframe
        width={UI_CARD_WIDTH_DEFAULT}
        height={UI_CARD_HEIGHT_DEFAULT}
        title="Content Preview"
        scrolling="no"
        frameBorder="0"
        className="overflow-hidden mx-auto"
        src={`${process.env.REACT_APP_PLUGILO_PREVIEW_HOST}/contentPreview/${contentId}/${contentType}?actions=0&dt=0`}
        style={{
          boxShadow:
            "0px 20px 40px rgb(33 37 41 / 5%), 0px 0px 2px rgb(33 37 41 / 16%)",
          borderRadius: "16px",
        }}
      />
    </div>
  );
}

CardPreview.propTypes = {
  htmlContent: PropTypes.string,
};

export default CardPreview;
