import React, { useEffect, useMemo } from "react";
import { isEmpty, sum, orderBy } from "lodash";
import { useContentViewsContext } from "../../ContentViewsContext";
import ChartTitle from "components/ChartTitle";
import { GRID_GUTTER, LABEL_INACTIVE } from "constants/ui";
import { Card, Col, Row, Space, Typography } from "antd";
import TableChart from "components/charts/TableChart";
import { DIMENSION_ITEM_SOURCE } from "constants/customDimensions";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import {
  DIMENSION_DATE,
  DIMENSION_EVENT_NAME,
  DIMENSION_HOSTNAME,
} from "constants/dimensions";
import { EVENT_VIEW_ITEM_IMPRESSION } from "constants/customEvents";
import { SOURCE_PLUGILO, SOURCE_WIDGET } from "constants/contentSources";
import { indigo300, sky300 } from "constants/colors";
import PieChart from "components/charts/PieChart";
import { formatNumber } from "services/numberService";
import Text from "antd/lib/typography/Text";
import LineChart from "components/charts/LineChart";
import { reviseTimeSeriesData } from "services/gaService";

function Impressions() {
  const { summaryReport, impressionsReport, dateStrings, setTimeSeriesData } =
    useContentViewsContext();

  const plugiloCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGILO
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);

  const widgetCount = useMemo(() => {
    if (isEmpty(summaryReport)) return 0;
    const filteredrows = summaryReport.rows.filter(
      (row) =>
        row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
        row[DIMENSION_ITEM_SOURCE] === SOURCE_WIDGET
    );
    return sum(filteredrows.map((row) => row[METRIC_EVENT_COUNT]));
  }, [summaryReport]);

  const pieChartData = useMemo(() => {
    const total = plugiloCount + widgetCount;
    const plugiloPercent = total ? (plugiloCount / total) * 100 : null;
    const widgetPercent = total ? (widgetCount / total) * 100 : null;

    return [
      {
        name: "plugilo",
        value: plugiloCount,
        color: sky300,
        percent: plugiloPercent,
      },
      {
        name: "Widgets",
        value: widgetCount,
        color: indigo300,
        percent: widgetPercent,
      },
    ];
  }, [plugiloCount, widgetCount]);

  const impressionsByPartnersTableData = useMemo(() => {
    if (isEmpty(impressionsReport)) return [];

    const rowsGroupedByPartner = impressionsReport.rows.reduce(
      (result, current) => {
        const hostName = current[DIMENSION_HOSTNAME];
        if (!result[hostName]) result[hostName] = {};
        result[hostName][DIMENSION_HOSTNAME] = hostName;
        result[hostName][METRIC_EVENT_COUNT] = sum([
          result[hostName][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    return orderBy(
      Object.values(rowsGroupedByPartner),
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [impressionsReport]);

  const impressionsTrendTimeSeriesData = useMemo(() => {
    if (isEmpty(summaryReport)) return [];

    const dataKeysMap = {};
    const rowsGroupedByDate = summaryReport.rows
      .filter(
        (row) =>
          [EVENT_VIEW_ITEM_IMPRESSION].includes(row[DIMENSION_EVENT_NAME]) &&
          [SOURCE_WIDGET, SOURCE_PLUGILO].includes(row[DIMENSION_ITEM_SOURCE])
      )
      .reduce((result, current) => {
        const date = current[DIMENSION_DATE];
        const source = current[DIMENSION_ITEM_SOURCE];
        if (!result[date]) result[date] = {};

        result[date][DIMENSION_DATE] = date;
        result[date][source] = sum([
          result[date][source],
          current[METRIC_EVENT_COUNT],
        ]);
        result[date][METRIC_EVENT_COUNT] = sum([
          result[date][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        dataKeysMap[source] = source;
        return result;
      }, {});

    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [...Object.keys(dataKeysMap), METRIC_EVENT_COUNT],
    });
  }, [dateStrings, summaryReport]);

  useEffect(() => {
    setTimeSeriesData("impressions", impressionsTrendTimeSeriesData);
  }, [impressionsTrendTimeSeriesData]);

  return (
    <section className="mt-8">
      <Space className="mb-2">
        <span className="text-xl font-semibold mb-0">Impressions</span> (plugilo & Widgets)
      </Space>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12}>
          <Card size="small" className="lg:h-full flex-grow">
            <TableChart
              rowKey={DIMENSION_HOSTNAME}
              dataSource={impressionsByPartnersTableData}
              columns={[
                {
                  title: "Partner",
                  dataIndex: DIMENSION_HOSTNAME,
                  key: DIMENSION_HOSTNAME,
                  ellipsis: true,
                },
                {
                  title: "Impressions",
                  dataIndex: METRIC_EVENT_COUNT,
                  key: METRIC_EVENT_COUNT,
                  width: 100,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>

        <Col xs={24} lg={12}>
          <Card size="small" className="text-center lg:h-full mt-8 lg:mt-0">
            <div className="h-80">
              <PieChart
                data={pieChartData}
                dataKey="value"
                nameKey="name"
                colors={[sky300, indigo300]}
                showLegends={false}
              />
            </div>
            <Space size={40}>
              {pieChartData.map((item) => (
                <Space
                  direction="vertical"
                  size={4}
                  key={item.name}
                  className="flex-wrap justify-center"
                >
                  <Space align="baseline">
                    <Typography.Title
                      level={4}
                      style={{ color: item.color, margin: 0 }}
                    >
                      {item.value ? formatNumber(item.value) : LABEL_INACTIVE}
                    </Typography.Title>
                    {Boolean(item.percent) && (
                      <Text type="secondary">
                        ({formatNumber(item.percent)}%)
                      </Text>
                    )}
                  </Space>
                  <Text level={5}>{item.name}</Text>
                </Space>
              ))}
            </Space>
          </Card>
        </Col>
      </Row>
      <Card size="small" className="mt-8">
        <LineChart
          xAxisDataKey="date"
          data={impressionsTrendTimeSeriesData}
          lines={[
            {
              name: "plugilo",
              dataKey: "plugilo",
            },
            {
              name: "Widgets",
              dataKey: "infoboard",
            },
          ]}
        />
      </Card>
    </section>
  );
}

Impressions.propTypes = {};

export default Impressions;
