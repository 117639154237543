import PropTypes from "prop-types";
import React, { useContext } from "react";
import { noop } from "lodash";

const ContentViewsContext = React.createContext({
  summaryReport: null,
  contentData: null,
  plugiloReport: null,
  deepClicksReport: null,
  deepLinkClicksReport: null,
  impressionsReport: null,
  dateStrings: null,
  emailSourcesMap: null,
  clickSourcesMap: null,
  deepClickSourcesMap: null,
  sendInBlueReport: null,
  useSendInBlueReport: null,
  emailEventsMap: null,
  setEmailEventCount: noop,
  timeSeriesEventsMap: {},
  setTimeSeriesData: noop,
});
ContentViewsContext.displayName = "ContentViewsContext";

export const useContentViewsContext = () => useContext(ContentViewsContext);

export default function ContentViewsProvider({ children, value }) {
  return (
    <ContentViewsContext.Provider value={value}>
      {children}
    </ContentViewsContext.Provider>
  );
}

ContentViewsProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.object,
};
