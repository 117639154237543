import { Card, Col, Row } from "antd";
import Scorecard from "components/charts/Scorecard";
import ChartTitle from "components/ChartTitle";
import { EVENT_PLUG_ITEM, EVENT_SAVE_ITEM } from "constants/customEvents";
import { GRID_GUTTER } from "constants/ui";
import React, { useMemo } from "react";
import { isEmpty, sum } from "lodash";

const SaveContent = ({ data, hasPlugiloSource }) => {
  const saveCount = useMemo(() => {
    if (isEmpty(data)) return 0;

    return sum(data.map((item) => item[EVENT_SAVE_ITEM]));
  }, [data]);

  const plugCount = useMemo(() => {
    if (isEmpty(data)) return 0;

    return sum(data.map((item) => item[EVENT_PLUG_ITEM]));
  }, [data]);

  if (!hasPlugiloSource) {
    return null;
  }

  return (
    <Col xs={24} lg={12} className="mb-8">
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={24} className="mb-8">
          <ChartTitle level={5}>Save contents</ChartTitle>
          <Card size="small" className="lg:h-full">
            <Scorecard
              title="Save"
              value={saveCount}
              chartProps={{
                data,
                lines: [
                  {
                    name: "Save",
                    dataKey: EVENT_SAVE_ITEM,
                  },
                ],
              }}
            />
          </Card>
        </Col>
        <Col xs={24} lg={24} className="my-8">
          <ChartTitle>Plug contents</ChartTitle>
          <Card size="small" className="lg:h-full">
            <Scorecard
              title="Plug"
              value={plugCount}
              chartProps={{
                data,
                lines: [
                  {
                    name: "Plug",
                    dataKey: EVENT_PLUG_ITEM,
                  },
                ],
              }}
            />
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default SaveContent;
