import React, { useCallback, useEffect, useMemo, useState } from "react";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { DIMENSION_DATE, DIMENSION_EVENT_NAME } from "constants/dimensions";
import {
  DIMENSION_ITEM_BRAND,
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_ID_TEMP,
  DIMENSION_ITEM_NAME,
  DIMENSION_ITEM_SOURCE,
} from "constants/customDimensions";
import { get, isEmpty, orderBy, sum } from "lodash";
import { Card, Col, Row, Spin, Divider, Radio } from "antd";
import { GRID_GUTTER } from "constants/ui";
import LineChart from "components/charts/LineChart";
import {
  getBatchReports,
  getContentTypeName,
  reviseReport,
  reviseTimeSeriesData,
} from "services/gaService";
import {
  FILTER_EVENT_VIEW_ITEM_DETAILS,
  FILTER_EVENT_VIEW_ITEM_IMPRESSIONS,
  buildStringFilter,
  buildInListFilter,
  FILTER_EVENT_VIEW_ITEM_EMAIL_DETAILS,
  FILTER_EVENT_VIEW_ITEM_EXTERNAL,
} from "services/gaDimensionsService";
import {
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_EMAIL_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import PieChart from "components/charts/PieChart";
import ContentType from "components/ContentType";
import Scorecard from "components/charts/Scorecard";
import TableChart from "components/charts/TableChart";
import ContentLink from "components/ContentLink";
import ChartTitle from "components/ChartTitle";
import { formatNumber } from "services/numberService";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import { v4 as uuid } from "uuid";
import {
  SOURCE_EMAIL,
  SOURCE_PLUGILO,
  SOURCE_WIDGET,
} from "constants/contentSources";
import { alertUnknownError } from "services/notificationService";

const syncNamesOptions = {
  idKey: DIMENSION_ITEM_ID,
  nameKey: DIMENSION_ITEM_NAME,
  typeKey: DIMENSION_ITEM_CATEGORY,
};

const ignoreItemCategories = ["infoboard", ""];

const sourceOptions = [
  { label: "All", value: "" },
  { label: "plugilo", value: SOURCE_PLUGILO },
  { label: "Widget", value: SOURCE_WIDGET },
  { label: "Email", value: SOURCE_EMAIL },
];

const ContentViews = () => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSource, setSelectedSource] = useState(sourceOptions[0].value);
  const { dateStrings } = useReportContext();

  const buildFilters = (filters, notFilters) => {
    const sourceFilter = selectedSource
      ? buildStringFilter(DIMENSION_ITEM_SOURCE, selectedSource)
      : buildInListFilter(DIMENSION_ITEM_SOURCE, [
          SOURCE_PLUGILO,
          SOURCE_EMAIL,
          SOURCE_WIDGET,
        ]);
    return {
      andGroup: {
        expressions: [sourceFilter, ...(filters || [])],
      },
    };
  };

  const reportRequests = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;

    return [
      // Content Active Views & Details
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_ITEM_SOURCE,
          },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          buildInListFilter(DIMENSION_EVENT_NAME, [
            EVENT_VIEW_ITEM_DETAILS,
            EVENT_VIEW_ITEM_IMPRESSION,
            EVENT_VIEW_ITEM_EMAIL_DETAILS,
          ]),
        ]),
      },
      // Content Active Views from plugilo portal
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
          // {
          //   name: DIMENSION_ITEM_NAME,
          // },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
          {
            name: DIMENSION_ITEM_BRAND,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          FILTER_EVENT_VIEW_ITEM_DETAILS,
          buildStringFilter(DIMENSION_ITEM_SOURCE, "plugilo"),
        ]),
        limit: 100,
        offset: 0,
      },
      // Content Active Views from email
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
          // {
          //   name: DIMENSION_ITEM_NAME,
          // },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
          {
            name: DIMENSION_ITEM_BRAND,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([FILTER_EVENT_VIEW_ITEM_EMAIL_DETAILS]),
        limit: 100,
        offset: 0,
      },
      // Content Open in new tab
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
          // {
          //   name: DIMENSION_ITEM_NAME,
          // },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
          {
            name: DIMENSION_ITEM_BRAND,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([FILTER_EVENT_VIEW_ITEM_EXTERNAL]),
        limit: 100,
        offset: 0,
      },
      // Content Impressions
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
          // {
          //   name: DIMENSION_ITEM_NAME,
          // },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
          {
            name: DIMENSION_ITEM_BRAND,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([FILTER_EVENT_VIEW_ITEM_IMPRESSIONS]),
        limit: 100,
        offset: 0,
      },
    ];
  }, [selectedSource, dateStrings]);

  useEffect(() => {
    if (!reportRequests) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await getBatchReports(reportRequests);
        setReports(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        setReports([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [reportRequests]);

  const filterReport = useCallback((report) => {
    if (isEmpty(report)) return {};

    const rows = report.rows.filter((row) => {
      return !ignoreItemCategories.includes(row[DIMENSION_ITEM_CATEGORY]);
    });

    return {
      ...report,
      rows,
    };
  }, []);

  const viewsReportData = useMemo(() => {
    return filterReport(reviseReport(reports[0]));
  }, [filterReport, reports]);

  const plugiloActiveViewsReportData = useMemo(() => {
    return filterReport(reviseReport(reports[1]));
  }, [filterReport, reports]);

  const emailActiveViewsReportData = useMemo(() => {
    return filterReport(reviseReport(reports[2]));
  }, [filterReport, reports]);

  const contentsOpenedInNewTabReportData = useMemo(() => {
    return filterReport(reviseReport(reports[3]));
  }, [filterReport, reports]);

  const impressionsReportData = useMemo(() => {
    return filterReport(reviseReport(reports[4]));
  }, [filterReport, reports]);

  const activeViewsTrendTimeSeriesData = useMemo(() => {
    if (isEmpty(viewsReportData)) return [];

    const dataKeysMap = {};
    const rowsGroupedByDate = viewsReportData.rows
      .filter((row) =>
        [EVENT_VIEW_ITEM_DETAILS, EVENT_VIEW_ITEM_EMAIL_DETAILS].includes(
          row[DIMENSION_EVENT_NAME]
        )
      )
      .reduce((result, current) => {
        const date = current[DIMENSION_DATE];
        const source = current[DIMENSION_ITEM_SOURCE];
        if (!result[date]) result[date] = {};

        result[date][DIMENSION_DATE] = date;
        result[date][source] = sum([
          result[date][source],
          current[METRIC_EVENT_COUNT],
        ]);
        result[date][METRIC_EVENT_COUNT] = sum([
          result[date][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        dataKeysMap[source] = source;
        return result;
      }, {});

    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [...Object.keys(dataKeysMap), METRIC_EVENT_COUNT],
    });
  }, [dateStrings, viewsReportData]);

  const activeViewsCount = useMemo(() => {
    if (isEmpty(activeViewsTrendTimeSeriesData)) return 0;

    return sum(
      activeViewsTrendTimeSeriesData.map((item) => item[METRIC_EVENT_COUNT])
    );
  }, [activeViewsTrendTimeSeriesData]);

  const plugiloActiveViewsCount = useMemo(() => {
    if (isEmpty(activeViewsTrendTimeSeriesData)) return 0;

    return sum(activeViewsTrendTimeSeriesData.map((row) => row["plugilo"]));
  }, [activeViewsTrendTimeSeriesData]);

  const widgetActiveViewsCount = useMemo(() => {
    if (isEmpty(activeViewsTrendTimeSeriesData)) return 0;

    return sum(activeViewsTrendTimeSeriesData.map((row) => row["infoboard"]));
  }, [activeViewsTrendTimeSeriesData]);

  const emailActiveViewsCount = useMemo(() => {
    if (isEmpty(activeViewsTrendTimeSeriesData)) return 0;

    return sum(activeViewsTrendTimeSeriesData.map((row) => row["email"]));
  }, [activeViewsTrendTimeSeriesData]);

  const plugiloContentActiveViewsByTypesPieChartData = useMemo(() => {
    if (isEmpty(viewsReportData)) return [];

    const rowsGroupedBySource = viewsReportData.rows
      .filter(
        (row) =>
          [EVENT_VIEW_ITEM_DETAILS].includes(row[DIMENSION_EVENT_NAME]) &&
          row[DIMENSION_ITEM_SOURCE] === "plugilo"
      )
      .reduce((result, current) => {
        const category = current[DIMENSION_ITEM_CATEGORY];
        if (!result[category]) result[category] = {};

        result[category][DIMENSION_ITEM_CATEGORY] =
          getContentTypeName(category);
        result[category][METRIC_EVENT_COUNT] = sum([
          result[category][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      }, {});

    return orderBy(Object.values(rowsGroupedBySource), [DIMENSION_DATE]);
  }, [viewsReportData]);

  const plugiloContentActiveViewsTableData = useMemo(() => {
    if (isEmpty(plugiloActiveViewsReportData)) return [];

    return orderBy(
      plugiloActiveViewsReportData.rows,
      [METRIC_EVENT_COUNT],
      ["desc"]
    ).map((item) => ({ ...item, [DIMENSION_ITEM_ID_TEMP]: uuid() }));
  }, [plugiloActiveViewsReportData]);

  // Email
  const emailContentActiveViewsTableData = useMemo(() => {
    if (isEmpty(emailActiveViewsReportData)) return [];

    const rowsGroupedByDate = emailActiveViewsReportData.rows.reduce(
      (result, current) => {
        const itemId = current[DIMENSION_ITEM_ID];
        if (!result[itemId]) result[itemId] = {};
        result[itemId][DIMENSION_ITEM_ID] = itemId;
        result[itemId][DIMENSION_ITEM_BRAND] = current[DIMENSION_ITEM_BRAND];
        result[itemId][DIMENSION_ITEM_NAME] = current[DIMENSION_ITEM_NAME];
        result[itemId][DIMENSION_ITEM_CATEGORY] =
          current[DIMENSION_ITEM_CATEGORY];
        result[itemId][METRIC_EVENT_COUNT] = sum([
          result[itemId][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    return orderBy(
      Object.values(rowsGroupedByDate),
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [emailActiveViewsReportData]);

  // Impressions
  const impressionsTrendTimeSeriesData = useMemo(() => {
    if (isEmpty(viewsReportData)) return [];

    const dataKeysMap = {};
    const rowsGroupedByDate = viewsReportData.rows
      .filter((row) =>
        [EVENT_VIEW_ITEM_IMPRESSION].includes(row[DIMENSION_EVENT_NAME])
      )
      .reduce((result, current) => {
        const date = current[DIMENSION_DATE];
        const source = current[DIMENSION_ITEM_SOURCE];
        if (!result[date]) result[date] = {};

        result[date][DIMENSION_DATE] = date;
        result[date][source] = sum([
          result[date][source],
          current[METRIC_EVENT_COUNT],
        ]);
        result[date][METRIC_EVENT_COUNT] = sum([
          result[date][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        dataKeysMap[source] = source;
        return result;
      }, {});

    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [...Object.keys(dataKeysMap), METRIC_EVENT_COUNT],
    });
  }, [dateStrings, viewsReportData]);

  const impressionsCount = useMemo(() => {
    if (isEmpty(impressionsTrendTimeSeriesData)) return 0;

    return sum(
      impressionsTrendTimeSeriesData.map((item) => item[METRIC_EVENT_COUNT])
    );
  }, [impressionsTrendTimeSeriesData]);

  const plugiloImpressionsCount = useMemo(() => {
    if (isEmpty(impressionsTrendTimeSeriesData)) return 0;

    return sum(impressionsTrendTimeSeriesData.map((row) => row["plugilo"]));
  }, [impressionsTrendTimeSeriesData]);

  const widgetImpressionsCount = useMemo(() => {
    if (isEmpty(impressionsTrendTimeSeriesData)) return 0;

    return sum(impressionsTrendTimeSeriesData.map((row) => row["infoboard"]));
  }, [impressionsTrendTimeSeriesData]);

  const emailImpressionsCount = useMemo(() => {
    if (isEmpty(impressionsTrendTimeSeriesData)) return 0;

    return sum(impressionsTrendTimeSeriesData.map((row) => row["email"]));
  }, [impressionsTrendTimeSeriesData]);

  const contentImpressionsTableData = useMemo(() => {
    if (isEmpty(impressionsReportData)) return [];

    const rowsGroupedByDate = impressionsReportData.rows.reduce(
      (result, current) => {
        const itemId = current[DIMENSION_ITEM_ID];
        if (!result[itemId]) result[itemId] = {};
        result[itemId][DIMENSION_ITEM_ID] = itemId;
        result[itemId][DIMENSION_ITEM_BRAND] = current[DIMENSION_ITEM_BRAND];
        result[itemId][DIMENSION_ITEM_NAME] = current[DIMENSION_ITEM_NAME];
        result[itemId][DIMENSION_ITEM_CATEGORY] =
          current[DIMENSION_ITEM_CATEGORY];
        result[itemId][METRIC_EVENT_COUNT] = sum([
          result[itemId][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    return orderBy(
      Object.values(rowsGroupedByDate),
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [impressionsReportData]);

  const plugiloContenImpressionsByTypesPieChartData = useMemo(() => {
    if (isEmpty(viewsReportData)) return [];

    const rowsGroupedByCategories = viewsReportData.rows
      .filter((row) =>
        [EVENT_VIEW_ITEM_IMPRESSION].includes(row[DIMENSION_EVENT_NAME])
      )
      .reduce((result, current) => {
        const category = current[DIMENSION_ITEM_CATEGORY];
        if (!result[category]) result[category] = {};

        result[category][DIMENSION_ITEM_CATEGORY] =
          getContentTypeName(category);
        result[category][METRIC_EVENT_COUNT] = sum([
          result[category][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      }, {});

    return orderBy(Object.values(rowsGroupedByCategories), [DIMENSION_DATE]);
  }, [viewsReportData]);

  // Content Open in new tab
  const contentsOpenedInNewTabTableData = useMemo(() => {
    if (isEmpty(contentsOpenedInNewTabReportData)) return [];

    const rowsGroupedByDate = contentsOpenedInNewTabReportData.rows.reduce(
      (result, current) => {
        const itemId = current[DIMENSION_ITEM_ID];
        if (!result[itemId]) result[itemId] = {};
        result[itemId][DIMENSION_ITEM_ID] = itemId;
        result[itemId][DIMENSION_ITEM_BRAND] = current[DIMENSION_ITEM_BRAND];
        result[itemId][DIMENSION_ITEM_NAME] = current[DIMENSION_ITEM_NAME];
        result[itemId][DIMENSION_ITEM_CATEGORY] =
          current[DIMENSION_ITEM_CATEGORY];
        result[itemId][METRIC_EVENT_COUNT] = sum([
          result[itemId][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      },
      {}
    );

    return orderBy(
      Object.values(rowsGroupedByDate),
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [contentsOpenedInNewTabReportData]);

  return (
    <Spin spinning={isLoading}>
      {/* <div className="flex">
        <span className="ml-auto">
          <Radio.Group
            size="small"
            options={sourceOptions}
            onChange={(e) => setSelectedSource(e.target.value)}
            value={selectedSource}
            optionType="button"
            buttonStyle="solid"
          />
        </span>
      </div> */}

      <ChartTitle>Active views trend</ChartTitle>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <Card size="small">
            <LineChart
              xAxisDataKey="date"
              data={activeViewsTrendTimeSeriesData}
              lines={[
                {
                  name: "plugilo",
                  dataKey: "plugilo",
                },
                {
                  name: "Widgets",
                  dataKey: "infoboard",
                },
                {
                  name: "Email",
                  dataKey: "email",
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <Row gutter={GRID_GUTTER}>
            <Col span={24} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Active Views"
                  value={activeViewsCount}
                  chartProps={{
                    data: activeViewsTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Active Views",
                        dataKey: METRIC_EVENT_COUNT,
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={GRID_GUTTER}>
            <Col span={8} className="mb-8">
              <Card size="small" className="lg:h-full">
                <Scorecard
                  title="plugilo"
                  value={plugiloActiveViewsCount}
                  chartProps={{
                    data: activeViewsTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Active Views",
                        dataKey: "plugilo",
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
            <Col span={8} className="mb-8">
              <Card size="small" className="lg:h-full">
                <Scorecard
                  title="Widget"
                  value={widgetActiveViewsCount}
                  chartProps={{
                    data: activeViewsTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Widgets",
                        dataKey: "infoboard",
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
            <Col span={8} className="mb-8">
              <Card size="small" className="lg:h-full">
                <Scorecard
                  title="Email"
                  value={emailActiveViewsCount}
                  chartProps={{
                    data: activeViewsTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Email",
                        dataKey: "email",
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER}>
        {/* Most Viewed Contents from plugilo portal */}
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Top Active views contents from plugilo portal</ChartTitle>
          <Card size="small" className="lg:h-full">
            <TableChart
              dataSource={plugiloContentActiveViewsTableData}
              rowKey={DIMENSION_ITEM_ID_TEMP}
              syncNames={syncNamesOptions}
              columns={[
                {
                  title: "Content",
                  dataIndex: DIMENSION_ITEM_ID,
                  key: DIMENSION_ITEM_ID,
                  render: (_, record) => (
                    <ContentLink
                      id={record[DIMENSION_ITEM_ID]}
                      name={record[DIMENSION_ITEM_NAME]}
                      tag={record[DIMENSION_ITEM_CATEGORY]}
                      ownerDomain={record[DIMENSION_ITEM_BRAND]}
                    />
                  ),
                  ellipsis: true,
                },
                {
                  title: "Type",
                  dataIndex: DIMENSION_ITEM_CATEGORY,
                  key: DIMENSION_ITEM_CATEGORY,
                  render: (value) => <ContentType tag={value} />,
                  width: 80,
                },
                {
                  title: "Active Views",
                  dataIndex: METRIC_EVENT_COUNT,
                  key: METRIC_EVENT_COUNT,
                  width: 100,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>
            Active views by content types from plugilo portal
          </ChartTitle>
          <Card size="small" className="lg:h-full">
            <div className="h-80">
              <PieChart
                data={plugiloContentActiveViewsByTypesPieChartData}
                dataKey={METRIC_EVENT_COUNT}
                nameKey={DIMENSION_ITEM_CATEGORY}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER} className="mt-8">
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Top clicked contents from email</ChartTitle>
          <Card size="small" className="lg:h-full">
            <TableChart
              rowKey={DIMENSION_ITEM_ID}
              dataSource={emailContentActiveViewsTableData}
              syncNames={syncNamesOptions}
              columns={[
                {
                  title: "Content",
                  dataIndex: DIMENSION_ITEM_ID,
                  key: DIMENSION_ITEM_ID,
                  ellipsis: true,
                  render: (_, record) => (
                    <ContentLink
                      id={record[DIMENSION_ITEM_ID]}
                      name={record[DIMENSION_ITEM_NAME]}
                      tag={record[DIMENSION_ITEM_CATEGORY]}
                      ownerDomain={record[DIMENSION_ITEM_BRAND]}
                    />
                  ),
                },
                {
                  title: "Type",
                  dataIndex: DIMENSION_ITEM_CATEGORY,
                  key: DIMENSION_ITEM_CATEGORY,
                  render: (value) => <ContentType tag={value} />,
                  width: 80,
                },
                {
                  title: "Active Views",
                  dataIndex: METRIC_EVENT_COUNT,
                  key: METRIC_EVENT_COUNT,
                  width: 100,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>

        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Contents open in new tab</ChartTitle>
          <Card size="small" className="lg:h-full">
            <TableChart
              rowKey={DIMENSION_ITEM_ID}
              dataSource={contentsOpenedInNewTabTableData}
              syncNames={syncNamesOptions}
              columns={[
                {
                  title: "Content",
                  dataIndex: DIMENSION_ITEM_ID,
                  key: DIMENSION_ITEM_ID,
                  render: (_, record) => (
                    <ContentLink
                      id={record[DIMENSION_ITEM_ID]}
                      name={record[DIMENSION_ITEM_NAME]}
                      tag={record[DIMENSION_ITEM_CATEGORY]}
                      ownerDomain={record[DIMENSION_ITEM_BRAND]}
                    />
                  ),
                },
                {
                  title: "Type",
                  dataIndex: DIMENSION_ITEM_CATEGORY,
                  key: DIMENSION_ITEM_CATEGORY,
                  render: (value) => <ContentType tag={value} />,
                },
                {
                  title: "Count",
                  dataIndex: METRIC_EVENT_COUNT,
                  key: METRIC_EVENT_COUNT,
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>

      <Divider />

      {/* Impressions */}
      <ChartTitle>Content Impressions Trend</ChartTitle>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <Card size="small">
            <LineChart
              xAxisDataKey={DIMENSION_DATE}
              data={impressionsTrendTimeSeriesData}
              lines={[
                {
                  name: "plugilo",
                  dataKey: "plugilo",
                },
                {
                  name: "Widgets",
                  dataKey: "infoboard",
                },
                {
                  name: "Email",
                  dataKey: "email",
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <Row gutter={GRID_GUTTER}>
            <Col span={24} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Impressions"
                  value={impressionsCount}
                  chartProps={{
                    data: impressionsTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Impressions",
                        dataKey: METRIC_EVENT_COUNT,
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={GRID_GUTTER}>
            <Col span={8} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="plugilo"
                  value={plugiloImpressionsCount}
                  chartProps={{
                    data: impressionsTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Impressions",
                        dataKey: "plugilo",
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
            <Col span={8} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Widget"
                  value={widgetImpressionsCount}
                  chartProps={{
                    data: impressionsTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Impressions",
                        dataKey: "infoboard",
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
            <Col span={8} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Widget"
                  value={emailImpressionsCount}
                  chartProps={{
                    data: impressionsTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Emails",
                        dataKey: "email",
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Top impression contents</ChartTitle>
          <Card size="small" className="lg:h-full">
            <TableChart
              dataSource={contentImpressionsTableData}
              rowKey={DIMENSION_ITEM_ID}
              syncNames={syncNamesOptions}
              columns={[
                {
                  title: "Content",
                  dataIndex: DIMENSION_ITEM_ID,
                  key: DIMENSION_ITEM_ID,
                  render: (_, record) => (
                    <ContentLink
                      id={record[DIMENSION_ITEM_ID]}
                      name={record[DIMENSION_ITEM_NAME]}
                      tag={record[DIMENSION_ITEM_CATEGORY]}
                      ownerDomain={record[DIMENSION_ITEM_BRAND]}
                    />
                  ),
                  ellipsis: true,
                },
                {
                  title: "Type",
                  dataIndex: DIMENSION_ITEM_CATEGORY,
                  key: DIMENSION_ITEM_CATEGORY,
                  render: (value) => <ContentType tag={value} />,
                  width: 80,
                },
                {
                  title: "Impressions",
                  dataIndex: METRIC_EVENT_COUNT,
                  key: METRIC_EVENT_COUNT,
                  width: 100,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Impressions By Content Types</ChartTitle>
          <Card size="small" className="lg:h-full">
            <div className="h-80">
              <PieChart
                data={plugiloContenImpressionsByTypesPieChartData}
                dataKey={METRIC_EVENT_COUNT}
                nameKey={DIMENSION_ITEM_CATEGORY}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

ContentViews.propTypes = {};

export default ContentViews;
