import { Typography } from "antd";

const ChartTitle = ({ children }) => {
  return <Typography.Title level={5}>{children}</Typography.Title>;
};

ChartTitle.propTypes = {};

ChartTitle.defaultProps = {};

export default ChartTitle;
