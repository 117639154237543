import React, { useState, useEffect, useMemo } from "react";
import { Badge, Card, Col, Row, Space, Spin, Table } from "antd";
import { GRID_GUTTER } from "constants/ui";
import moment from "moment";
import { useParams } from "react-router";
import ChartTitle from "components/ChartTitle";
import { getContent, getSendInBlueReport } from "services/gaService";
import { get, sum, orderBy } from "lodash";
import PieChart from "components/charts/PieChart";
import TableChart from "components/charts/TableChart";
import { formatNumber } from "services/numberService";
import { DATE_FORMAT } from "constants/default";
import EmailPreview from "./components/EmailPreview";
import Text from "antd/lib/typography/Text";
import { gray300, sky300 } from "constants/colors";
import { PARAM_CONTENT_TYPE } from "constants/queryStringParams";
import useQueryString from "hooks/useQueryString";

const EmailCampaignSendInBlue = () => {
  const [report, setReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id: contentId } = useParams();
  const { [PARAM_CONTENT_TYPE]: contentType } = useQueryString();

  useEffect(() => {
    if (!contentId) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const contentData = await getContent(contentId, contentType);
        if (contentData && contentData.sibCampaignId) {
          const reportData = await getSendInBlueReport(
            {id: contentData.sibCampaignId, contentId: contentId}
          );

          setReport(reportData);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  const sentCount = useMemo(() => {
    return get(report, "statistics.globalStats.sent", 0);
  }, [report]);

  const viewedCount = useMemo(() => {
    return get(report, "statistics.globalStats.viewed", 0);
  }, [report]);

  const readPieChartData = useMemo(() => {
    const notMeasured = sentCount - viewedCount;
    const notMeasuredPct = (notMeasured / sentCount) * 100;
    const viewedPct = (viewedCount / sentCount) * 100;

    return [
      {
        name: "Not measured",
        value: notMeasured,
        color: gray300,
        percent: notMeasuredPct,
      },
      {
        name: "Viewed",
        value: viewedCount,
        color: sky300,
        percent: viewedPct,
      },
    ];
  }, [sentCount, viewedCount]);

  const newslettersTableChartData = useMemo(() => {
    return [
      {
        id: 1,
        name: get(report, "subject"),
        sent: sentCount,
        viewed: viewedCount,
        sentDate: get(report, "sentDate"),
        link: get(report, "shareLink"),
      },
    ];
  }, [report, sentCount, viewedCount]);

  const clicksTableChartData = useMemo(() => {
    const linksStats = get(report, "statistics.linksStats", {});
    const items = Object.keys(linksStats).map((key) => ({
      link: key,
      clicks: linksStats[key] || 0,
    }));

    return orderBy(items, ["clicks"], ["desc"]);
  }, [report]);

  const totalClicks = useMemo(() => {
    return sum(clicksTableChartData.map((item) => item.clicks));
  }, [clicksTableChartData]);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <div className="h-full flex flex-col">
            <ChartTitle>Card preview</ChartTitle>
            <Card size="small" className="text-center flex-grow">
              <EmailPreview
                className="w-full h-full block rounded-lg"
                contentId={contentId}
                contentType={contentType}
              />
            </Card>
          </div>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Statistics</ChartTitle>
          <Card size="small" className="text-center">
            <div className="h-80">
              <PieChart
                data={readPieChartData}
                dataKey="value"
                nameKey="name"
                colors={[gray300, sky300]}
                showLegends={false}
              />
            </div>
            <Space direction="vertical" className="justify-start" align="start">
              {readPieChartData.map((item) => (
                <Space>
                  <Badge color={item.color} />
                  <Text>{item.name}</Text>·<Text>{item.value}</Text>
                  <Text type="secondary">({formatNumber(item.percent)}%)</Text>
                </Space>
              ))}
            </Space>
          </Card>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER} className="mb-8">
        <Col xs={24} lg={24}>
          <ChartTitle>Newsletters</ChartTitle>
          <Card size="small">
            <TableChart
              dataSource={newslettersTableChartData}
              rowKey="id"
              columns={[
                {
                  title: "Date",
                  dataIndex: "sentDate",
                  width: 100,
                  render: (value) => moment(value).format(DATE_FORMAT),
                },
                {
                  title: "Name",
                  dataIndex: "name",
                  ellipsis: true,
                  render: (value, record) => (
                    <a
                      href={record.link}
                      target="_blank"
                      rel="noreferrer"
                      className="break-all"
                    >
                      {value}
                    </a>
                  ),
                },
                {
                  title: "Sent",
                  dataIndex: "sent",
                  width: 100,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
                {
                  title: "Viewed",
                  dataIndex: "viewed",
                  width: 100,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={24}>
          <ChartTitle>Clicks</ChartTitle>
          <Card size="small">
            <TableChart
              dataSource={clicksTableChartData}
              rowKey="link"
              columns={[
                {
                  title: "Link",
                  dataIndex: "link",
                  render: (value) => (
                    <a
                      href={value}
                      target="_blank"
                      rel="noreferrer"
                      className="break-all"
                    >
                      {value}
                    </a>
                  ),
                },
                {
                  title: "Clicks",
                  dataIndex: "clicks",
                  width: 100,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
              summary={() => (
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="secondary" className="font-semibold">
                      Total
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right">
                    <Text className="font-semibold">{totalClicks}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            />
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

EmailCampaignSendInBlue.propTypes = {};

export default EmailCampaignSendInBlue;
