import { Divider, Row, Typography } from 'antd';
import { GRID_GUTTER } from 'constants/ui';
import React, { useCallback } from 'react';
import TopClickContents from '../TopClickContents';
import ViewDetails from '../ViewDetails';
import ViewsContentTypes from '../ViewsContentTypes';
import SaveContent from '../SaveContents';
import { useOverViewContext } from '../../OverviewContextProvider';
import { isEmpty, sum, orderBy } from 'lodash';
import {
  SOURCE_EMAIL,
  SOURCE_PLUGILO,
  SOURCE_WIDGET,
} from 'constants/contentSources';
import { getContentTypeName } from 'services/gaService';
import { getCount } from 'services/overviewService';
import {
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_SOURCE,
} from 'constants/customDimensions';
import { METRIC_EVENT_COUNT } from 'constants/metrics';
import { DIMENSION_DATE } from 'constants/dimensions';

const PrintViewContent = ({
  viewsReportData,
  plugiloTableData,
  plugSaveTrendTimeSeriesData,
  plugiloClickData,
  emailTableData,
  emailClickData,
  widgetTableData,
  widgetClickData,
}) => {
  const { selectedSource } = useOverViewContext();
  const hasPlugiloSource =
    isEmpty(selectedSource) || selectedSource === SOURCE_PLUGILO;

  const pieChartDataBySource = useCallback(
    (source) => {
      if (isEmpty(viewsReportData)) return [];

      const rowsGroupedByCategory = viewsReportData.rows
        .filter((item) => source === item[DIMENSION_ITEM_SOURCE])
        .reduce((result, current) => {
          const category = current[DIMENSION_ITEM_CATEGORY];
          if (!result[category]) result[category] = {};

          result[category][DIMENSION_ITEM_CATEGORY] =
            getContentTypeName(category);
          result[category][METRIC_EVENT_COUNT] = sum([
            result[category][METRIC_EVENT_COUNT],
            getCount(current),
          ]);
          return result;
        }, {});

      return orderBy(Object.values(rowsGroupedByCategory), [DIMENSION_DATE]);
    },
    [viewsReportData]
  );

  return (
    <>
      <Divider />
      <Typography.Title level={3}>plugilo</Typography.Title>
      <Row gutter={GRID_GUTTER}>
        <ViewDetails dataSource={plugiloTableData} />
        <ViewsContentTypes data={pieChartDataBySource(SOURCE_PLUGILO)} />
      </Row>

      <Row gutter={GRID_GUTTER} className="my-8">
        <TopClickContents
          dataSource={plugiloClickData}
          hasPlugiloSource={hasPlugiloSource}
        />
        <SaveContent
          data={plugSaveTrendTimeSeriesData}
          hasPlugiloSource={hasPlugiloSource}
        />
      </Row>
      <Divider />
      <Typography.Title level={3}>Email</Typography.Title>
      <Row gutter={GRID_GUTTER}>
        <ViewDetails dataSource={emailTableData} />
        <ViewsContentTypes data={pieChartDataBySource(SOURCE_EMAIL)} />
      </Row>
      <Row gutter={GRID_GUTTER} className="my-8">
        <TopClickContents dataSource={emailClickData} />
      </Row>
      <Divider />
      <Typography.Title level={3}>Widget</Typography.Title>
      <Row gutter={GRID_GUTTER}>
        <ViewDetails dataSource={widgetTableData} />
        <ViewsContentTypes data={pieChartDataBySource(SOURCE_WIDGET)} />
      </Row>

      <Row gutter={GRID_GUTTER} className="my-8">
        <TopClickContents dataSource={widgetClickData} />
      </Row>
    </>
  );
};

export default PrintViewContent;
