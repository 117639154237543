import PropTypes from "prop-types";
import React, { useContext } from "react";

const AppContext = React.createContext({});
AppContext.displayName = "AppContext";

export const useAppContext = () => useContext(AppContext);

export default function AppContextProvider({ children, value }) {
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

AppContextProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.object,
};
