import React, { useEffect, useMemo, useState } from "react";
import { METRIC_TOTAL_USERS, METRIC_VIEWS } from "constants/metrics";
import {
  DIMENSION_DATE,
  DIMENSION_FULL_PAGE_URL,
  DIMENSION_PAGE_TITLE,
} from "constants/dimensions";
import { get, isEmpty, orderBy, sum } from "lodash";
import { Card, Col, Row, Spin } from "antd";
import { GRID_GUTTER } from "constants/ui";
import LineChart from "components/charts/LineChart";
import {
  getBatchReports,
  reviseReport,
  reviseTimeSeriesData,
} from "services/gaService";
import Favicon from "components/Favicon";
import Scorecard from "components/charts/Scorecard";
import TableChart from "components/charts/TableChart";
import ChartTitle from "components/ChartTitle";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import { alertUnknownError } from "services/notificationService";

const PageViews = () => {
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dateStrings } = useReportContext();

  const reportRequests = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;

    return [
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
        ],
        metrics: [
          {
            name: METRIC_VIEWS,
          },
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
      },

      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_PAGE_TITLE,
          },
          {
            name: DIMENSION_FULL_PAGE_URL,
          },
        ],
        metrics: [
          {
            name: METRIC_VIEWS,
          },
        ],
        limit: 1000,
        offset: 0,
      },
    ];
  }, [dateStrings]);

  useEffect(() => {
    if (!reportRequests) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await getBatchReports(reportRequests);
        setReports(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        setReports([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [reportRequests]);

  const commonReportData = useMemo(() => {
    return reviseReport(reports[0]);
  }, [reports]);

  const pagesReportData = useMemo(() => {
    return reviseReport(reports[1]);
  }, [reports]);

  const eventTrendTimeSeriesData = useMemo(() => {
    if (isEmpty(commonReportData)) return [];

    const dataKeysMap = {};
    const rowsGroupedByDate = commonReportData.rows.reduce(
      (result, current) => {
        const date = current[DIMENSION_DATE];
        if (!result[date]) result[date] = {};

        result[date][DIMENSION_DATE] = date;
        result[date][METRIC_VIEWS] = sum([
          result[date][METRIC_VIEWS],
          current[METRIC_VIEWS],
        ]);
        result[date][METRIC_TOTAL_USERS] = sum([
          result[date][METRIC_TOTAL_USERS],
          current[METRIC_TOTAL_USERS],
        ]);
        return result;
      },
      {}
    );

    // return orderBy(Object.values(rowsGroupedByDate), [DIMENSION_DATE]);
    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [...Object.keys(dataKeysMap), METRIC_VIEWS],
    });
  }, [commonReportData, dateStrings]);

  const viewsCount = useMemo(() => {
    if (isEmpty(eventTrendTimeSeriesData)) return 0;

    return sum(eventTrendTimeSeriesData.map((item) => item[METRIC_VIEWS]));
  }, [eventTrendTimeSeriesData]);

  const totalUsersCount = useMemo(() => {
    if (isEmpty(eventTrendTimeSeriesData)) return 0;

    return sum(
      eventTrendTimeSeriesData.map((item) => item[METRIC_TOTAL_USERS])
    );
  }, [eventTrendTimeSeriesData]);

  const pagesTableData = useMemo(() => {
    if (isEmpty(pagesReportData)) return [];

    return orderBy(pagesReportData.rows, [METRIC_VIEWS], ["desc"]);
  }, [pagesReportData]);

  return (
    <Spin spinning={isLoading}>
      <ChartTitle>Page views trend</ChartTitle>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <Card size="small">
            <LineChart
              xAxisDataKey={DIMENSION_DATE}
              data={eventTrendTimeSeriesData}
              lines={[
                {
                  name: "Event",
                  dataKey: METRIC_VIEWS,
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <Row gutter={GRID_GUTTER}>
            <Col span={24} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Views"
                  value={viewsCount}
                  chartProps={{
                    data: eventTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Views",
                        dataKey: METRIC_VIEWS,
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
            <Col span={24} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Total Users"
                  value={totalUsersCount}
                  chartProps={{
                    data: eventTrendTimeSeriesData,
                    lines: [
                      {
                        name: "Event",
                        dataKey: METRIC_TOTAL_USERS,
                      },
                    ],
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER}>
        <ChartTitle>Which Pages And Screens Get The Most Views?</ChartTitle>
        <Col xs={24} lg={24}>
          <Card size="small" className="lg:h-full">
            <TableChart
              rowKey={DIMENSION_FULL_PAGE_URL}
              dataSource={pagesTableData}
              columns={[
                {
                  title: "Page Title",
                  dataIndex: DIMENSION_PAGE_TITLE,
                  key: DIMENSION_PAGE_TITLE,
                  render: (value, record) => {
                    return (
                      <a
                        href={`https://${record[DIMENSION_FULL_PAGE_URL]}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Favicon
                          url={record[DIMENSION_FULL_PAGE_URL]}
                          className="mr-2"
                        />
                        {record[DIMENSION_PAGE_TITLE]}
                      </a>
                    );
                  },
                  ellipsis: true,
                },
                {
                  title: "Views",
                  dataIndex: METRIC_VIEWS,
                  key: METRIC_VIEWS,
                  width: 100,
                  align: "right",
                },
                {
                  title: "Total Users",
                  dataIndex: METRIC_TOTAL_USERS,
                  key: METRIC_TOTAL_USERS,
                  width: 100,
                  align: "right",
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

PageViews.propTypes = {};

export default PageViews;
