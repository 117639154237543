import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { METRIC_EVENT_COUNT, METRIC_TOTAL_USERS } from "constants/metrics";
import {
  DIMENSION_DATE,
  DIMENSION_EVENT_NAME,
  DIMENSION_HOSTNAME,
  DIMENSION_HOSTNAME_FULL,
  NOT_SET_DIMENSION_VALUE,
} from "constants/dimensions";
import {
  DIMENSION_INFOBOARD_BRAND,
  DIMENSION_ITEM_BRAND,
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_SOURCE_ID,
  DIMENSION_ITEM_SOURCE_NAME,
  DIMENSION_ITEM_SOURCE_STREAM_ID,
  DIMENSION_ITEM_SOURCE_STREAM_NAME,
} from "constants/customDimensions";
import { get, isEmpty, orderBy, reduce, sum, uniqBy } from "lodash";
import { Col, Row, Spin, Select, Space, Divider, message } from "antd";
import { GRID_GUTTER, HIDE_VISITORS } from "constants/ui";
import {
  getBatchReports,
  reviseReport,
  reviseTimeSeriesData,
  getContentNames,
  getInfoboards,
  getEmailuploadersByIds,
} from "services/gaService";
import {
  FILTER_ITEM_SOURCE_INFOBOARD,
  FILTER_ITEM_CATEGORY_INFOBOARD,
  FILTER_EVENT_VIEW_ITEM_DETAILS,
  FILTER_EVENT_VIEW_ITEM_IMPRESSIONS,
  buildStringFilter,
  FILTER_EVENT_VIEW_ITEM_CLICKED,
  buildInListFilter,
  FILTER_EVENT_CLOSE_ITEM,
  FILTER_EVENT_MINIMIZE_ITEM,
} from "services/gaDimensionsService";
import {
  EVENT_CLOSE_ITEM,
  EVENT_MINIMIZE_ITEM,
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import Favicon from "components/Favicon";
import { useParams } from "react-router";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import OverviewSection from "./components/OverviewSection";
import ContentActiveViewsSection from "./components/ContentActiveViewsSection";
import ContentClicksSection from "./components/ContentClicksSection";
import ContentActiveViewsByPartnersSection from "./components/ContentActiveViewsByPartnersSection";
import AdImpressionsByPartnersSection from "./components/AdImpressionsByPartnersSection";
import ContentClicksByPartnersSection from "./components/ContentClicksByPartnersSection";
import UsersByPartnersSection from "./components/UsersByPartnersSection";
import DailyTrafficSection from "./components/DailyTrafficSection";
import useSetQueryString from "hooks/useSetQueryString";
import {
  PARAM_WIDGET_HOST,
  PARAM_WIDGET_ITEM_OWNER,
  PARAM_WIDGET_SOURCE,
  PARAM_WIDGET_STREAM,
  PARAM_WIDGET_ITEM_ID,
} from "constants/queryStringParams";
import useQueryString from "hooks/useQueryString";
import { useAppContext } from "contexts/AppContextProvider";
import { alertUnknownError } from "services/notificationService";
import { validate } from "uuid";
import { isGuid } from "services/guidService";
import WidgetsProvider from "./WidgetsProvider";

const defaultOrderBys = [
  {
    desc: true,
    metric: {
      metricName: METRIC_EVENT_COUNT,
    },
  },
];

const buildFilters = (widgetIds, filters, notFilters) => {
  const expressions = [FILTER_ITEM_SOURCE_INFOBOARD];
  if (widgetIds && widgetIds.length) {
    expressions.push(buildInListFilter(DIMENSION_ITEM_SOURCE_ID, widgetIds));
  }
  expressions.push(...(filters || []));
  return {
    andGroup: {
      expressions: expressions,
      // notExpressions: notFilters,
    },
  };
};

const domainsHaveProductCardRight = [
  "krups.de",
  "sonicwall.com",
  "vnexpress.net",
  "fptplay.vn",
  "bluechip.de",
  "comteam.de",
];
const ignoreHostNames = [];
const Widgets = () => {
  const [setParam, _, removeParam] = useSetQueryString();
  const {
    [PARAM_WIDGET_HOST]: paramWidgetHost,
    [PARAM_WIDGET_SOURCE]: paramWidgetSource,
    [PARAM_WIDGET_STREAM]: paramWidgetStream,
    [PARAM_WIDGET_ITEM_OWNER]: paramWidgetItemOwner,
    [PARAM_WIDGET_ITEM_ID]: paramWidgetItemId,
  } = useQueryString();
  const [reports01, setReports01] = useState([]);
  const [reports02, setReports02] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const { dateStrings } = useReportContext();
  const [selectedSourceId, setSelectedSourceId] = useState(paramWidgetSource);
  const [selectedSourceStreamId, setSelectedSourceStreamId] =
    useState(paramWidgetStream);
  const [selectedHostName, setSelectedHostName] = useState(paramWidgetHost);
  const [selectedHostNameFull, setSelectedHostNameFull] = useState(null);
  const [selectedItemOwner, setSelectedItemOwner] =
    useState(paramWidgetItemOwner);
  const [selectedItemId, setSelectedItemId] = useState(paramWidgetItemId);
  const [availableContents, setAvailableContents] = useState([]);
  const [availableWidgetSources, setAvailableWidgetSources] = useState([]);
  const [widgetSources, setWidgetSources] = useState([]);

  const [availableWidgetSourceStreams, setAvailableWidgetSourceStreams] =
    useState([]);
  const { id: domain } = useParams();
  const hasProductRight = domainsHaveProductCardRight.includes(domain);
  const { isPrintMode } = useAppContext();

  const reportRequests01 = useMemo(() => {
    if (
      !dateStrings ||
      !dateStrings[0] ||
      !dateStrings[1] ||
      !widgetSources.length
    )
      return;
    const widgetIds = widgetSources.map((x) => x.id);
    return [
      // 0: General Filters
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_SOURCE_ID,
          },
          {
            name: DIMENSION_ITEM_SOURCE_NAME,
          },
          {
            name: DIMENSION_ITEM_SOURCE_STREAM_ID,
          },
          {
            name: DIMENSION_ITEM_SOURCE_STREAM_NAME,
          },
          {
            name: DIMENSION_HOSTNAME,
          },
          {
            name: DIMENSION_ITEM_BRAND,
          },
          {
            name: DIMENSION_ITEM_ID,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters(widgetIds),
      },
      // 1: Widget Impressions
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_ITEM_SOURCE_ID,
          },
          {
            name: DIMENSION_ITEM_SOURCE_STREAM_ID,
          },
          {
            name: DIMENSION_HOSTNAME,
          },
          {
            name: DIMENSION_ITEM_BRAND,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters(widgetIds, [
          FILTER_EVENT_VIEW_ITEM_IMPRESSIONS,
          FILTER_ITEM_CATEGORY_INFOBOARD,
        ]),
      },
      // 3: Close And Minimize
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters(widgetIds, [
          buildInListFilter(DIMENSION_EVENT_NAME, [
            EVENT_CLOSE_ITEM,
            EVENT_MINIMIZE_ITEM,
          ]),
          FILTER_ITEM_CATEGORY_INFOBOARD,
        ]),
      },
    ];
  }, [widgetSources, dateStrings]);

  const reportRequests02 = useMemo(() => {
    if (
      !dateStrings ||
      !dateStrings[0] ||
      !dateStrings[1] ||
      !widgetSources.length
    )
      return;
    let widgetIds = widgetSources.map((x) => x.id);
    const commonFilters = [];

    if (selectedSourceId) {
      commonFilters.push(
        buildStringFilter(DIMENSION_ITEM_SOURCE_ID, selectedSourceId)
      );
      widgetIds = [];
    }

    if (selectedSourceStreamId)
      commonFilters.push(
        buildStringFilter(
          DIMENSION_ITEM_SOURCE_STREAM_ID,
          selectedSourceStreamId
        )
      );

    if (selectedHostNameFull)
      commonFilters.push(
        buildStringFilter(DIMENSION_HOSTNAME, selectedHostNameFull)
      );

    if (selectedItemOwner)
      commonFilters.push(
        buildStringFilter(DIMENSION_ITEM_BRAND, selectedItemOwner)
      );
    if (selectedItemId)
      commonFilters.push(buildStringFilter(DIMENSION_ITEM_ID, selectedItemId));

    return [
      // 0: Audiences
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
        dimensionFilter: buildFilters(widgetIds, [
          buildInListFilter(DIMENSION_EVENT_NAME, [
            EVENT_VIEW_ITEM_CLICKED,
            EVENT_VIEW_ITEM_DETAILS,
            EVENT_CLOSE_ITEM,
            EVENT_MINIMIZE_ITEM,
          ]),
          ...commonFilters,
        ]),
      },
      // 1: Total users
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_HOSTNAME,
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
        dimensionFilter: buildFilters(widgetIds, [...commonFilters]),
      },
      // 2: Content Active Views
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
          {
            name: DIMENSION_ITEM_BRAND,
          },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters(widgetIds, [
          FILTER_EVENT_VIEW_ITEM_DETAILS,
          ...commonFilters,
        ]),
        limit: 100,
        offset: 0,
        orderBys: defaultOrderBys,
      },
      // 3: Top clicked contents
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_ID,
          },
          {
            name: DIMENSION_ITEM_BRAND,
          },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters(widgetIds, [
          FILTER_EVENT_VIEW_ITEM_CLICKED,
          ...commonFilters,
        ]),
        limit: 100,
        offset: 0,
        orderBys: defaultOrderBys,
      },
      // 4: Content View Impressions and Details
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_ITEM_SOURCE_ID,
          },
          {
            name: DIMENSION_ITEM_SOURCE_STREAM_ID,
          },
          {
            name: DIMENSION_HOSTNAME,
          },
          {
            name: DIMENSION_ITEM_BRAND,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
          {
            name: DIMENSION_ITEM_CATEGORY,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters(
          widgetIds,
          [...commonFilters],
          [FILTER_ITEM_CATEGORY_INFOBOARD]
        ),
      },
    ];
  }, [
    widgetSources,
    dateStrings,
    selectedSourceId,
    selectedSourceStreamId,
    selectedHostNameFull,
    selectedItemOwner,
    selectedItemId,
  ]);

  const resetData = () => {
    setReports01([]);
    setReports02([]);
  };

  useEffect(() => {
    if (!reportRequests01) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await getBatchReports(reportRequests01);
        setReports01(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        resetData();
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [reportRequests01]);

  useEffect(() => {
    if (!reportRequests02) return;

    const loadData = async () => {
      setIsLoading2(true);
      try {
        const data = await getBatchReports(reportRequests02);
        setReports02(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        resetData();
      } finally {
        setIsLoading2(false);
      }
    };

    loadData();
  }, [reportRequests02]);

  const widgetFilter = useCallback(
    (report, otherFilter) => {
      if (isEmpty(report)) return {};

      const rows = report.rows.filter((row) => {
        const matchWidgetSourceId = selectedSourceId
          ? row[DIMENSION_ITEM_SOURCE_ID] === selectedSourceId
          : true;
        const matchHostName = selectedHostName
          ? row[DIMENSION_HOSTNAME] === selectedHostName
          : true;
        const matchIgnoreHostNames = !ignoreHostNames.includes(
          row[DIMENSION_HOSTNAME]
        );
        const matchOtherFilter = otherFilter ? otherFilter(row) : true;

        return (
          matchWidgetSourceId &&
          matchHostName &&
          matchIgnoreHostNames &&
          matchOtherFilter
        );
      });

      return {
        ...report,
        rows,
      };
    },
    [selectedHostName, selectedSourceId]
  );

  const commonFilter = useCallback(
    (report, otherFilter) => {
      if (isEmpty(report)) return {};

      const rows = report.rows.filter((row) => {
        const matchWidgetSourceId = selectedSourceId
          ? row[DIMENSION_ITEM_SOURCE_ID] === selectedSourceId
          : true;
        const matchWidgetSourceStreamId = selectedSourceStreamId
          ? row[DIMENSION_ITEM_SOURCE_STREAM_ID] === selectedSourceStreamId
          : true;
        const matchHostName = selectedHostName
          ? row[DIMENSION_HOSTNAME] === selectedHostName
          : true;
        const matchItemOwner = selectedItemOwner
          ? row[DIMENSION_ITEM_BRAND] === selectedItemOwner
          : true;
        const matchItemId = selectedItemId
          ? row[DIMENSION_ITEM_ID] === selectedItemId
          : true;

        const matchIgnoreHostNames = !ignoreHostNames.includes(
          row[DIMENSION_HOSTNAME]
        );
        const matchOtherFilter = otherFilter ? otherFilter(row) : true;

        return (
          matchWidgetSourceId &&
          matchWidgetSourceStreamId &&
          matchHostName &&
          matchIgnoreHostNames &&
          matchOtherFilter &&
          matchItemOwner &&
          matchItemId
        );
      });

      return {
        ...report,
        rows,
      };
    },
    [
      selectedHostName,
      selectedItemOwner,
      selectedSourceId,
      selectedSourceStreamId,
      selectedItemId,
    ]
  );

  const filtersReportData = useMemo(() => {
    const report = reviseReport(reports01[0]);
    if (!isEmpty(report)) {
      return {
        ...report,
        rows: report.rows.filter(
          (row) => !ignoreHostNames.includes(row[DIMENSION_HOSTNAME])
        ),
      };
    }

    return report;
  }, [reports01]);

  useEffect(() => {
    if (!filtersReportData.rowCount) return setAvailableContents([]);

    const loadData = async () => {
      const contentIds = uniqBy(
        filtersReportData.rows.map((x) => x[DIMENSION_ITEM_ID])
      );
      const returnSyncedData = await getContentNames(contentIds);
      setAvailableContents(returnSyncedData);
    };
    loadData();
  }, [filtersReportData]);

  useEffect(() => {
    if (!filtersReportData.rowCount) return setAvailableWidgetSources([]);
    const loadData = async () => {
      const infoboardIds = uniqBy(
        filtersReportData.rows.map((x) => x[DIMENSION_ITEM_SOURCE_ID])
      );
      const result = widgetSources.filter((it) => infoboardIds.includes(it.id));
      const sources = orderBy(
        result.map((x) => ({
          value: x.id,
          label: x.name,
          domain: x.domain,
        })),
        ["label"]
      );
      setAvailableWidgetSources(sources);
    };

    loadData();
  }, [filtersReportData, widgetSources]);

  useEffect(() => {
    const loadData = async () => {
      const result = await getInfoboards(domain);
      const sources = orderBy(
        result.map((x) => ({
          id: x.id,
          name: x.name,
          domain: x.domain,
        })),
        ["label"]
      );
      setWidgetSources(sources);
    };

    loadData();
  }, [domain]);

  useEffect(() => {
    if (!filtersReportData.rowCount) return setAvailableWidgetSourceStreams([]);

    const loadData = async () => {
      const euIds = uniqBy(
        filtersReportData.rows
          .map((x) => x[DIMENSION_ITEM_SOURCE_STREAM_ID])
          .filter((x) => validate(x))
      );
      const result = euIds.length
        ? await getEmailuploadersByIds({ ids: euIds })
        : [];
      const sources = orderBy(
        result.map((x) => ({
          value: x.id,
          label: x.name,
        })),
        ["label"]
      );
      setAvailableWidgetSourceStreams(sources);
    };

    loadData();
  }, [filtersReportData]);

  // const availableWidgetSourceStreams = useMemo(() => {
  //   if (!filtersReportData.rowCount) return [];

  //   const widgetStreamsMap = reduce(
  //     filtersReportData.rows,
  //     (result, current) => {
  //       const matchSourceId = selectedSourceId
  //         ? current[DIMENSION_ITEM_SOURCE_ID] === selectedSourceId
  //         : true;
  //       if (
  //         current[DIMENSION_ITEM_SOURCE_STREAM_ID] &&
  //         matchSourceId &&
  //         current[DIMENSION_ITEM_SOURCE_STREAM_NAME] !== NOT_SET_DIMENSION_VALUE
  //       )
  //         result[current[DIMENSION_ITEM_SOURCE_STREAM_ID]] =
  //           current[DIMENSION_ITEM_SOURCE_STREAM_NAME];

  //       return result;
  //     },
  //     {}
  //   );
  //   return orderBy(
  //     Object.keys(widgetStreamsMap).map((key) => ({
  //       value: key,
  //       label: widgetStreamsMap[key],
  //     })),
  //     ["label"]
  //   );
  // }, [filtersReportData, selectedSourceId]);

  const widgetImpressionsReportData = useMemo(() => {
    return widgetFilter(reviseReport(reports01[1]));
  }, [widgetFilter, reports01]);

  const contentViewsReportData = useMemo(() => {
    // const otherFilter = (row) => !isGuid(row[DIMENSION_ITEM_ID]);
    const revisedReport = reviseReport(reports02[4]);
    revisedReport.rows = (revisedReport.rows || []).filter(
      (row) => row[DIMENSION_ITEM_CATEGORY] !== "infoboard"
    );
    return revisedReport;
  }, [reports02]);

  const widgetActionsReportData = useMemo(() => {
    return reviseReport(reports01[2]);
  }, [reports01]);

  const audiencesReportData = useMemo(() => {
    return reviseReport(reports02[0]);
  }, [reports02]);

  const usersReportData = useMemo(() => {
    return reviseReport(reports02[1]);
  }, [reports02]);

  const contentActiveViewsReportData = useMemo(() => {
    return reviseReport(reports02[2]);
  }, [reports02]);

  const contentClicksReportData = useMemo(() => {
    return reviseReport(reports02[3]);
  }, [reports02]);

  const availablePartners = useMemo(() => {
    if (!filtersReportData.rowCount) return [];

    return uniqBy(
      filtersReportData.rows.map((row) => ({
        [DIMENSION_HOSTNAME]: row[DIMENSION_HOSTNAME],
        [DIMENSION_HOSTNAME_FULL]: row[DIMENSION_HOSTNAME_FULL],
      })),
      DIMENSION_HOSTNAME
    );
  }, [filtersReportData]);

  const availableItemOwners = useMemo(() => {
    if (!filtersReportData.rowCount) return [];

    return uniqBy(
      filtersReportData.rows.map((row) => ({
        [DIMENSION_ITEM_BRAND]: row[DIMENSION_ITEM_BRAND],
      })),
      DIMENSION_ITEM_BRAND
    );
  }, [filtersReportData]);

  const availableItems = useMemo(() => {
    if (
      !filtersReportData.rowCount ||
      !availableContents ||
      !availableContents.length
    )
      return [];
    if (
      !selectedSourceId &&
      !selectedSourceStreamId &&
      !selectedHostName &&
      !selectedItemOwner
    )
      return availableContents || [];
    const filterItems = filtersReportData.rows.filter(
      (x) =>
        (!selectedSourceId ||
          selectedSourceId === x[DIMENSION_ITEM_SOURCE_ID]) &&
        (!selectedSourceStreamId ||
          selectedSourceStreamId === x[DIMENSION_ITEM_SOURCE_STREAM_ID]) &&
        (!selectedHostName || selectedHostName === x[DIMENSION_HOSTNAME]) &&
        (!selectedItemOwner || selectedItemOwner === x[DIMENSION_ITEM_BRAND])
    );
    const contentIds = uniqBy(filterItems.map((row) => row[DIMENSION_ITEM_ID]));
    const results = availableContents.filter((x) => contentIds.includes(x.id));
    if (!results.find((x) => x.id === selectedItemId)) {
      setSelectedItemId(null);
    }
    return results;
  }, [
    filtersReportData.rowCount,
    filtersReportData.rows,
    availableContents,
    selectedSourceId,
    selectedSourceStreamId,
    selectedHostName,
    selectedItemOwner,
    selectedItemId,
  ]);

  const contentViewsTimeSeriesData = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return [];

    const rowsGroupedByDate = contentViewsReportData.rows.reduce(
      (result, current) => {
        const date = current[DIMENSION_DATE];
        if (!result[date])
          result[date] = {
            [EVENT_VIEW_ITEM_DETAILS]: 0,
            [EVENT_VIEW_ITEM_IMPRESSION]: 0,
          };
        result[date].date = date;

        if (current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION)
          result[date][EVENT_VIEW_ITEM_IMPRESSION] = sum([
            result[date][EVENT_VIEW_ITEM_IMPRESSION],
            current[METRIC_EVENT_COUNT],
          ]);
        if (current[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS)
          result[date][EVENT_VIEW_ITEM_DETAILS] = sum([
            result[date][EVENT_VIEW_ITEM_DETAILS],
            current[METRIC_EVENT_COUNT],
          ]);

        return result;
      },
      {}
    );

    // return orderBy(Object.values(rowsGroupedByDate), [DIMENSION_DATE]);
    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [EVENT_VIEW_ITEM_IMPRESSION, EVENT_VIEW_ITEM_DETAILS],
    });
  }, [contentViewsReportData, dateStrings]);

  // Content Impressions

  const onSourceChange = useCallback(
    (value) => {
      setSelectedSourceId(value);
      setParam(PARAM_WIDGET_SOURCE, value);
      setSelectedSourceStreamId(null);
      removeParam(PARAM_WIDGET_STREAM);
    },
    [removeParam, setParam]
  );

  const onSourceStreamChange = useCallback(
    (value) => {
      setSelectedSourceStreamId(value);
      setParam(PARAM_WIDGET_STREAM, value);
    },
    [setParam]
  );

  const onHostChange = useCallback(
    (value, option) => {
      setSelectedHostName(value);
      setSelectedHostNameFull(option[DIMENSION_HOSTNAME_FULL]);
      setParam(PARAM_WIDGET_HOST, value);
    },
    [setParam]
  );

  const onItemOwnerChange = useCallback(
    (value, option) => {
      setSelectedItemOwner(value);
      setParam(PARAM_WIDGET_ITEM_OWNER, value);
    },
    [setParam]
  );

  const onContentChange = useCallback(
    (value, option) => {
      setSelectedItemId(value);
      setParam(PARAM_WIDGET_ITEM_ID, value);
    },
    [setParam]
  );

  const adImpressionsEnabled =
    !selectedSourceStreamId && !selectedItemOwner && !selectedItemId;
  const context = useMemo(() => {
    return {
      clicksEnabled: hasProductRight,
      adImpressionsEnabled,
    };
  }, [hasProductRight, adImpressionsEnabled]);

  return (
    <WidgetsProvider value={context}>
      <Spin spinning={isLoading || isLoading2}>
        <Row gutter={GRID_GUTTER} className="xl:justify-center">
          <Col xs={12} lg={6} xl={4} className="mb-8">
            {!isPrintMode ? (
              <Select
                value={selectedSourceId}
                onChange={onSourceChange}
                className="w-full"
                defaultValue={null}
              >
                <Select.Option value={null}>All widgets</Select.Option>
                {availableWidgetSources.map((widget) => (
                  <Select.Option value={widget.value} key={widget.value}>
                    {widget.label}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <p>
                <span className="font-semibold">Widget: </span>
                <span>
                  {availableWidgetSources.find(
                    (widgetSource) => widgetSource.value === selectedSourceId
                  )?.label || "All"}
                </span>
              </p>
            )}
          </Col>
          <Col xs={12} lg={6} xl={4} className="mb-8">
            {!isPrintMode ? (
              <Select
                value={selectedHostName}
                onChange={onHostChange}
                className="w-full"
                defaultValue={null}
              >
                <Select.Option value={null}>All partners</Select.Option>
                {availablePartners.map((item) => (
                  <Select.Option
                    value={item[DIMENSION_HOSTNAME]}
                    key={item[DIMENSION_HOSTNAME]}
                    hostNameFull={item[DIMENSION_HOSTNAME_FULL]}
                  >
                    <Space>
                      <Favicon url={item[DIMENSION_HOSTNAME_FULL]} />
                      {item[DIMENSION_HOSTNAME]}
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <p>
                <span className="font-semibold">Partner: </span>
                <span>
                  {availablePartners.find(
                    (widgetSource) =>
                      widgetSource[DIMENSION_HOSTNAME] === selectedHostName
                  )?.[DIMENSION_HOSTNAME] || "All"}
                </span>
              </p>
            )}
          </Col>
          <Col xs={12} lg={6} xl={4} className="mb-8">
            {!isPrintMode ? (
              <Select
                value={selectedSourceStreamId}
                onChange={onSourceStreamChange}
                className="w-full"
                defaultValue={null}
              >
                <Select.Option value={null}>All EUs</Select.Option>
                {availableWidgetSourceStreams.map((stream) => (
                  <Select.Option value={stream.value} key={stream.value}>
                    {stream.label}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <p>
                <span className="font-semibold">EU: </span>
                <span>
                  {availableWidgetSourceStreams.find(
                    (widgetSource) =>
                      widgetSource.value === selectedSourceStreamId
                  )?.label || "All"}
                </span>
              </p>
            )}
          </Col>
          <Col xs={12} lg={6} xl={4} className="mb-8">
            {!isPrintMode ? (
              <Select
                value={selectedItemOwner}
                onChange={onItemOwnerChange}
                className="w-full"
                defaultValue={null}
              >
                <Select.Option value={null}>All content owners</Select.Option>
                {availableItemOwners.map((item) => (
                  <Select.Option
                    value={item[DIMENSION_ITEM_BRAND]}
                    key={item[DIMENSION_ITEM_BRAND]}
                  >
                    <Space>
                      <Favicon url={item[DIMENSION_ITEM_BRAND]} />
                      {item[DIMENSION_ITEM_BRAND]}
                    </Space>
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <p>
                <span className="font-semibold">Owner: </span>
                <span>
                  {availablePartners.find(
                    (widgetSource) =>
                      widgetSource[DIMENSION_ITEM_BRAND] === selectedItemOwner
                  )?.[DIMENSION_ITEM_BRAND] || "All"}
                </span>
              </p>
            )}
          </Col>
          <Col xs={12} lg={6} xl={4} className="mb-8">
            {!isPrintMode ? (
              <Select
                value={selectedItemId}
                onChange={onContentChange}
                className="w-full"
                defaultValue={null}
              >
                <Select.Option value={null}>All contents</Select.Option>
                {availableItems.map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    <Space>{item.name || item.title}</Space>
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <p>
                <span className="font-semibold">Content: </span>
                <span>
                  {availableContents.find((it) => it.id === selectedItemId)
                    ?.name || "All"}
                </span>
              </p>
            )}
          </Col>
        </Row>

        <OverviewSection
          domain={domain}
          widgetImpressionsReportData={widgetImpressionsReportData}
          widgetActionsReportData={widgetActionsReportData}
          contentViewsReportData={contentViewsReportData}
          audiencesReportData={audiencesReportData}
          usersReportData={usersReportData}
          contentViewsTimeSeriesData={contentViewsTimeSeriesData}
          dateStrings={dateStrings}
        />
        <Divider />

        <ContentActiveViewsSection
          contentActiveViewsReportData={contentActiveViewsReportData}
          contentViewsTimeSeriesData={contentViewsTimeSeriesData}
        />

        {hasProductRight && (
          <>
            <Divider />
            <ContentClicksSection
              contentClicksReportData={contentClicksReportData}
            />
          </>
        )}

        {adImpressionsEnabled && (
          <>
            <Divider />
            <AdImpressionsByPartnersSection
              widgetImpressionsReportData={widgetImpressionsReportData}
              dateStrings={dateStrings}
            />
          </>
        )}

        {!HIDE_VISITORS && (
          <>
            <Divider />
            <UsersByPartnersSection
              dateStrings={dateStrings}
              usersReportData={usersReportData}
            />
          </>
        )}
        <Divider />
        <ContentActiveViewsByPartnersSection
          dateStrings={dateStrings}
          contentViewsReportData={contentViewsReportData}
        />

        {hasProductRight && (
          <>
            <Divider />
            <ContentClicksByPartnersSection
              dateStrings={dateStrings}
              contentViewsReportData={contentViewsReportData}
            />
          </>
        )}

        <Divider />
        <DailyTrafficSection
          dateStrings={dateStrings}
          widgetImpressionsReportData={widgetImpressionsReportData}
          contentViewsReportData={contentViewsReportData}
          usersReportData={usersReportData}
        />
      </Spin>
    </WidgetsProvider>
  );
};

Widgets.propTypes = {};

export default Widgets;
