import PropTypes from "prop-types";
import React, { useContext } from "react";
import { noop } from "lodash";

const TriggerTextsContext = React.createContext({
  summaryReport: null,
  contentData: null,
  partnerFilter: null,
  contentOwner: null,
  setPartnerFilter: noop,
  setContentOwner: noop,
});
TriggerTextsContext.displayName = "TriggerTextsContext";

export const useTriggerTextsContext = () => useContext(TriggerTextsContext);

export default function TriggerTextsContextProvider({ children, value }) {
  return (
    <TriggerTextsContext.Provider value={value}>
      {children}
    </TriggerTextsContext.Provider>
  );
}

TriggerTextsContextProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.object,
};
