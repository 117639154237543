import {
  TAG_BOOKMARK,
  TAG_DOMAIN,
  TAG_FACEBOOK,
  TAG_RSS,
  TAG_HIGHLIGHT,
  TAG_LIST,
  TAG_LISTSET,
  TAG_TWITTER,
  TAG_LISTGROUP_CONNECTION,
  TAG_YOUTUBE,
  TAG_PINTEREST,
  TAG_LINKEDIN,
  TAG_INSTAGRAM,
} from "constants/contentTags";
import { kebabCase } from "lodash";
import Favicon from "components/Favicon";

const ContentLink = ({ tag, id, name, ownerDomain, children }) => {
  const seoText = kebabCase(name);
  const webHost = process.env.REACT_APP_PLUGILO_WEB_HOST;
  let url = null;

  if (!tag || !id) return null;

  switch (tag) {
    case TAG_DOMAIN:
      url = `${webHost}/companies/${id}/${seoText}`;
      break;
    case TAG_BOOKMARK:
      url = `${webHost}/bookmarks/${id}/${seoText}`;
      break;
    case TAG_HIGHLIGHT:
      url = `${webHost}/highlights/${id}/${seoText}`;
      break;
    case TAG_RSS:
      url = `${webHost}/news/${id}/${seoText}`;
      break;
    case TAG_LISTSET:
      url = `${webHost}/stacks/${id}/${seoText}`;
      break;
    case TAG_LIST:
      url = `${webHost}/stacks/${id}/${seoText}`;
      break;
    case TAG_LISTGROUP_CONNECTION:
      url = `${webHost}/listgroupconnections/${id}/${seoText}`;
      break;
    case TAG_TWITTER:
    case TAG_FACEBOOK:
    case TAG_YOUTUBE:
    case TAG_PINTEREST:
    case TAG_LINKEDIN:
    case TAG_INSTAGRAM:
      url = `${webHost}/feeds/${id}/${seoText}`;
      break;
    default:
      break;
  }

  if (!url) return null;

  return (
    <a href={url} target="_blank" rel="noreferrer">
      {children ? (
        children
      ) : (
        <>
          <Favicon url={ownerDomain} className="mr-2" />
          <span>{name || id}</span>
        </>
      )}
    </a>
  );
};

ContentLink.propTypes = {};

ContentLink.defaultProps = {};

export default ContentLink;
