import PropTypes from "prop-types";
import React, { useContext } from "react";

const WidgetsContext = React.createContext({
  clicksEnabled: false,
  adImpressionsEnabled: true,
});
WidgetsContext.displayName = "WidgetsContext";

export const useWidgetsContext = () => useContext(WidgetsContext);

export default function WidgetsProvider({ children, value }) {
  return (
    <WidgetsContext.Provider value={value}>{children}</WidgetsContext.Provider>
  );
}

WidgetsProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.object,
};
