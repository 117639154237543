import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

function useQueryString() {
  const { search } = useLocation();

  return useMemo(
    () =>
      queryString.parse(search, {
        arrayFormat: "comma",
      }),
    [search]
  );
}

export default useQueryString;
