import { createContext, useContext, useMemo } from "react";

const ReportContext = createContext({});

export const useReportContext = () => useContext(ReportContext);

export default function ReportContextProvider({ children, value }) {
  const contextValue = useMemo(() => {
    return {
      ...value,
    };
  }, [value]);

  return (
    <ReportContext.Provider value={contextValue}>
      {children}
    </ReportContext.Provider>
  );
}
