import React, { memo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Favicon = memo(({ title, url, className, size, src }) => {
  const imageRef = useRef();
  const dynamicSrc = `https://www.google.com/s2/favicons?sz=${size}&domain=https://www.${url}`;

  const clearSrc = () => {
    if (imageRef.current) imageRef.current.src = "";
  };

  useEffect(() => {
    if (imageRef.current) imageRef.current.src = dynamicSrc;
  }, [dynamicSrc]);

  useEffect(() => {
    return () => {
      clearSrc();
    };
  }, []);

  return (
    <img
      className={classNames(className, "inline rounded-sm")}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
      alt=""
      ref={imageRef}
      src={dynamicSrc}
    />
  );
});

Favicon.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
  src: PropTypes.string,
};

Favicon.defaultProps = {
  className: null,
  size: 16,
};

export default Favicon;
