import PropTypes from "prop-types";
  
  const LinkToDetails = ({children, tag, id }) => {
    const webHost = process.env.REACT_APP_PLUGILO_WEB_HOST;  
   const url = `${webHost}/content-analytics/${id}/${tag}`
    return (
      <a href={url} rel="noreferrer" target="_blank">
       {children}
      </a>
    );
  };
  
  LinkToDetails.propTypes = {
    children: PropTypes.any,
    tag: PropTypes.string,
    id: PropTypes.string
  };
  
  LinkToDetails.defaultProps = {};
  
  export default LinkToDetails;
  