import React, { useMemo } from "react";
import ChartTitle from "components/ChartTitle";
import { Card, Col, Row, Statistic, Tooltip } from "antd";
import { GRID_GUTTER, HIDE_VISITORS, LABEL_INACTIVE } from "constants/ui";
import {
  EVENT_CLOSE_ITEM,
  EVENT_MINIMIZE_ITEM,
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import { METRIC_EVENT_COUNT, METRIC_TOTAL_USERS } from "constants/metrics";
import Scorecard from "components/charts/Scorecard";
import { isEmpty, sum } from "lodash";
import { DIMENSION_DATE, DIMENSION_EVENT_NAME } from "constants/dimensions";
import { reviseTimeSeriesData } from "services/gaService";
import { DIMENSION_ITEM_CATEGORY } from "constants/customDimensions";
import LineChart from "components/charts/LineChart";
import TooltipText from "components/TooltipText";
import { useTriggerTextsContext } from "../../TriggerTextsContext";

const OverviewSection = () => {
  const {
    triggerTextViewsReportData,
    audiencesReportData,
    usersReportData,
    triggerTextViewsTimeSeriesData,
    dateStrings,
    clicksEnabled,
  } = useTriggerTextsContext();

  const triggerTextImpressionTimeSeriesData = useMemo(() => {
    if (isEmpty(triggerTextViewsReportData)) return [];

    const rowsGroupedByDate = triggerTextViewsReportData.rows
      .filter((row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION)
      .reduce((result, current) => {
        const date = current[DIMENSION_DATE];
        if (!result[date]) result[date] = {};
        result[date].date = date;
        result[date][EVENT_VIEW_ITEM_IMPRESSION] = sum([
          result[date][EVENT_VIEW_ITEM_IMPRESSION],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      }, {});

    // return orderBy(Object.values(rowsGroupedByDate), [DIMENSION_DATE]);
    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [EVENT_VIEW_ITEM_IMPRESSION],
    });
  }, [dateStrings, triggerTextViewsReportData]);

  const usersTimeSeriesData = useMemo(() => {
    if (isEmpty(usersReportData)) return [];

    const rowsGroupedByDate = usersReportData.rows.reduce((result, current) => {
      const date = current[DIMENSION_DATE];
      if (!result[date]) result[date] = {};
      result[date].date = date;
      result[date][METRIC_TOTAL_USERS] = sum([
        result[date][METRIC_TOTAL_USERS],
        current[METRIC_TOTAL_USERS],
      ]);
      return result;
    }, {});

    return reviseTimeSeriesData(Object.values(rowsGroupedByDate), {
      dateKey: DIMENSION_DATE,
      startDate: dateStrings[0],
      endDate: dateStrings[1],
      dataKeys: [METRIC_TOTAL_USERS],
    });
  }, [dateStrings, usersReportData]);

  // NOTE: because GA doesn't provide get OR and AND filters in parallel, so we have to merge data here
  const triggerTextImpressionWithUsersTrendTimeSeriesData = useMemo(() => {
    if (
      isEmpty(triggerTextImpressionTimeSeriesData) &&
      isEmpty(usersTimeSeriesData)
    )
      return [];

    const dataLength =
      triggerTextImpressionTimeSeriesData.length || usersTimeSeriesData.length;

    return Array(dataLength)
      .fill()
      .map((_, index) => {
        const triggerTextItem =
          triggerTextImpressionTimeSeriesData[index] || {};
        const userItem = usersTimeSeriesData[index] || {};
        return {
          ...triggerTextItem,
          [METRIC_TOTAL_USERS]: userItem[METRIC_TOTAL_USERS] || 0,
        };
      });
  }, [triggerTextImpressionTimeSeriesData, usersTimeSeriesData]);

  const triggerTextImpressionCount = useMemo(() => {
    if (isEmpty(triggerTextImpressionTimeSeriesData)) return 0;

    return sum(
      triggerTextImpressionTimeSeriesData.map(
        (item) => item[EVENT_VIEW_ITEM_IMPRESSION]
      )
    );
  }, [triggerTextImpressionTimeSeriesData]);

  const contentActiveViewCount = useMemo(() => {
    if (isEmpty(triggerTextViewsReportData)) return 0;

    return sum(
      triggerTextViewsReportData.rows
        .filter((row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS)
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [triggerTextViewsReportData]);

  // const contentImpressionCount = useMemo(() => {
  //   if (isEmpty(triggerTextViewsReportData)) return 0;

  //   return sum(
  //     triggerTextViewsReportData.rows
  //       .filter(
  //         (row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION
  //       )
  //       .map((row) => row[METRIC_EVENT_COUNT])
  //   );
  // }, [triggerTextViewsReportData]);

  const contentClickCount = useMemo(() => {
    if (isEmpty(triggerTextViewsReportData)) return 0;

    return sum(
      triggerTextViewsReportData.rows
        .filter((row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED)
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [triggerTextViewsReportData]);

  const usersHaveActiveViewsCount = useMemo(() => {
    if (isEmpty(audiencesReportData)) return 0;

    return sum(
      audiencesReportData.rows
        .filter((row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS)
        .map((row) => row[METRIC_TOTAL_USERS])
    );
  }, [audiencesReportData]);

  const usersHaveClickCount = useMemo(() => {
    if (isEmpty(audiencesReportData)) return 0;

    return sum(
      audiencesReportData.rows
        .filter((row) => row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED)
        .map((row) => row[METRIC_TOTAL_USERS])
    );
  }, [audiencesReportData]);

  const totalUsers = useMemo(() => {
    if (isEmpty(usersReportData)) return 0;

    return sum(usersReportData.rows.map((row) => row[METRIC_TOTAL_USERS]));
  }, [usersReportData]);

  const clickThroughRate = useMemo(() => {
    return contentClickCount && triggerTextImpressionCount
      ? (contentClickCount / triggerTextImpressionCount) * 100
      : 0;
  }, [contentClickCount, triggerTextImpressionCount]);

  const userClickThroughRate = useMemo(() => {
    return contentClickCount && totalUsers
      ? (contentClickCount / totalUsers) * 100
      : 0;
  }, [contentClickCount, totalUsers]);

  const activeViewRate = useMemo(() => {
    return contentActiveViewCount && triggerTextImpressionCount
      ? (contentActiveViewCount / triggerTextImpressionCount) * 100
      : 0;
  }, [contentActiveViewCount, triggerTextImpressionCount]);

  const userActiveViewRate = useMemo(() => {
    return contentActiveViewCount && totalUsers
      ? (contentActiveViewCount / totalUsers) * 100
      : 0;
  }, [contentActiveViewCount, totalUsers]);

  // const userMinimizeWidgetRate = useMemo(() => {
  //   return minimizedWidgetCount && totalUsers
  //     ? (minimizedWidgetCount / totalUsers) * 100
  //     : 0;
  // }, [minimizedWidgetCount, totalUsers]);

  // const userCloseWidgetRate = useMemo(() => {
  //   return closedWidgetCount && totalUsers
  //     ? (closedWidgetCount / totalUsers) * 100
  //     : 0;
  // }, [closedWidgetCount, totalUsers]);

  return (
    <>
      <ChartTitle>plugilo Widget · Trends</ChartTitle>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <Card size="small">
            <LineChart
              xAxisDataKey="date"
              data={triggerTextImpressionWithUsersTrendTimeSeriesData}
              lines={[
                {
                  name: "Trigger Text Impressions",
                  dataKey: EVENT_VIEW_ITEM_IMPRESSION,
                },
                ...(!HIDE_VISITORS?
                [{
                  name: "Visitors",
                  dataKey: METRIC_TOTAL_USERS,
                }]:[]),
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Row gutter={GRID_GUTTER}>
            <Col span={12} className="mb-8">
              <Tooltip
                title={
                  <TooltipText>
                    The "Trigger Text Impressions" shows the on-screen display
                    of the plugilo plug and trigger text.
                  </TooltipText>
                }
              >
                <Card size="small">
                  <Scorecard
                    title="Trigger Text Impressions"
                    value={triggerTextImpressionCount || LABEL_INACTIVE}
                    chartProps={{
                      data: triggerTextImpressionTimeSeriesData,
                      lines: [
                        {
                          name: "Trigger Text Impressions",
                          dataKey: EVENT_VIEW_ITEM_IMPRESSION,
                        },
                      ],
                    }}
                  />
                </Card>
              </Tooltip>
            </Col>
            {/* <Col span={12} className="mb-8">
              <Tooltip
                title={
                  <TooltipText>
                    The "Content Visibility" shows all content that was listed
                    and visible in the plugilo widget.
                  </TooltipText>
                }
              >
                <Card size="small">
                  <Scorecard
                    title="Content Visibility"
                    value={contentImpressionCount || LABEL_INACTIVE}
                    chartProps={{
                      data: triggerTextViewsTimeSeriesData,
                      lines: [
                        {
                          name: "Content Visibility",
                          dataKey: EVENT_VIEW_ITEM_IMPRESSION,
                        },
                      ],
                    }}
                  />
                </Card>
              </Tooltip>
            </Col> */}
          </Row>
          {!HIDE_VISITORS &&
          <div className="mb-8">
            <Card size="small">
              <Row>
                <Col span={12} lg={8} className="mb-8 lg:mb-0">
                  <Tooltip
                    title={
                      <TooltipText>
                        The "Visitors" show the unique visitors who saw the
                        plugilo widget.
                      </TooltipText>
                    }
                  >
                    <Statistic
                      title="Visitors"
                      value={totalUsers || LABEL_INACTIVE}
                    />
                  </Tooltip>
                </Col>
                <Col span={12} lg={8} className="mb-8 lg:mb-0">
                  <Tooltip
                    title={
                      <TooltipText>
                        The “Visitors with AVs” shows all unique visitors who
                        have an active view window open!
                      </TooltipText>
                    }
                  >
                    <Statistic
                      title="Visitors with AVs"
                      value={usersHaveActiveViewsCount || LABEL_INACTIVE}
                    />
                  </Tooltip>
                </Col>
                {/* <Col span={12} lg={8}>
                  <Tooltip
                    title={
                      <TooltipText>
                        The "Visitors with Clicks" shows all unique visitors who
                        also clicked on the content after the active view!
                      </TooltipText>
                    }
                  >
                    <Statistic
                      title="Visitors with Clicks"
                      value={usersHaveClickCount || LABEL_INACTIVE}
                    />
                  </Tooltip>
                </Col> */}
              </Row>
            </Card>
          </div>
          }

          <div className="mb-8">
            <Card size="small">
              <Row>
                <Col span={12} lg={8} className="mb-8 lg:mb-0">
                  <Tooltip
                    title={
                      <TooltipText>
                        An "Active View* presents the entire content of the
                        advertiser in a window by mouse over or click and is
                        measured after a certain dwell time. A card, a stack, a
                        detailed newsletter, PDF or even a complete homepage or
                        website can be displayed.
                      </TooltipText>
                    }
                  >
                    <Statistic
                      title="Active Views"
                      value={contentActiveViewCount || LABEL_INACTIVE}
                    />
                  </Tooltip>
                </Col>
                <Col span={12} lg={8} className="mb-8 lg:mb-0">
                  <Tooltip
                    title={
                      <TooltipText>
                        The “AV Rate” shows the number of active views in
                        relation to the total ad impressions.
                      </TooltipText>
                    }
                  >
                    <Statistic
                      title="AV Rate"
                      value={activeViewRate || LABEL_INACTIVE}
                      precision={2}
                      suffix="%"
                    />
                  </Tooltip>
                </Col>
                {!HIDE_VISITORS &&
                <Col span={12} lg={8}>
                  <Tooltip
                    title={
                      <TooltipText>
                        The “AV Visitor Rate” shows the number of active views
                        in relation to the total unique visitors.
                      </TooltipText>
                    }
                  >
                    <Statistic
                      title="AV Visitor Rate"
                      value={userActiveViewRate || LABEL_INACTIVE}
                      precision={2}
                      suffix="%"
                    />
                  </Tooltip>
                </Col>
                }
              </Row>
            </Card>
          </div>
        </Col>
      </Row>
      {/* 
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8 lg:mb-0">
          <Row gutter={GRID_GUTTER}>
            <Col span={12}>
              <Card className="mb-8" size="small">
                <Row>
                  <Col span={12} lg={12} className="mb-8 lg:mb-0">
                    <Statistic
                      title="Close Ad"
                      value={closedWidgetCount || LABEL_INACTIVE}
                    />
                  </Col>
                  <Col span={12} lg={12} className="mb-8 lg:mb-0">
                    <Statistic
                      title="Close Visitor Rate"
                      value={userCloseWidgetRate || LABEL_INACTIVE}
                      precision={2}
                      suffix="%"
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card className="mb-8" size="small">
                <Row>
                  <Col span={12} lg={12} className="mb-8 lg:mb-0">
                    <Statistic
                      title="Minimize Ad"
                      value={minimizedWidgetCount || LABEL_INACTIVE}
                    />
                  </Col>
                  <Col span={12} lg={12} className="mb-8 lg:mb-0">
                    <Statistic
                      title="Minimize Visitor Rate"
                      value={userMinimizeWidgetRate || LABEL_INACTIVE}
                      precision={2}
                      suffix="%"
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        {clicksEnabled && (
          <Col xs={24} lg={12}>
            <div className="mb-8">
              <Card className="mb-8" size="small">
                <Row>
                  <Col span={12} lg={8} className="mb-8 lg:mb-0">
                    <Tooltip
                      title={
                        <TooltipText>
                          The “Clicks” show all clicks on the content that are
                          displayed in the active views and redirected to the
                          advertiser's content. Only the clicks on content
                          hosted by plugilo can be measured and reported. Clicks
                          within websites in the active view window cannot be
                          evaluated. Usually there are many more clicks that we
                          cannot measure.
                        </TooltipText>
                      }
                    >
                      <Statistic title="Clicks" value={contentClickCount} />
                    </Tooltip>
                  </Col>
                  <Col span={12} lg={8} className="mb-8 lg:mb-0">
                    <Tooltip
                      title={
                        <TooltipText>
                          The “CTR” is click-through rate and shows the number
                          of clicks in relation to the total Ad impressions.
                        </TooltipText>
                      }
                    >
                      <Statistic
                        title="CTR"
                        value={clickThroughRate}
                        precision={2}
                        suffix="%"
                      />
                    </Tooltip>
                  </Col>
                  <Col span={12} lg={8}>
                    <Tooltip
                      title={
                        <TooltipText>
                          The “CTR Visitor Rate” shows the number of clicks in
                          relation to the total unique visitors.
                        </TooltipText>
                      }
                    >
                      <Statistic
                        title="CTR Visitor Rate"
                        value={userClickThroughRate}
                        precision={2}
                        suffix="%"
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        )}
      </Row> */}
    </>
  );
};

OverviewSection.propTypes = {};

export default OverviewSection;
