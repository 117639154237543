export const clientClickSourcesMap = {
  DCIDailyHighlight: {
    order: 2,
    name: "DCI Highlights - Daily",
    key: "dcidailyhighlight",
  },
  DCIWeeklyHighlight: {
    order: 3,
    name: "DCI Highlights - Weekly",
    key: "dciweeklyhighlight",
  },
  ProConnectWeekly: {
    order: 4,
    name: "ProConnect - Weekly",
    key: "proconnectweekly",
  },
  ResellerWeeklyund: {
    order: 5,
    name: "Reseller Direct - Weekly",
    key: "resellerweeklyund",
  },
  plugiloPortal: {
    order: 6,
    name: "plugilo Portal",
    key: "plugiloportal",
  },
  plugiloWidget: {
    order: 7,
    name: "plugilo Widget",
    key: "plugilowidget",
  },
  Invitation: {
    order: 8,
    name: "Invitation",
    key: "invitation",
  },
};
