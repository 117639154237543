import React, { useEffect, useMemo, useState } from "react";
import { METRIC_TOTAL_USERS } from "constants/metrics";
import {
  DIMENSION_BROWSER,
  DIMENSION_DEVICE_CATEGORY,
  DIMENSION_OPERATING_SYSTEM,
  DIMENSION_SCREEN_RESOLUTION,
} from "constants/dimensions";
import { get, isEmpty, orderBy } from "lodash";
import { Card, Col, Row, Spin } from "antd";
import { GRID_GUTTER } from "constants/ui";
import { getBatchReports, reviseReport } from "services/gaService";
import PieChart from "components/charts/PieChart";
import VerticalBarChart from "components/charts/BarChart/VerticalBarChart";
import ChartTitle from "components/ChartTitle";
import { useReportContext } from "components/ReportContext/ReportContextProvider";
import { alertUnknownError } from "services/notificationService";

const TechOverview = () => {
  const [batchReports, setBatchReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { dateStrings } = useReportContext();

  const batchReportRequests = useMemo(() => {
    if (!dateStrings || !dateStrings[0] || !dateStrings[1]) return;

    return [
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_OPERATING_SYSTEM,
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
      },
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DEVICE_CATEGORY,
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
      },
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_BROWSER,
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
        offset: 0,
        limit: 10,
      },
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_SCREEN_RESOLUTION,
          },
        ],
        metrics: [
          {
            name: METRIC_TOTAL_USERS,
          },
        ],
        offset: 0,
        limit: 10,
      },
    ];
  }, [dateStrings]);

  useEffect(() => {
    if (!batchReportRequests) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const batchData01 = await getBatchReports(batchReportRequests);
        setBatchReports(get(batchData01, "reports", []));
      } catch (error) {
        alertUnknownError();
        setBatchReports([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [batchReportRequests]);

  const usersByOperatingSystemReportData = useMemo(() => {
    return reviseReport(batchReports[0]);
  }, [batchReports]);

  const usersByDeviceCategoryData = useMemo(() => {
    return reviseReport(batchReports[1]);
  }, [batchReports]);

  const usersByBrowserReportData = useMemo(() => {
    return reviseReport(batchReports[2]);
  }, [batchReports]);

  const usersByScreenResolutionReportData = useMemo(() => {
    return reviseReport(batchReports[3]);
  }, [batchReports]);

  const usersByOperatingSystemPieChartData = useMemo(() => {
    if (isEmpty(usersByOperatingSystemReportData)) return [];

    return orderBy(
      usersByOperatingSystemReportData.rows,
      [METRIC_TOTAL_USERS],
      ["desc"]
    ).slice(0, 10);
  }, [usersByOperatingSystemReportData]);

  const usersByDeviceCategoryPieChartData = useMemo(() => {
    if (isEmpty(usersByDeviceCategoryData)) return [];

    return orderBy(
      usersByDeviceCategoryData.rows,
      [METRIC_TOTAL_USERS],
      ["desc"]
    );
  }, [usersByDeviceCategoryData]);

  const usersByBrowserBarChartData = useMemo(() => {
    if (isEmpty(usersByBrowserReportData)) return [];

    return orderBy(
      usersByBrowserReportData.rows,
      [METRIC_TOTAL_USERS],
      ["desc"]
    );
  }, [usersByBrowserReportData]);

  const usersByScreenResolutionBarChartData = useMemo(() => {
    if (isEmpty(usersByScreenResolutionReportData)) return [];

    return orderBy(
      usersByScreenResolutionReportData.rows,
      [METRIC_TOTAL_USERS],
      ["desc"]
    );
  }, [usersByScreenResolutionReportData]);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Users By Operating System</ChartTitle>
          <Card size="small">
            <div className="h-80">
              <PieChart
                data={usersByOperatingSystemPieChartData}
                dataKey={METRIC_TOTAL_USERS}
                nameKey={DIMENSION_OPERATING_SYSTEM}
              />
            </div>
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Users By Device Category</ChartTitle>
          <Card size="small">
            <div className="h-80">
              <PieChart
                data={usersByDeviceCategoryPieChartData}
                dataKey={METRIC_TOTAL_USERS}
                nameKey={DIMENSION_DEVICE_CATEGORY}
              />
            </div>
          </Card>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Users By Browser</ChartTitle>
          <Card size="small">
            <VerticalBarChart
              yAxisDataKey={DIMENSION_BROWSER}
              data={usersByBrowserBarChartData}
              bars={[
                {
                  name: "Total Users",
                  dataKey: METRIC_TOTAL_USERS,
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Users By Screen Resolution</ChartTitle>
          <Card size="small">
            <VerticalBarChart
              yAxisDataKey={DIMENSION_SCREEN_RESOLUTION}
              data={usersByScreenResolutionBarChartData}
              bars={[
                {
                  name: "Total Users",
                  dataKey: METRIC_TOTAL_USERS,
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

TechOverview.propTypes = {};

export default TechOverview;
