import React, { useEffect, useMemo, useState } from "react";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { DIMENSION_DATE, DIMENSION_EVENT_NAME } from "constants/dimensions";
import {
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_SOURCE,
  DIMENSION_ITEM_SOURCE_ID,
  DIMENSION_ITEM_URL,
  DIMENSION_ITEM_URL1,
  DIMENSION_ITEM_URL2,
  DIMENSION_ITEM_SOURCE_NAME,
  DIMENSION_ITEM_CATEGORY2,
} from "constants/customDimensions";
import { get, isArray, orderBy, sum, isEmpty } from "lodash";
import { Card, Col, Row, Space, Spin, Table, Typography, Tooltip } from "antd";
import { GRID_GUTTER } from "constants/ui";
import {
  getBatchReports,
  getDecompressedUrl,
  reviseReport,
} from "services/gaService";
import {
  buildInListFilter,
  buildStringFilter,
  FILTER_ITEM_SOURCE_EMAIL,
} from "services/gaDimensionsService";
import {
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_EMAIL_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
  EVENT_VIEW_ITEM_LINK_CLICKED,
} from "constants/customEvents";
import { useParams } from "react-router";
import TableChart from "components/charts/TableChart";
import ContentLink from "components/ContentLink";
import ChartTitle from "components/ChartTitle";
import { formatNumber } from "services/numberService";
import useQueryString from "hooks/useQueryString";
import moment from "moment";
import { getContent, getPlugiloReport } from "services/gaService";
import PieChart from "components/charts/PieChart";
import { DATE_FORMAT } from "constants/default";
import EmailPreview from "./components/EmailPreview";
import Text from "antd/lib/typography/Text";
import { gray300, sky300 } from "constants/colors";
import {
  PARAM_CONTENT_TYPE,
  PARAM_MERGED_IDS,
} from "constants/queryStringParams";
import {
  SOURCE_EMAIL,
  SOURCE_PLUGILO,
  SOURCE_WIDGET,
} from "constants/contentSources";
import Scorecard from "components/charts/Scorecard";
import Icon from "components/Icon";
import { alertUnknownError } from "services/notificationService";
import { useAppContext } from "contexts/AppContextProvider";

const partnerSourceNames = ["ProConnectWeekly", "ResellerWeeklyund"];
const sourceMap = {
  PremiumEmail: {
    order: 1,
    name: "DCI Premium Email",
  },
  DCIDailyHighlight: {
    order: 2,
    name: "DCI Highlights - Daily",
  },
  DCIWeeklyHighlight: {
    order: 3,
    name: "DCI Highlights - Weekly",
  },
  ProConnectWeekly: {
    order: 4,
    name: "ProConnect - Weekly",
  },
  ResellerWeeklyund: {
    order: 5,
    name: "Reseller Direct - Weekly",
  },
  Invitation: {
    order: 6,
    name: "Invitation",
    key: "invitation",
  },
  default: {
    order: 1000,
  },
};

const buildFilters = (filters, notFilters) => {
  return {
    andGroup: {
      expressions: [...(filters || [])],
    },
  };
};

const EmailCampaign = () => {
  const [reports, setReports] = useState([]);
  const [contentData, setContentData] = useState({});
  const [plugiloReport, setPlugiloReport] = useState();
  const [dateStrings, setDateStrings] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { id: contentId } = useParams();
  const { isAdmin } = useAppContext();
  const { [PARAM_CONTENT_TYPE]: contentType, [PARAM_MERGED_IDS]: mergeIds } =
    useQueryString();
  const sentCount = get(plugiloReport, "statistics.sent");

  const contentIds = useMemo(() => {
    const refinedMergedIds = !isEmpty(mergeIds)
      ? isArray(mergeIds)
        ? mergeIds
        : [mergeIds]
      : [];
    return [contentId, ...refinedMergedIds];
  }, [mergeIds, contentId]);

  const reportRequests = useMemo(() => {
    if (isEmpty(dateStrings) || !dateStrings[0] || !dateStrings[1]) return;

    return [
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_DATE,
          },
          {
            name: DIMENSION_ITEM_CATEGORY2,
          },
          {
            name: DIMENSION_ITEM_SOURCE,
          },
          {
            name: DIMENSION_ITEM_SOURCE_NAME,
          },
          {
            name: DIMENSION_EVENT_NAME,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          buildInListFilter(DIMENSION_ITEM_ID, contentIds),
        ]),
      },
      {
        dateRanges: [
          {
            startDate: dateStrings[0],
            endDate: dateStrings[1],
          },
        ],
        dimensions: [
          {
            name: DIMENSION_ITEM_URL,
          },
          {
            name: DIMENSION_ITEM_URL1,
          },
          {
            name: DIMENSION_ITEM_URL2,
          },
        ],
        metrics: [
          {
            name: METRIC_EVENT_COUNT,
          },
        ],
        dimensionFilter: buildFilters([
          buildInListFilter(DIMENSION_EVENT_NAME, [
            EVENT_VIEW_ITEM_CLICKED,
            EVENT_VIEW_ITEM_LINK_CLICKED,
          ]),
          buildInListFilter(DIMENSION_ITEM_SOURCE_ID, contentIds),
        ]),
      },
    ];
  }, [contentIds, dateStrings]);

  useEffect(() => {
    if (!reportRequests) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const data = await getBatchReports(reportRequests);
        setReports(get(data, "reports", []));
      } catch (error) {
        alertUnknownError();
        setReports([]);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [reportRequests]);

  useEffect(() => {
    if (!contentId) return;

    const loadData = async () => {
      setIsLoading(true);
      try {
        const contentData = await getContent(contentId, contentType);
        const pReport = await getPlugiloReport(contentId);
        if (contentData) {
          setContentData(contentData);
          setDateStrings([
            moment(contentData.createdDate).format(DATE_FORMAT),
            "today",
          ]);
        }
        setPlugiloReport(pReport);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  const commonReportData = useMemo(() => {
    return reviseReport(reports[0]);
  }, [reports]);

  const contentLinkClicksReportData = useMemo(() => {
    return reviseReport(reports[1]);
  }, [reports]);

  const viewsBySources = useMemo(() => {
    if (isEmpty(commonReportData)) return [];

    const rowsGroupedByCategories = commonReportData.rows
      .filter(
        (row) =>
          [EVENT_VIEW_ITEM_EMAIL_DETAILS, EVENT_VIEW_ITEM_IMPRESSION].includes(
            row[DIMENSION_EVENT_NAME]
          ) && row[DIMENSION_ITEM_SOURCE] === SOURCE_EMAIL
      )
      .reduce((result, current) => {
        const category2 = current[DIMENSION_ITEM_CATEGORY2];
        const partnerSourceName = partnerSourceNames.includes(
          current[DIMENSION_ITEM_SOURCE_NAME]
        )
          ? current[DIMENSION_ITEM_SOURCE_NAME]
          : null;
        const itemCategory = partnerSourceName || category2;
        if (!result[itemCategory]) result[itemCategory] = {};

        result[itemCategory][DIMENSION_ITEM_SOURCE] =
          sourceMap[itemCategory]?.name || itemCategory;
        result[itemCategory][DIMENSION_ITEM_CATEGORY2] = itemCategory;
        result[itemCategory][METRIC_EVENT_COUNT] = sum([
          result[itemCategory][METRIC_EVENT_COUNT],
          current[METRIC_EVENT_COUNT],
        ]);
        return result;
      }, {});

    return Object.values(rowsGroupedByCategories).sort((a, b) => {
      const aCategory = a[DIMENSION_ITEM_CATEGORY2];
      const bCategory = b[DIMENSION_ITEM_CATEGORY2];
      return (
        (sourceMap[aCategory]?.order || sourceMap.default.order) -
        (sourceMap[bCategory]?.order || sourceMap.default.order)
      );
    });
  }, [commonReportData]);

  const plugiloViews = useMemo(() => {
    if (isEmpty(commonReportData)) return [];

    const views = commonReportData.rows
      .filter(
        (row) =>
          [EVENT_VIEW_ITEM_DETAILS, EVENT_VIEW_ITEM_IMPRESSION].includes(
            row[DIMENSION_EVENT_NAME]
          ) && row[DIMENSION_ITEM_SOURCE] === SOURCE_PLUGILO
      )
      .map((current) => {
        return current[METRIC_EVENT_COUNT];
      });

    return sum(views);
  }, [commonReportData]);

  const widgetViews = useMemo(() => {
    if (isEmpty(commonReportData)) return [];

    const views = commonReportData.rows
      .filter(
        (row) =>
          [EVENT_VIEW_ITEM_DETAILS].includes(row[DIMENSION_EVENT_NAME]) &&
          row[DIMENSION_ITEM_SOURCE] === SOURCE_WIDGET
      )
      .map((current) => {
        return current[METRIC_EVENT_COUNT];
      });

    return sum(views);
  }, [commonReportData]);

  const viewsCount = useMemo(() => {
    if (isEmpty(viewsBySources)) return 0;

    return sum(viewsBySources.map((item) => item[METRIC_EVENT_COUNT]));
  }, [viewsBySources]);

  const readPieChartData = useMemo(() => {
    const notMeasured = sentCount ? sentCount - viewsCount : 0;
    const notMeasuredPct = sentCount ? (notMeasured / sentCount) * 100 : 0;
    const viewedPct = sentCount ? (viewsCount / sentCount) * 100 : null;

    return [
      {
        name: "Not measured",
        value: notMeasured,
        color: gray300,
        percent: notMeasuredPct,
      },
      {
        name: "Views",
        value: viewsCount,
        color: sky300,
        percent: viewedPct,
      },
    ];
  }, [sentCount, viewsCount]);

  const linkClicksReportDataTableData = useMemo(() => {
    if (isEmpty(contentLinkClicksReportData)) return [];

    return orderBy(
      contentLinkClicksReportData.rows,
      [METRIC_EVENT_COUNT],
      ["desc"]
    );
  }, [contentLinkClicksReportData]);

  const totalClicks = useMemo(() => {
    return sum(
      linkClicksReportDataTableData.map((item) => item[METRIC_EVENT_COUNT])
    );
  }, [linkClicksReportDataTableData]);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <div className="h-full flex flex-col">
            <div className="flex items-center">
              <ChartTitle>
                <Space>
                  plugilo Card
                  <ContentLink
                    id={contentData.id}
                    name={contentData.name}
                    tag={contentType}
                    ownerDomain={contentData.ownerDomain}
                  >
                    <Icon name="arrow-up-right-from-square" />
                  </ContentLink>
                </Space>
              </ChartTitle>
              <span className="ml-auto text-gray-600 text-xxs">
                {moment(contentData.createdDate).format(DATE_FORMAT)}
              </span>
            </div>

            <Card size="small" className="text-center flex-grow">
              <EmailPreview
                className="w-full h-full block rounded-lg"
                contentId={contentId}
                contentType={contentType}
              />
            </Card>
          </div>
        </Col>

        {Boolean(viewsCount || sentCount) && (
          <Col xs={24} lg={12} className="mb-8">
            <div className="flex flex-col h-full">
              <ChartTitle>Overview</ChartTitle>
              <Card size="small" className="text-center flex-grow">
                <div className="h-80">
                  <PieChart
                    data={readPieChartData}
                    dataKey="value"
                    nameKey="name"
                    colors={[gray300, sky300]}
                    showLegends={false}
                  />
                </div>
                <Space size={40}>
                  {readPieChartData.map((item) => (
                    <Space direction="vertical" size={4}>
                      <Space align="baseline">
                        <Typography.Title
                          level={4}
                          style={{ color: item.color, margin: 0 }}
                        >
                          {formatNumber(item.value)}
                        </Typography.Title>
                        {Boolean(item.percent) && (
                          <Text type="secondary">
                            ({formatNumber(item.percent)}%)
                          </Text>
                        )}
                      </Space>
                      <Text level={5}>{item.name}</Text>
                    </Space>
                  ))}
                </Space>
              </Card>
              <Row gutter={GRID_GUTTER} className="mt-8">
                <Col span={12} lg={8} className="mb-8 lg:mb-0">
                  <Tooltip title="Approx. amount of all unique email addresses from all distributors">
                    <Card size="small">
                      <Scorecard title="Total Circulation" value={sentCount} />
                    </Card>
                  </Tooltip>
                </Col>
                <Col span={12} lg={8} className="mb-8 lg:mb-0">
                  <Tooltip title="Number of all views from all sources including multiple views">
                    <Card size="small">
                      <Scorecard title="Views" value={viewsCount} />
                    </Card>
                  </Tooltip>
                </Col>
                <Col span={12} lg={8} className="mb-8 lg:mb-0">
                  <Card size="small">
                    <Scorecard title="Link Clicks" value={totalClicks} />
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        )}
      </Row>

      {isAdmin && (
        <>
          <ChartTitle>Views by sources</ChartTitle>
          <Row gutter={GRID_GUTTER} className="mb-8">
            <Col xs={24} lg={12}>
              <Space direction="vertical" className="w-full">
                <Card size="small">
                  <TableChart
                    rowKey={DIMENSION_ITEM_CATEGORY2}
                    dataSource={viewsBySources}
                    columns={[
                      {
                        title: "Source",
                        dataIndex: DIMENSION_ITEM_SOURCE,
                        key: DIMENSION_ITEM_SOURCE,
                        ellipsis: true,
                      },
                      {
                        title: "Views",
                        dataIndex: METRIC_EVENT_COUNT,
                        key: METRIC_EVENT_COUNT,
                        width: 100,
                        align: "right",
                      },
                    ]}
                  />
                </Card>
              </Space>
            </Col>
            <Col xs={24} lg={12}>
              <Card size="small">
                <TableChart
                  rowKey={DIMENSION_ITEM_CATEGORY2}
                  dataSource={[
                    {
                      [DIMENSION_ITEM_SOURCE]: "plugilo Portal",
                      [METRIC_EVENT_COUNT]: plugiloViews,
                    },
                    {
                      [DIMENSION_ITEM_SOURCE]: "plugilo Widgets",
                      [METRIC_EVENT_COUNT]: widgetViews,
                    },
                  ]}
                  columns={[
                    {
                      title: "plugilo Source",
                      dataIndex: DIMENSION_ITEM_SOURCE,
                      key: DIMENSION_ITEM_SOURCE,
                      ellipsis: true,
                    },
                    {
                      title: "Views",
                      dataIndex: METRIC_EVENT_COUNT,
                      key: METRIC_EVENT_COUNT,
                      width: 100,
                      align: "right",
                    },
                  ]}
                />
              </Card>
            </Col>
          </Row>
        </>
      )}

      {!isEmpty(linkClicksReportDataTableData) && (
        <Col xs={24} lg={24} className="mb-8">
          <ChartTitle>Links</ChartTitle>
          <Card size="small">
            <TableChart
              pageSize={50}
              dataSource={linkClicksReportDataTableData}
              columns={[
                {
                  title: "Link",
                  dataIndex: DIMENSION_ITEM_URL,
                  key: DIMENSION_ITEM_URL,
                  ellipsis: true,
                  render: (value, record) => {
                    const url = getDecompressedUrl(record);
                    return url === "Others" ? (
                      url
                    ) : (
                      <a
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                        className="break-all"
                      >
                        {url}
                      </a>
                    );
                  },
                },
                {
                  title: "Clicks",
                  dataIndex: METRIC_EVENT_COUNT,
                  key: METRIC_EVENT_COUNT,
                  width: 100,
                  align: "right",
                  render: (value) => formatNumber(value),
                },
              ]}
              summary={() => (
                <Table.Summary.Row>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="secondary" className="font-semibold">
                      Total
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell className="text-right">
                    <Text className="font-semibold">{totalClicks}</Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              )}
            />
          </Card>
        </Col>
      )}
    </Spin>
  );
};

EmailCampaign.propTypes = {};

export default EmailCampaign;
