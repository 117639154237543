import React, { useMemo } from "react";
import { Card, Col, Row, Typography, Space } from "antd";
import { GRID_GUTTER } from "constants/ui";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { isEmpty } from "lodash";
import { DIMENSION_ITEM_ID } from "constants/customDimensions";
import TableChart from "components/charts/TableChart";
import { formatNumber } from "services/numberService";
import { DIMENSION_DATE } from "constants/dimensions";
import moment from "moment";
import { DATE_FORMAT } from "constants/default";
import { useContentViewsContext } from "../../ContentViewsContext";
import { getTextByKey } from "services/textService";

const DailyTraffic = ({ clicksEnabled }) => {
  const { timeSeriesEventsMap } = useContentViewsContext();

  const tableData = useMemo(() => {
    if (
      isEmpty(timeSeriesEventsMap?.impressions) &&
      isEmpty(timeSeriesEventsMap?.activeViews) &&
      isEmpty(timeSeriesEventsMap?.clicks) &&
      isEmpty(timeSeriesEventsMap?.deepClicks)
    )
      return [];

    const dataLength =
      timeSeriesEventsMap?.impressions?.length ||
      timeSeriesEventsMap?.activeViews?.length ||
      timeSeriesEventsMap?.clicks?.length ||
      timeSeriesEventsMap?.deepClicks.length;

    return Array(dataLength)
      .fill()
      .map((_, index) => {
        const impressionsItem =
          (timeSeriesEventsMap?.impressions || [])[index] || {};
        const activeViewsItem =
          (timeSeriesEventsMap?.activeViews || [])[index] || {};
        const clicksItem = (timeSeriesEventsMap?.clicks || [])[index] || {};
        const deepClicksItem =
          (timeSeriesEventsMap?.deepClicks || [])[index] || {};
        return {
          ...impressionsItem,
          impressions: impressionsItem[METRIC_EVENT_COUNT] || 0,
          activeViews: activeViewsItem[METRIC_EVENT_COUNT] || 0,
          clicks: clicksItem[METRIC_EVENT_COUNT] || 0,
          deepClicks: deepClicksItem[METRIC_EVENT_COUNT] || 0,
        };
      })
      .filter(
        (item) =>
          item.impressions || item.activeViews || item.clicks || item.deepClicks
      );
  }, [timeSeriesEventsMap]);

  return (
    <section className="mt-8">
      <Space className="mb-2">
        <span className="text-xl font-semibold mb-0">Daily Traffic</span>
      </Space>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={24} className="mb-8">
          <Card size="small" className="">
            <TableChart
              rowKey={DIMENSION_ITEM_ID}
              dataSource={tableData}
              columns={[
                {
                  title: "Date",
                  dataIndex: DIMENSION_DATE,
                  key: DIMENSION_DATE,
                  render(value) {
                    return moment(value).format(DATE_FORMAT);
                  },
                },
                {
                  title: "Impressions",
                  dataIndex: "impressions",
                  key: "impressions",
                  align: "right",
                  width: 200,
                  render: (value) => formatNumber(value),
                },
                {
                  title: "Active Views",
                  dataIndex: "activeViews",
                  key: "activeViews",
                  align: "right",
                  width: 200,
                  render: (value) => formatNumber(value),
                },
                ...(clicksEnabled
                  ? [
                      {
                        title: "Clicks on Card",
                        dataIndex: "clicks",
                        key: "clicks",
                        align: "right",
                        width: 200,
                        render: (value) => formatNumber(value),
                      },
                    ]
                  : []),
                {
                  title: getTextByKey("Plugilo.Analytics.ClickOuts"),
                  dataIndex: "deepClicks",
                  key: "deepClicks",
                  align: "right",
                  width: 200,
                  render: (value) => formatNumber(value),
                },
              ]}
            />
          </Card>
        </Col>
      </Row>
    </section>
  );
};

DailyTraffic.propTypes = {};

export default DailyTraffic;
