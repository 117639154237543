import { Card, Col, Row } from "antd";
import LineChart from "components/charts/LineChart";
import Scorecard from "components/charts/Scorecard";
import ChartTitle from "components/ChartTitle";
import {
  SOURCE_EMAIL,
  SOURCE_PLUGILO,
  SOURCE_WIDGET,
} from "constants/contentSources";
import { GRID_GUTTER } from "constants/ui";
import React, { useMemo } from "react";
import { isEmpty, sum } from "lodash";
import { sourceNamesMap, SOURCE_ALL } from "services/overviewService";
import { METRIC_EVENT_COUNT } from "constants/metrics";
import { useOverViewContext } from "../../OverviewContextProvider";

const ViewsTrend = ({ data }) => {
  const { selectedSource } = useOverViewContext();

  const hasAllSources = isEmpty(selectedSource);

  const contentViewsLines = useMemo(() => {
    return selectedSource
      ? [{ name: sourceNamesMap[selectedSource], dataKey: selectedSource }]
      : SOURCE_ALL;
  }, [selectedSource]);

  const viewsCount = useMemo(() => {
    if (isEmpty(data)) return 0;

    return sum(data.map((item) => item[METRIC_EVENT_COUNT]));
  }, [data]);

  const plugiloViewsCount = useMemo(() => {
    if (isEmpty(data)) return 0;

    return sum(data.map((row) => row[SOURCE_PLUGILO]));
  }, [data]);

  const widgetViewsCount = useMemo(() => {
    if (isEmpty(data)) return 0;

    return sum(data.map((row) => row[SOURCE_WIDGET]));
  }, [data]);

  const emailViewsCount = useMemo(() => {
    if (isEmpty(data)) return 0;

    return sum(data.map((row) => row[SOURCE_EMAIL]));
  }, [data]);

  return (
    <>
      <ChartTitle>Views trend</ChartTitle>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <Card size="small">
            <LineChart
              xAxisDataKey="date"
              data={data}
              lines={contentViewsLines}
            />
          </Card>
        </Col>
        <Col xs={24} lg={12} className="mb-8">
          <Row gutter={GRID_GUTTER}>
            <Col span={24} className="mb-8">
              <Card size="small">
                <Scorecard
                  title="Views"
                  value={viewsCount}
                  chartProps={
                    hasAllSources
                      ? {
                          data,
                          lines: [
                            {
                              name: "Views",
                              dataKey: METRIC_EVENT_COUNT,
                            },
                          ],
                        }
                      : null
                  }
                />
              </Card>
            </Col>
          </Row>
          {hasAllSources && (
            <Row gutter={GRID_GUTTER}>
              <Col span={12} className="mb-8">
                <Card size="small" className="lg:h-full">
                  <Scorecard
                    title="plugilo"
                    value={plugiloViewsCount}
                    chartProps={{
                      data,
                      lines: [
                        {
                          name: "Active Views",
                          dataKey: SOURCE_PLUGILO,
                        },
                      ],
                    }}
                  />
                </Card>
              </Col>
              <Col span={12} className="mb-8">
                <Card size="small" className="lg:h-full">
                  <Scorecard
                    title="Widget"
                    value={widgetViewsCount}
                    chartProps={{
                      data,
                      lines: [
                        {
                          name: "Widgets",
                          dataKey: SOURCE_WIDGET,
                        },
                      ],
                    }}
                  />
                </Card>
              </Col>
              <Col span={12} className="mb-8">
                <Card size="small" className="lg:h-full">
                  <Scorecard
                    title="Email"
                    value={emailViewsCount}
                    chartProps={{
                      data,
                      lines: [
                        {
                          name: "Email",
                          dataKey: SOURCE_EMAIL,
                        },
                      ],
                    }}
                  />
                </Card>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ViewsTrend;
